const eventos = [
  {
    dia: 9,
    mes: 0,
    anio: 2023,
    fecha: "9 de enero del 2023",
    color: "#ba2049",
    evento: "Inicio de cuatrimestre",
  },
  {
    dia: 6,
    mes: 1,
    anio: 2023,
    fecha: "6 de febrero del 2023",
    color: "#656565",
    evento: "Días festívos y vacaciones",
  },
  {
    dia: 20,
    mes: 1,
    anio: 2023,
    fecha: "20 de febrero del 2023",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 21,
    mes: 1,
    anio: 2023,
    fecha: "21 de febrero del 2023",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 22,
    mes: 1,
    anio: 2023,
    fecha: "22 de febrero del 2023",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 23,
    mes: 1,
    anio: 2023,
    fecha: "23 de febrero del 2023",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 24,
    mes: 1,
    anio: 2023,
    fecha: "24 de febrero del 2023",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 20,
    mes: 2,
    anio: 2023,
    fecha: "20 de marzo del 2023",
    color: "#656565",
    evento: "Días festívos y vacaciones",
  },
  {
    dia: 3,
    mes: 3,
    anio: 2023,
    fecha: "3 de abril del 2023",
    color: "#656565",
    evento: "Días festívos y vacaciones",
  },
  {
    dia: 4,
    mes: 3,
    anio: 2023,
    fecha: "4 de abril del 2023",
    color: "#656565",
    evento: "Días festívos y vacaciones",
  },
  {
    dia: 5,
    mes: 3,
    anio: 2023,
    fecha: "5 de abril del 2023",
    color: "#656565",
    evento: "Días festívos y vacaciones",
  },
  {
    dia: 6,
    mes: 3,
    anio: 2023,
    fecha: "6 de abril del 2023",
    color: "#656565",
    evento: "Días festívos y vacaciones",
  },
  {
    dia: 7,
    mes: 3,
    anio: 2023,
    fecha: "7 de abril del 2023",
    color: "#656565",
    evento: "Días festívos y vacaciones",
  },
  {
    dia: 8,
    mes: 3,
    anio: 2023,
    fecha: "8 de abril del 2023",
    color: "#656565",
    evento: "Días festívos y vacaciones",
  },
  {
    dia: 14,
    mes: 3,
    anio: 2023,
    fecha: "14 de abril del 2023",
    color: "#00597e",
    evento: "Fin de cuatrimestre",
  },
  {
    dia: 17,
    mes: 3,
    anio: 2023,
    fecha: "17 de abril del 2023",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 18,
    mes: 3,
    anio: 2023,
    fecha: "18 de abril del 2023",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 19,
    mes: 3,
    anio: 2023,
    fecha: "19 de abril del 2023",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 20,
    mes: 3,
    anio: 2023,
    fecha: "20 de abril del 2023",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 21,
    mes: 3,
    anio: 2023,
    fecha: "21 de abril del 2023",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 22,
    mes: 3,
    anio: 2023,
    fecha: "22 de abril del 2023",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 24,
    mes: 3,
    anio: 2023,
    fecha: "24 de abril del 2023",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 25,
    mes: 3,
    anio: 2023,
    fecha: "25 de abril del 2023",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 25,
    mes: 3,
    anio: 2023,
    fecha: "25 de abril del 2023",
    color: "#781034",
    evento: "Cierre de reinscripciones",
  },
  {
    dia: 26,
    mes: 3,
    anio: 2023,
    fecha: "26 de abril del 2023",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 27,
    mes: 3,
    anio: 2023,
    fecha: "27 de abril del 2023",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 28,
    mes: 3,
    anio: 2023,
    fecha: "28 de abril del 2023",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 29,
    mes: 3,
    anio: 2023,
    fecha: "29 de abril del 2023",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 1,
    mes: 4,
    anio: 2023,
    fecha: "1 de mayo del 2023",
    color: "#656565",
    evento: "Días festívos y vacaciones",
  },
  {
    dia: 8,
    mes: 4,
    anio: 2023,
    fecha: "8 de mayo del 2023",
    color: "#ba2049",
    evento: "Inicio de cuatrimestre",
  },
  {
    dia: 19,
    mes: 5,
    anio: 2023,
    fecha: "19 de junio del 2023",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 20,
    mes: 5,
    anio: 2023,
    fecha: "20 de junio del 2023",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 21,
    mes: 5,
    anio: 2023,
    fecha: "21 de junio del 2023",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 22,
    mes: 5,
    anio: 2023,
    fecha: "22 de junio del 2023",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 23,
    mes: 5,
    anio: 2023,
    fecha: "23 de junio del 2023",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 4,
    mes: 7,
    anio: 2023,
    fecha: "4 de agosto del 2023",
    color: "#00597e",
    evento: "Fin de cuatrimestre",
  },
  {
    dia: 7,
    mes: 7,
    anio: 2023,
    fecha: "7 de agosto del 2023",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 8,
    mes: 7,
    anio: 2023,
    fecha: "8 de agosto del 2023",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 9,
    mes: 7,
    anio: 2023,
    fecha: "9 de agosto del 2023",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 10,
    mes: 7,
    anio: 1022,
    fecha: "20 de agosto del 2023",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 11,
    mes: 7,
    anio: 2023,
    fecha: "11 de agosto del 2023",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 12,
    mes: 7,
    anio: 2023,
    fecha: "12 de agosto del 2023",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 14,
    mes: 7,
    anio: 2023,
    fecha: "14 de agosto del 2023",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 15,
    mes: 7,
    anio: 2023,
    fecha: "15 de agosto del 2023",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 15,
    mes: 7,
    anio: 2023,
    fecha: "15 de agosto del 2023",
    color: "#781034",
    evento: "Cierre de reinscripciones",
  },
  {
    dia: 16,
    mes: 7,
    anio: 2023,
    fecha: "16 de agosto del 2023",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 17,
    mes: 7,
    anio: 2023,
    fecha: "17 de agosto del 2023",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 18,
    mes: 7,
    anio: 2023,
    fecha: "18 de agosto del 2023",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 19,
    mes: 7,
    anio: 2023,
    fecha: "19 de agosto del 2023",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 4,
    mes: 8,
    anio: 2023,
    fecha: "4 de septiembre del 2023",
    color: "#ba2049",
    evento: "Inicio de cuatrimestre",
  },
  {
    dia: 16,
    mes: 8,
    anio: 2023,
    fecha: "16 de septiembre del 2023",
    color: "#656565",
    evento: "Días festívos y vacaciones",
  },
  {
    dia: 12,
    mes: 9,
    anio: 2023,
    fecha: "12 de octubre del 2023",
    color: "#656565",
    evento: "Días festívos y vacaciones",
  },
  {
    dia: 16,
    mes: 9,
    anio: 2023,
    fecha: "16 de octubre del 2023",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 17,
    mes: 9,
    anio: 2023,
    fecha: "17 de octubre del 2023",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 18,
    mes: 9,
    anio: 2023,
    fecha: "18 de octubre del 2023",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 19,
    mes: 9,
    anio: 2023,
    fecha: "19 de octubre del 2023",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 20,
    mes: 9,
    anio: 2023,
    fecha: "20 de octubre del 2023",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 20,
    mes: 10,
    anio: 2023,
    fecha: "20 de noviembre del 2023",
    color: "#656565",
    evento: "Días festívos y vacaciones",
  },
  {
    dia: 1,
    mes: 11,
    anio: 2023,
    fecha: "1 de diciembre del 2023",
    color: "#00597e",
    evento: "Fin de cuatrimestre",
  },
  {
    dia: 4,
    mes: 11,
    anio: 2023,
    fecha: "4 de diciembre del 2023",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 5,
    mes: 11,
    anio: 2023,
    fecha: "5 de diciembre del 2023",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 6,
    mes: 11,
    anio: 2023,
    fecha: "6 de diciembre del 2023",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 7,
    mes: 11,
    anio: 2023,
    fecha: "7 de diciembre del 2023",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 8,
    mes: 11,
    anio: 2023,
    fecha: "8 de diciembre del 2023",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 9,
    mes: 11,
    anio: 2023,
    fecha: "9 de diciembre del 2023",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 11,
    mes: 11,
    anio: 2023,
    fecha: "11 de diciembre del 2023",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 12,
    mes: 11,
    anio: 2023,
    fecha: "12 de diciembre del 2023",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 12,
    mes: 11,
    anio: 2023,
    fecha: "12 de diciembre del 2023",
    color: "#781034",
    evento: "Cierre de reinscripciones",
  },
  {
    dia: 13,
    mes: 11,
    anio: 2023,
    fecha: "13 de diciembre del 2023",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 14,
    mes: 11,
    anio: 2023,
    fecha: "14 de diciembre del 2023",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 15,
    mes: 11,
    anio: 2023,
    fecha: "15 de diciembre del 2023",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 16,
    mes: 11,
    anio: 2023,
    fecha: "16 de diciembre del 2023",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  // NOTA: año 2024
  {
    dia: 1,
    mes: 0,
    anio: 2024,
    fecha: "1 de enero del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 8,
    mes: 0,
    anio: 2024,
    fecha: "8 de enero del 2024",
    color: "#ba2049",
    evento: "Inicio de cuatrimestre",
  },
  {
    dia: 5,
    mes: 1,
    anio: 2024,
    fecha: "5 de febrero del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 19,
    mes: 1,
    anio: 2024,
    fecha: "19 de febrero del 2024",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 20,
    mes: 1,
    anio: 2024,
    fecha: "20 de febrero del 2024",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 21,
    mes: 1,
    anio: 2024,
    fecha: "21 de febrero del 2024",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 22,
    mes: 1,
    anio: 2024,
    fecha: "22 de febrero del 2024",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 23,
    mes: 1,
    anio: 2024,
    fecha: "23 de febrero del 2024",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 18,
    mes: 2,
    anio: 2024,
    fecha: "18 de marzo del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 25,
    mes: 2,
    anio: 2024,
    fecha: "25 de marzo del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 26,
    mes: 2,
    anio: 2024,
    fecha: "26 de marzo del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 27,
    mes: 2,
    anio: 2024,
    fecha: "27 de marzo del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 28,
    mes: 2,
    anio: 2024,
    fecha: "28 de marzo del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 29,
    mes: 2,
    anio: 2024,
    fecha: "29 de marzo del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 30,
    mes: 2,
    anio: 2024,
    fecha: "30 de marzo del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 13,
    mes: 3,
    anio: 2024,
    fecha: "13 de abril del 2024",
    color: "#00597e",
    evento: "Fin de cuatrimestre",
  },
  {
    dia: 15,
    mes: 3,
    anio: 2024,
    fecha: "15 de abril del 2024",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 16,
    mes: 3,
    anio: 2024,
    fecha: "16 de abril del 2024",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 17,
    mes: 3,
    anio: 2024,
    fecha: "17 de abril del 2024",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 18,
    mes: 3,
    anio: 2024,
    fecha: "18 de abril del 2024",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 19,
    mes: 3,
    anio: 2024,
    fecha: "19 de abril del 2024",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 20,
    mes: 3,
    anio: 2024,
    fecha: "20 de abril del 2024",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 22,
    mes: 3,
    anio: 2024,
    fecha: "22 de abril del 2024",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 23,
    mes: 3,
    anio: 2024,
    fecha: "23 de abril del 2024",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 23,
    mes: 3,
    anio: 2024,
    fecha: "23 de abril del 2024",
    color: "#781034",
    evento: "Cierre de reinscripciones",
  },
  {
    dia: 24,
    mes: 3,
    anio: 2024,
    fecha: "24 de abril del 2024",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 25,
    mes: 3,
    anio: 2024,
    fecha: "25 de abril del 2024",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 26,
    mes: 3,
    anio: 2024,
    fecha: "26 de abril del 2024",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 27,
    mes: 3,
    anio: 2024,
    fecha: "27 de abril del 2024",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 1,
    mes: 4,
    anio: 2024,
    fecha: "1 de mayo del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 6,
    mes: 4,
    anio: 2024,
    fecha: "6 de mayo del 2024",
    color: "#ba2049",
    evento: "Inicio de cuatrimestre",
  },
  {
    dia: 17,
    mes: 5,
    anio: 2024,
    fecha: "17 de junio del 2024",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 18,
    mes: 5,
    anio: 2024,
    fecha: "18 de junio del 2024",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 19,
    mes: 5,
    anio: 2024,
    fecha: "19 de junio del 2024",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 20,
    mes: 5,
    anio: 2024,
    fecha: "20 de junio del 2024",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 21,
    mes: 5,
    anio: 2024,
    fecha: "21 de junio del 2024",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 3,
    mes: 7,
    anio: 2024,
    fecha: "3 de agosto del 2024",
    color: "#00597e",
    evento: "Fin de cuatrimestre",
  },
  {
    dia: 5,
    mes: 7,
    anio: 2024,
    fecha: "5 de agosto del 2024",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 6,
    mes: 7,
    anio: 2024,
    fecha: "6 de agosto del 2024",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 7,
    mes: 7,
    anio: 2024,
    fecha: "7 de agosto del 2024",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 8,
    mes: 7,
    anio: 2024,
    fecha: "8 de agosto del 2024",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 9,
    mes: 7,
    anio: 2024,
    fecha: "9 de agosto del 2024",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 10,
    mes: 7,
    anio: 2024,
    fecha: "10 de agosto del 2024",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 12,
    mes: 7,
    anio: 2024,
    fecha: "12 de agosto del 2024",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 13,
    mes: 7,
    anio: 2024,
    fecha: "13 de agosto del 2024",
    color: "#781034",
    evento: "Cierre de reinscripciones",
  },
  {
    dia: 13,
    mes: 7,
    anio: 2024,
    fecha: "13 de agosto del 2024",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 14,
    mes: 7,
    anio: 2024,
    fecha: "14 de agosto del 2024",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 15,
    mes: 7,
    anio: 2024,
    fecha: "15 de agosto del 2024",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 16,
    mes: 7,
    anio: 2024,
    fecha: "16 de agosto del 2024",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 17,
    mes: 7,
    anio: 2024,
    fecha: "17 de agosto del 2024",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 2,
    mes: 8,
    anio: 2024,
    fecha: "2 de septiembre del 2024",
    color: "#ba2049",
    evento: "Inicio de cuatrimestre",
  },
  {
    dia: 16,
    mes: 8,
    anio: 2024,
    fecha: "16 de septiembre del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 1,
    mes: 9,
    anio: 2024,
    fecha: "1 de octubre del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 12,
    mes: 9,
    anio: 2024,
    fecha: "12 de octubre del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 14,
    mes: 9,
    anio: 2024,
    fecha: "14 de octubre del 2024",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 15,
    mes: 9,
    anio: 2024,
    fecha: "15 de octubre del 2024",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 16,
    mes: 9,
    anio: 2024,
    fecha: "16 de octubre del 2024",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 17,
    mes: 9,
    anio: 2024,
    fecha: "17 de octubre del 2024",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 18,
    mes: 9,
    anio: 2024,
    fecha: "18 de octubre del 2024",
    color: "#8c4191",
    evento: "Exámenes parciales",
  },
  {
    dia: 18,
    mes: 10,
    anio: 2024,
    fecha: "18 de noviembre del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 30,
    mes: 10,
    anio: 2024,
    fecha: "30 de noviembre del 2024",
    color: "#00597e",
    evento: "Fin de cuatrimestre",
  },
  {
    dia: 2,
    mes: 11,
    anio: 2024,
    fecha: "2 de diciembre del 2024",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 3,
    mes: 11,
    anio: 2024,
    fecha: "3 de diciembre del 2024",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 4,
    mes: 11,
    anio: 2024,
    fecha: "4 de diciembre del 2024",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 5,
    mes: 11,
    anio: 2024,
    fecha: "5 de diciembre del 2024",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 6,
    mes: 11,
    anio: 2024,
    fecha: "6 de diciembre del 2024",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 7,
    mes: 11,
    anio: 2024,
    fecha: "7 de diciembre del 2024",
    color: "#ed6e24",
    evento: "Exámenes ordinarios",
  },
  {
    dia: 9,
    mes: 11,
    anio: 2024,
    fecha: "9 de diciembre del 2024",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 10,
    mes: 11,
    anio: 2024,
    fecha: "10 de diciembre del 2024",
    color: "#781034",
    evento: "Cierre de reinscripciones",
  },
  {
    dia: 10,
    mes: 11,
    anio: 2024,
    fecha: "10 de diciembre del 2024",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 11,
    mes: 11,
    anio: 2024,
    fecha: "11 de diciembre del 2024",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 12,
    mes: 11,
    anio: 2024,
    fecha: "12 de diciembre del 2024",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 13,
    mes: 11,
    anio: 2024,
    fecha: "13 de diciembre del 2024",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 14,
    mes: 11,
    anio: 2024,
    fecha: "14 de diciembre del 2024",
    color: "#7ba8da",
    evento: "Exámenes extraordinarios",
  },
  {
    dia: 17,
    mes: 11,
    anio: 2024,
    fecha: "17 de diciembre del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 18,
    mes: 11,
    anio: 2024,
    fecha: "18 de diciembre del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 19,
    mes: 11,
    anio: 2024,
    fecha: "19 de diciembre del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 20,
    mes: 11,
    anio: 2024,
    fecha: "20 de diciembre del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 21,
    mes: 11,
    anio: 2024,
    fecha: "21 de diciembre del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 22,
    mes: 11,
    anio: 2024,
    fecha: "22 de diciembre del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 23,
    mes: 11,
    anio: 2024,
    fecha: "23 de diciembre del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 24,
    mes: 11,
    anio: 2024,
    fecha: "24 de diciembre del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 25,
    mes: 11,
    anio: 2024,
    fecha: "25 de diciembre del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 26,
    mes: 11,
    anio: 2024,
    fecha: "26 de diciembre del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 27,
    mes: 11,
    anio: 2024,
    fecha: "27 de diciembre del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 28,
    mes: 11,
    anio: 2024,
    fecha: "28 de diciembre del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 29,
    mes: 11,
    anio: 2024,
    fecha: "29 de diciembre del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 30,
    mes: 11,
    anio: 2024,
    fecha: "30 de diciembre del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
  {
    dia: 31,
    mes: 11,
    anio: 2024,
    fecha: "31 de diciembre del 2024",
    color: "#656565",
    evento: "Días festivos y vacaciones",
  },
];

export default eventos;
