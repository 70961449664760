var url_json = require("../location.json");

if (window.location.origin.includes("localhost")) {
    url_json = url_json.local;
} else {
    url_json = url_json.production;
}

const Pdfs = {
    obtenerPdfCampo: async campo => {
        let url = url_json + "/pdf-campo"
        let body = {
            campo
        };

        let request = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }

        let response = await fetch(url, request);
        let res = await response.json();
        return res;
    }
}

export default Pdfs;