import React, { Fragment, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useParams, useNavigate } from "react-router-dom";

import blogsHelper from "../../../Helpers/Blogs";
import autoresBlogsHelper from "../../../Helpers/AutoresBlogs";
import etiquetasBlogs from "../../../Helpers/EtiquetasBlogs";
import Loader from "../../../Components/Loader/Loader";
import Reciente from "../../../Components/Blogs/Reciente";
import Relacionado from "../../../Components/Blogs/Relacionado";

import whats from "../../../Images/whats.svg";
import twitter from "../../../Images/twitter.svg";
import facebook from "../../../Images/facebook.svg";

const Blog = () => {
  const licenciaturas = [
    "CINE DIGITAL",
    "MULTIMEDIA",
    "MARKETING DIGITAL",
    "ANIMACIÓN",
  ];
  const { url } = useParams("titulo");
  const navigate = useNavigate();

  const [blog, setBlog] = useState({});
  const [autor, setAutor] = useState({});
  const [blogsRecientes, setBlogsRecientes] = useState([]);
  const [blogRelacionado, setBlogsRelacionado] = useState([]);
  const [etiquetas, setEtiquetas] = useState([]);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    obtenerBlog();
  }, []);

  const obtenerBlog = async () => {
    let res = await blogsHelper.obtenerBlogUrl(url);

    if (res.success) {
      setBlog(res.data[0]);
      obtenerEtiquetas(res.data[0]);
    } else {
      Swal.fire(
        "Error!",
        "No se pudo obtener el blog, codigo: " + res.code,
        "error"
      );
    }
  };

  const obtenerEtiquetas = async (blog) => {
    let res = await etiquetasBlogs.obtenerEtiquetasIDs(blog.etiquetas);

    if (res.success) {
      setEtiquetas(res.data);
      obtenerAutor(blog);
    } else {
      Swal.fire(
        "Error!",
        "No se pudo obtener las etiquetas, codigo: " + res.code,
        "error"
      );
    }
  };

  const obtenerAutor = async (blog) => {
    console.log({ blog });
    let res = await autoresBlogsHelper.obtenerUnAutor(blog.autor);

    if (res.success) {
      setAutor(res.data);
      obtenerRecientes();
    } else {
      Swal.fire(
        "Error!",
        "No se pudo obtener el autor, codigo: " + res.code,
        "error"
      );
    }
  };

  const obtenerRecientes = async () => {
    let res = await blogsHelper.obtenerBlogsRecientes();

    if (res.success) {
      setBlogsRecientes(res.data);
      obtenerRelacionados();
    } else {
      Swal.fire(
        "Error!",
        "No se pudo obtener el blog, codigo: " + res.code,
        "error"
      );
    }
  };

  const obtenerRelacionados = async () => {
    let res = await blogsHelper.obtenerBlogsRelacionado();

    if (res.success) {
      setBlogsRelacionado(res.data);
      setFlag(true);
    } else {
      Swal.fire(
        "Error!",
        "No se pudo obtener el blog, codigo: " + res.code,
        "error"
      );
    }
  };

  function createMarkup(materias) {
    return { __html: materias };
  }

  const redirecionFiltroEtiqueta = (id) => {
    sessionStorage.setItem("__fl-etq-bg-cv__", id);
    navigate("/caav-blogs");
  };

  const redirecionFiltroTema = (licenciatura) => {
    sessionStorage.setItem("__fl-tem-bg-cv__", licenciatura);
    navigate("/caav-blogs");
  };

  return (
    <Fragment>
      <div className="ancho_imagen_curso">
        <img
          src={blog.imagen}
          alt={blog.titulo}
          style={{
            width: "100%",
          }}
        />
      </div>

      <div className="container center">
        <p className="encabezadosMonserrat" style={{ fontWeight: "bolder" }}>
          {blog.titulo}
        </p>
      </div>

      <div className="container row" style={{ marginTop: "50px" }}>
        <div className="col s12 m12 l8">
          <div
            className="informacion_curso"
            dangerouslySetInnerHTML={createMarkup(blog.contenido)}
          />

          <div style={{ display: "flex" }}>
            <a
              target="_blank"
              rel="noreferrer"
              href={`/blogs-autor/${blog.autor}`}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "25px",
                margin: "30px 0",
              }}
            >
              <img
                style={{ width: "75px", borderRadius: "50%" }}
                src={autor.imagen}
                alt="autor"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p className="etiqueta_profesor">{autor.nombre}</p>
                  <p className="etiqueta_fin_profesor">.</p>
                </div>
                <p style={{ margin: "0", color: "#000" }}>
                  {autor.certificado}
                </p>
              </div>
            </a>
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <button
              onClick={() => {
                redirecionFiltroTema(blog.licenciatura);
              }}
              className="etiqueta-curso"
            >
              <p>{licenciaturas[blog.licenciatura]}</p>
            </button>
            {etiquetas.map((etiqueta) => (
              <button
                onClick={() => {
                  redirecionFiltroEtiqueta(etiqueta._id);
                }}
                className="etiqueta-curso-2"
              >
                <p>{etiqueta.nombre}</p>
              </button>
            ))}
          </div>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <p>Compartir:</p>
            <a
              target="_blank"
              href={`whatsapp://send?text=${window.location.href}`}
              rel="noreferrer"
            >
              <img src={whats} alt="WhatsApp CAAV" />
            </a>
            <a
              target="_blank"
              href={`https://twitter.com/intent/tweet?url=${window.location.href}&text=Visita%20nuestro%20blog%3A%20`}
              rel="noreferrer"
            >
              <img src={twitter} alt="Twitter CAAV" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
            >
              <img src={facebook} alt="Facebook CAAV" />
            </a>
            {/* <a target="_blank" href=""><img src={messenger} alt="Messenger CAAV"/></a> */}
          </div>
        </div>
        <div style={{ paddingLeft: "50px" }} className="col s12 m12 l4">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              margin: "40px 0 60px 0",
            }}
          >
            <div className="busqueda_cursos">
              <input
                placeholder="Buscar por titulo"
                id="nombre_materia"
                name="nombre_materia"
                type="text"
                className="validate formulario"
              />
              <label for="nombre_materia"></label>
            </div>
          </div>
          <p
            className="titulo_4_nv"
            style={{ marginBottom: "0px", color: "#00496C" }}
          >
            Buscar por temas
          </p>
          <div className="row">
            <div className="col s6 boton-licenciatura-blog-2">
              <button>CINE DIGITAL</button>
            </div>
            <div className="col s6 boton-licenciatura-blog-2">
              <button>MULTIMEDIA</button>
            </div>
            <div className="col s6 boton-licenciatura-blog-2">
              <button>MARKETING DIGITAL</button>
            </div>
            <div className="col s6 boton-licenciatura-blog-2">
              <button>ANIMACIÓN</button>
            </div>
            <div className="col s6 boton-licenciatura-blog-2">
              <button>CULTURA GENERAL</button>
            </div>
          </div>
          <p
            className="titulo_4_nv"
            style={{ margin: "30px 0 0 0", color: "#00496C" }}
          >
            Artículos recientes
          </p>
          {!flag ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <Loader />
            </div>
          ) : (
            blogsRecientes.map((blog) => <Reciente blog={blog} />)
          )}
        </div>
        <div className="col s12">
          <p
            className="titulo_4_nv"
            style={{ marginBottom: "30px", color: "#00496C" }}
          >
            Recomendado
          </p>
          {blogRelacionado.map((blog) => (
            <Relacionado blog={blog} />
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default Blog;
