import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ReactDOM from "react-dom";
import M from "materialize-css";

import Loader from "../../../Components/Loader/Loader";
import InscripcionesDataHelper from "../../../Helpers/InscripcionesData";

const FormInscripcion = () => {
  const [state, setState] = useState({
    archivado: false,
    fecha: new Date(),
    nombre: "",
    fechaNacimiento: "",
    edad: "",
    // sexo: "",
    domicilio: "",
    colonia: "",
    codigoPostal: "",
    ciudad: "",
    estado: "",
    pais: "Dato ya no disponible en formulario",
    // telefonoParticular: "",
    celular: "",
    email: "",
    // telefonoTrabajo: "",
    nombrePadre: "",
    nombreMadre: "",
    telefonoPadre: "",
    telefonoMadre: "",
    bachiller: "",
    ciudadBachiller: "",
    generacion: "",
    // estadoBachiller: "",
    enfermedad: false,
    enfermedadEspecificacion: "",
    condicion: false,
    condicionEspecial: "",
    tipoSangre: "",
    nombreEmergencia: "",
    telefonoEmergencia: "",
    medioEncontrado: "",
    licenciatura: "",
    estadoAlumno: "false",
    periodo: "",
  });
  const [condicionEspecialOtra, setCondicionEspecialOtra] = useState("");
  const [validacion, setValidacion] = useState(false);
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [fechaNacimientoMostar, setFechaNacimientoMostrar] = useState("");

  const [archivo, setArchivo] = useState("");
  const [otroMedio, setOtroMedio] = useState(false);

  const convertirBase = (archivos, index, base64) => {
    try {
      // Select the very first file from list
      let fileToLoad = archivos[index];
      // FileReader function for read the file.
      let fileReader = new FileReader();
      // Onload of file read the file content
      fileReader.onload = function (fileLoadedEvent) {
        let file = fileLoadedEvent.target.result;

        let copiaIndex = index + 1;
        let copiaBase64 = [...base64];
        copiaBase64.push(file);
        if (copiaIndex >= archivos.length) {
          agregar(copiaBase64);
        } else {
          convertirBase(archivos, copiaIndex, copiaBase64);
        }
      };
      // Convert data to base64
      fileReader.readAsDataURL(fileToLoad);
    } catch (error) {
      // console.log({error});
    }
  };

  const agregar = async (pdfs) => {
    ReactDOM.render(<Loader />, document.getElementById("cargando"));
    let copiaState = { ...state, fechaNacimiento };
    copiaState.pdfs = pdfs;

    if (copiaState.condicionEspecial === "Otra")
      copiaState.condicionEspecial = condicionEspecialOtra;

    let res = await InscripcionesDataHelper.AgregarInscripcionesData(
      copiaState
    );

    if (res.success) {
      Swal.fire({
        title: "Datos Enviados Correctamente",
        text: "Tu informacion fue enviada correctamente",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      }).then(() => {
        window.location.reload();
      });
    } else {
      Swal.fire(
        "Error!",
        "No se pudo enviar la información, codigo: " + res.code,
        "error"
      ).then(() => {
        window.location.reload();
      });
    }
  };

  useEffect(() => {
    var elems = document.querySelectorAll("select");
    M.FormSelect.init(elems);

    var data = document.querySelectorAll(".datepicker");
    M.Datepicker.init(data, {
      format: "dd mm yyyy",
      onSelect: (date) => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses comienzan en 0
        const year = date.getFullYear();

        const formattedDate = `${day}-${month}-${year}`;

        setFechaNacimiento(date);
        setFechaNacimientoMostrar(formattedDate);
      },
    });
  });

  const leerDato = (e) => {
    e.preventDefault();
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const seleccionPago = (e) => {
    e.preventDefault();
    let pago;
    const target_select = document.getElementsByClassName("boton_pago_select");
    if (e.target.parentNode.id === "cuatro_pagos") {
      pago = "Cuatro pagos mensuales";
    } else if (e.target.parentNode.id === "un_pago") {
      pago = "Un solo pago";
    } else {
      pago = "Evaluacion de perfil";
    }

    setState({ ...state, [e.target.className]: pago });

    //efecto de seleccion en el boton
    if (target_select.length !== 0) {
      target_select[0].className = "boton_evaluacion";
    }
    e.target.parentNode.className = "boton_pago_select";
  };

  const leerArchivo = (e) => {
    setArchivo(e.target.files);
  };

  const enviar = (e) => {
    e.preventDefault();

    if (archivo === "" || state.periodo === "0") {
      Swal.fire("Error", "Ingrese todos los datos", "error");
    } else {
      if (archivo.length > 3) {
        Swal.fire("Error", "El máximo de archivo a enviar son 3", "error");
      } else {
        let tamanio = 0;
        for (let i = 0; i < archivo.length; i++) {
          tamanio = tamanio + archivo[i].size;
        }
        if (tamanio > 3000000) {
          Swal.fire(
            "Error",
            "Verifica que sea sólo un archivo PDF no mayor a 3 MB. Si es necesario, comprime tu archivo",
            "error"
          );
        } else {
          convertirBase(archivo, 0, []);
        }
      }
    }
  };

  return (
    <form onSubmit={enviar}>
      <p
        className="titulo_4_nv centrar_movil"
        style={{ fontFamily: "Montserrat-Bold", marginBottom: "0px" }}
      >
        Quiero inscribirme en la licenciatura en:
      </p>
      <div className="row" style={{ marginBottom: "0px" }}>
        <div
          className="input-field col s12 select-formulario"
          style={{ margin: "4px 0px 0px 0px" }}
        >
          <select required name="licenciatura" onChange={leerDato}>
            <option value="" disabled selected>
              Selecciona tu licenciatura
            </option>
            <option value="cine" name="licenciatura">
              Cine
            </option>
            <option value="animacion" name="licenciatura">
              Animación
            </option>
            <option value="multimedia" name="licenciatura">
              Multimedia
            </option>
            <option value="marketing" name="licenciatura">
              Marketing
            </option>
          </select>
        </div>

        <div
          className="input-field col s12 m6 l6 xl6 select-formulario"
          style={{ margin: "4px 0px" }}
        >
          <p>Periodo escolar</p>
          {state.licenciatura === "" ? (
            <select
              required
              placeholder="Periodo escolar"
              name="periodo"
              onChange={leerDato}
            >
              <option value="0" disabled selected>
                Selecciona primero tu licenciatura
              </option>
            </select>
          ) : (
            <select
              required
              placeholder="Periodo escolar"
              name="periodo"
              onChange={leerDato}
            >
              <option value="0" disabled selected>
                Selecciona tu periodo
              </option>
              {state.licenciatura === "marketing" ? (
                <>
                  <option value="3">Septiembre - Diciembre 2024</option>
                  <option value="3">Septiembre - Diciembre 2025</option>
                  <option value="3">Septiembre - Diciembre 2026</option>
                </>
              ) : (
                <>
                  <option value="3">Septiembre - Diciembre 2024</option>
                  <option value="1">Enero - Abril 2025</option>
                  <option value="2">Mayo - Agosto 2025</option>
                </>
              )}
            </select>
          )}
        </div>
        <p
          className="col s12 titulo_4_nv"
          style={{
            fontFamily: "Montserrat-Bold",
            color: "#DE2657",
            margin: "15px 0 0 0",
          }}
        >
          Mis datos:
        </p>

        <div className="input-field col s12" style={{ margin: "4px 0px" }}>
          <p>Me llamo</p>
          <input
            required
            placeholder="Tu nombre tal cual aparece en tu acta de nacimiento"
            id="nombre"
            name="nombre"
            onChange={leerDato}
            type="text"
            className="validate formulario"
          />
          <label for="nombre"></label>
        </div>

        <div
          className="input-field col s12 m4 l4 xl4"
          style={{ margin: "4px 0px" }}
        >
          <p>Nací el</p>
          <input
            required
            placeholder="dd-mm-aaaa"
            id="fechaNacimiento"
            name="fechaNacimiento"
            type="text"
            className="validate datepicker formulario"
            value={fechaNacimientoMostar}
          />
          <label for="fechaNacimiento"></label>
        </div>
        <div
          className="input-field col s12 m4 l4 xl4"
          style={{ margin: "4px 0px" }}
        >
          <p>Mi edad</p>
          <input
            required
            placeholder="Años cumplidos"
            id="edad"
            name="edad"
            onChange={leerDato}
            type="number"
            className="validate formulario"
          />
          <label for="edad"></label>
        </div>

        <div
          className="input-field col s12 m12 l12 xl12"
          style={{ margin: "4px 0px" }}
        >
          <p>Actualmente vivo en</p>
          <input
            required
            placeholder="Calle y número"
            id="domicilio"
            name="domicilio"
            onChange={leerDato}
            type="text"
            className="validate formulario"
          />
          <label for="domicilio"></label>
        </div>
        <div
          className="input-field col s12 m6 l6 xl6"
          style={{ margin: "4px 0px" }}
        >
          <input
            required
            placeholder="Colonia"
            id="colonia"
            name="colonia"
            onChange={leerDato}
            type="text"
            className="validate formulario"
          />
          <label for="colonia"></label>
        </div>
        <div
          className="input-field col s12 m6 l6 xl6"
          style={{ margin: "4px 0px" }}
        >
          <input
            required
            placeholder="Código postal"
            id="codigoPostal"
            name="codigoPostal"
            onChange={leerDato}
            type="number"
            max="99999"
            min="10000"
            className="validate formulario"
          />
          <label for="codigoPostal"></label>
        </div>

        <div
          className="input-field col s12 m6 l6 xl6 select-formulario"
          style={{ margin: "4px 0px" }}
        >
          <input
            required
            placeholder="Municipio"
            id="ciudad"
            name="ciudad"
            onChange={leerDato}
            type="text"
            className="validate formulario"
          />
          <label for="ciudad"></label>
        </div>
        <div
          className="input-field col s12 m6 l6 xl6 select-formulario"
          style={{ margin: "4px 0px" }}
        >
          <input
            required
            placeholder="Estado"
            id="estado"
            name="estado"
            onChange={leerDato}
            type="text"
            className="validate formulario"
          />
          <label for="estado"></label>
        </div>

        <div
          className="input-field col s12 m6 l6 xl6"
          style={{ margin: "4px 0px" }}
        >
          <p>Mi celular</p>
          <input
            required
            placeholder="Escribe tu celular"
            id="celular"
            name="celular"
            onChange={leerDato}
            type="number"
            max="9999999999"
            min="1000000000"
            className="validate formulario"
          />
          <label for="celular"></label>
        </div>
        <div
          className="input-field col s12 m6 l6 xl6"
          style={{ margin: "4px 0px" }}
        >
          <p>Mi e-mail</p>
          <input
            required
            placeholder="Escribe tu e-mail"
            id="email"
            name="email"
            onChange={leerDato}
            type="email"
            className="validate formulario"
          />
          <label for="email"></label>
        </div>

        <div
          className="input-field col s12 m6 l6 xl6"
          style={{ margin: "4px 0px" }}
        >
          <p>Estudié la prepa en:</p>
          <input
            required
            placeholder="Nombre de la escuela"
            id="bachiller"
            name="bachiller"
            onChange={leerDato}
            type="text"
            className="validate formulario"
          />
          <label for="bachiller"></label>
        </div>
        <div
          className="input-field col s12 m6 l6 xl6"
          style={{ margin: "4px 0px" }}
        >
          <p className="invisible_movil" style={{ color: "#fff" }}>
            .
          </p>
          <input
            required
            placeholder="Generación"
            id="generacion"
            name="generacion"
            onChange={leerDato}
            type="number"
            className="validate formulario"
          />
          <label for="generacion"></label>
        </div>
        <div
          className="input-field col s12 m6 l6 xl6"
          style={{ margin: "4px 0px" }}
        >
          <input
            required
            placeholder="Municipio"
            id="ciudadBachiller"
            name="ciudadBachiller"
            onChange={leerDato}
            type="text"
            className="validate formulario"
          />
          <label for="ciudadBachiller"></label>
        </div>
      </div>

      <div className="row">
        <div
          className="input-field col s12 m6 l6 xl6"
          style={{ margin: "4px 0px" }}
        >
          <p>Mi grupo sanguíneo es</p>
          <input
            required
            placeholder="Escribe tu tipo de sangre"
            id="tipoSangre"
            name="tipoSangre"
            onChange={leerDato}
            type="text"
            className="validate formulario"
          />
          <label for="tipoSangre"></label>
        </div>

        <div className="col s12">
          <div
            className="input-field col s12 m4 l4 xl4 select-formulario"
            style={{ margin: "4px 0px", paddingLeft: "0" }}
          >
            <p>¿Padezco enfermedad crónicas?</p>
            <select
              required
              placeholder="Selecciona opción"
              name="enfermedad"
              onChange={leerDato}
            >
              <option value="" disabled selected>
                Selecciona una opción
              </option>
              <option value={true} name="enfermedad">
                Si
              </option>
              <option value={false} name="enfermedad">
                No
              </option>
            </select>
          </div>
          {state.enfermedad === "true" && (
            <div
              className="input-field col s12 m8"
              style={{ margin: "4px 0", paddingRight: "0px" }}
            >
              <p className="invisible_movil" style={{ color: "#ffffff" }}>
                .
              </p>
              <input
                placeholder="¿Cuáles?"
                id="enfermedadEspecificacion"
                name="enfermedadEspecificacion"
                onChange={leerDato}
                type="text"
                className="validate formulario"
              />
              <label for="enfermedadEspecificacion"></label>
            </div>
          )}
        </div>

        <div className="col s12" style={{ padding: "0" }}>
          <div
            className="input-field col s12 select-formulario"
            style={{ margin: "4px 0px" }}
          >
            <p>¿Tengo alguna condición especial?</p>
            <select
              required
              placeholder="Selecciona tu condición"
              name="condicionEspecial"
              onChange={leerDato}
            >
              <option value="0" disabled selected>
                Selecciona tu condición
              </option>
              <option
                value="Condición de espectro autista (CEA)."
                name="condicion"
              >
                Condición de espectro autista (CEA).
              </option>
              <option
                value="Trastorno por déficit de atención con hiperactividad (TDAH)."
                name="condicion"
              >
                Trastorno por déficit de atención con hiperactividad (TDAH).
              </option>
              <option
                value="Trastorno de identidad disociativo"
                name="condicion"
              >
                Trastorno de identidad disociativo
              </option>
              <option value="Síndrome de Tourette" name="condicion">
                Síndrome de Tourette
              </option>
              <option value="Dislexia" name="condicion">
                Dislexia
              </option>
              <option value="Discalculia" name="condicion">
                Discalculia
              </option>
              <option value="Ninguna" name="condicion">
                Ninguna
              </option>
              <option value="Otra" name="condicion">
                Otra
              </option>
            </select>
          </div>
          {state.condicionEspecial === "Otra" && (
            <div className="input-field col s12" style={{ margin: "4px 0" }}>
              <input
                placeholder="¿Cuál?"
                id="condicionEspecialOtra"
                name="condicionEspecialOtra"
                onChange={(e) => {
                  setCondicionEspecialOtra(e.target.value);
                }}
                type="text"
                className="validate formulario"
                checked
              />
              <label for="condicionEspecialOtra"></label>
            </div>
          )}
          <p style={{ paddingLeft: "15px", fontSize: "12px", marginTop: "0" }}>
            *En caso de tener alguna condición especial debes pasar a la
            Coordinación Académica antes del inicio de clases.
          </p>
        </div>

        <div
          className="input-field col s12 m6 l6 xl6"
          style={{ margin: "4px 0px" }}
        >
          <p>Contacto de emergencia</p>
          <input
            required
            placeholder="Nombre completo"
            id="nombreEmergencia"
            name="nombreEmergencia"
            onChange={leerDato}
            type="text"
            className="validate formulario"
          />
          <label for="nombreEmergencia"></label>
        </div>
        <div
          className="input-field col s12 m6 l6 xl6"
          style={{ margin: "4px 0px" }}
        >
          <p className="invisible_movil" style={{ color: "#fff" }}>
            .
          </p>
          <input
            required
            placeholder="Teléfono"
            id="telefonoEmergencia"
            name="telefonoEmergencia"
            onChange={leerDato}
            type="number"
            min="1000000000"
            max="9999999999"
            className="validate formulario"
          />
          <label for="telefonoEmergencia"></label>
        </div>

        <div
          className="input-field col s12 m12 l12 xl12"
          style={{ margin: "4px 0px" }}
        >
          <p>Nombre completo de mi mamá o tutora:</p>
          <input
            required
            placeholder="Su nombre completo"
            id="nombreMadre"
            name="nombreMadre"
            onChange={leerDato}
            type="text"
            className="validate formulario"
          />
          <label for="nombreMadre"></label>
        </div>
        <div
          className="input-field col s12 m12 l12 xl12"
          style={{ margin: "4px 0px" }}
        >
          <input
            required
            placeholder="Escribe su celular"
            id="telefonoMadre"
            name="telefonoMadre"
            onChange={leerDato}
            type="number"
            max="9999999999"
            min="1000000000"
            className="validate formulario"
          />
          <label for="telefonoMadre"></label>
        </div>

        <div
          className="input-field col s12 m12 l12 xl12"
          style={{ margin: "4px 0px" }}
        >
          <p>Nombre completo de mi papá o tutor:</p>
          <input
            required
            placeholder="Su nombre completo"
            id="nombrePadre"
            name="nombrePadre"
            onChange={leerDato}
            type="text"
            className="validate formulario"
          />
          <label for="nombrePadre"></label>
        </div>
        <div
          className="input-field col s12 m12 l12 xl12"
          style={{ margin: "4px 0px" }}
        >
          <input
            required
            placeholder="Escribe su celular"
            id="telefonoPadre"
            name="telefonoPadre"
            onChange={leerDato}
            type="number"
            max="9999999999"
            min="1000000000"
            className="validate formulario"
          />
          <label for="telefonoPadre"></label>
        </div>
      </div>

      {/* <div className="row">
        <div
          className="col s12 m12 l12 xl12"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <div style={{ width: "100%", marginTop: "30px" }}>
            <p style={{ margin: "0" }}>¿Cómo te enteraste del CAAV?</p>
          </div>
          <p style={{ marginRight: "20px" }}>
            <label>
              <input
                required
                onChange={leerDato2}
                value="Recomendación"
                name="medioEncontrado"
                type="radio"
              />
              <span style={{ fontStyle: "italic", color: "#5F5F5F" }}>
                Recomendación
              </span>
            </label>
          </p>
          <p style={{ marginRight: "20px" }}>
            <label>
              <input
                required
                onChange={leerDato2}
                value="En un stad de mi prepa"
                name="medioEncontrado"
                type="radio"
              />
              <span style={{ fontStyle: "italic", color: "#5F5F5F" }}>
                En un stad de mi prepa
              </span>
            </label>
          </p>
          <p style={{ marginRight: "20px" }}>
            <label>
              <input
                required
                onChange={leerDato2}
                value="Google"
                name="medioEncontrado"
                type="radio"
              />
              <span style={{ fontStyle: "italic", color: "#5F5F5F" }}>
                Google
              </span>
            </label>
          </p>
          <p style={{ marginRight: "20px" }}>
            <label>
              <input
                required
                onChange={leerDato2}
                value="Instagram"
                name="medioEncontrado"
                type="radio"
              />
              <span style={{ fontStyle: "italic", color: "#5F5F5F" }}>
                Instagram
              </span>
            </label>
          </p>
          <p style={{ marginRight: "20px" }}>
            <label>
              <input
                required
                onChange={leerDato2}
                value="Twitter"
                name="medioEncontrado"
                type="radio"
              />
              <span style={{ fontStyle: "italic", color: "#5F5F5F" }}>
                Twitter
              </span>
            </label>
          </p>
          <p style={{ marginRight: "20px" }}>
            <label>
              <input
                required
                onChange={leerDato2}
                value="Facebook"
                name="medioEncontrado"
                type="radio"
              />
              <span style={{ fontStyle: "italic", color: "#5F5F5F" }}>
                Facebook
              </span>
            </label>
          </p>
          <p style={{ marginRight: "20px" }}>
            <label>
              <input
                required
                onChange={leerDato2}
                value="TikTok"
                name="medioEncontrado"
                type="radio"
              />
              <span style={{ fontStyle: "italic", color: "#5F5F5F" }}>
                TikTok
              </span>
            </label>
          </p>
          <p style={{ marginRight: "20px" }}>
            <label>
              <input
                required
                onChange={leerDato2}
                value="Medios impresos"
                name="medioEncontrado"
                type="radio"
              />
              <span style={{ fontStyle: "italic", color: "#5F5F5F" }}>
                Medios impresos
              </span>
            </label>
          </p>
          <p style={{ marginRight: "20px" }}>
            <label>
              <input
                required
                onChange={leerDato2}
                value="Porque vi la escuela al pasar"
                name="medioEncontrado"
                type="radio"
              />
              <span style={{ fontStyle: "italic", color: "#5F5F5F" }}>
                Porque vi la escuela al pasar
              </span>
            </label>
          </p>
          <p style={{ marginRight: "20px" }}>
            <label>
              <input
                required
                onChange={leerDato2}
                value="Me llegó un email"
                name="medioEncontrado"
                type="radio"
              />
              <span style={{ fontStyle: "italic", color: "#5F5F5F" }}>
                Me llegó un email
              </span>
            </label>
          </p>
          <p style={{ marginRight: "20px" }}>
            <label>
              <input
                required
                onChange={() => {
                  setOtroMedio(true);
                }}
                value="flase"
                name="medioEncontrado"
                type="radio"
              />
              <span style={{ fontStyle: "italic", color: "#5F5F5F" }}>
                Por otro medio
              </span>
            </label>
          </p>
        </div>
        {otroMedio && (
          <div
            className="input-field col s12 m6 l6 xl6"
            style={{ marginBottom: "30px" }}
          >
            <input
              placeholder="¿Cuál?"
              id="medioEncontrado"
              name="medioEncontrado"
              onChange={leerDato}
              type="text"
              className="validate formulario"
            />
            <label for="medioEncontrado"></label>
          </div>
        )}
      </div> */}

      <p
        className="col s12 titulo_4_nv"
        style={{
          fontFamily: "Montserrat-Bold",
          color: "#DE2657",
          margin: "15px 0 0 0",
        }}
      >
        Elijo liquidar este cuatrimestre:
      </p>
      <div
        className="input-field col s12 select-formulario"
        style={{ margin: "4px 0px", padding: "0" }}
      >
        <select
          required
          placeholder="Selecciona opción"
          name="pago"
          onChange={leerDato}
        >
          <option value="" disabled selected>
            Selecciona una opción
          </option>
          <option
            value="En un solo pago, dentro de los primeros 20 días naturales del cuatrimestre."
            name="pago"
          >
            En un solo pago, dentro de los primeros 20 días naturales del
            cuatrimestre.
          </option>
          <option
            value="En cuatro pagos, antes del día 10 de cada mes."
            name="pago"
          >
            En cuatro pagos, antes del día 10 de cada mes.
          </option>
        </select>
      </div>

      <p
        className="col s12 titulo_4_nv"
        style={{
          fontFamily: "Montserrat-Bold",
          margin: "15px 0 0 0",
        }}
      >
        Adjunta comprobantes <br className="invisible_escritorio" />
        de pago.
      </p>
      <p style={{ paddingLeft: "15px" }}>
        *Sólo un archivo PDF no mayor a 3 MB. (Si es necesario, comprime tu
        archivo). Puedes convertir tus imágenes a pdf, con{" "}
        <a
          href="https://www.ilovepdf.com/es/comprimir_pdf"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "underline", color: "#02B5C1" }}
        >
          Ilovepdf
        </a>
      </p>

      <div
        class="file-field input-field"
        style={{ display: "flex", alignItems: "center" }}
      >
        <button className="boton_examinar">
          <div>
            <p>Examinar archivos</p>
          </div>
          <input type="file" accept="application/pdf" onChange={leerArchivo} />
        </button>
        <p style={{ display: "inline" }}>
          <b>Archivo:</b> {archivo.length > 0 && archivo[0].name}
        </p>
      </div>

      <p>
        <label className="check-box-label">
          <input
            onChange={() => {
              setValidacion(!validacion);
            }}
            type="checkbox"
            className="filled-in"
          />
          <span>
            <b>
              Declaro bajo protesta de decir verdad, manifiesto que los datos
              que proporciono son verídicos.
            </b>
            <br />
            El domicilio, correo electrónico y teléfono, es donde puedo recibir
            notificaciones de cualquier tipo. Estoy consciente que al omitir o
            dar información falsa se me cancelará mi trámite de ingreso o podría
            ser sujeto a baja.
            <br />
            <br />
            *Toda tu información es confidencial y sólo será utilizada por el
            CAAV, para fines administrativos y de comunicación contigo. Puedes
            ver nuestro aviso de privacidad en: caav.mx/aviso-privacidad.
          </span>
        </label>
      </p>

      <div id="cargando"></div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        {!validacion ? (
          <button disabled type="button" className="boton_2_rein-des">
            <p>Enviar formulario</p>
          </button>
        ) : (
          <button type="submit" className="boton_2_rein">
            <p>Enviar formulario</p>
          </button>
        )}
      </div>
    </form>
  );
};

export default FormInscripcion;
