import React from 'react';

const Videorreunion = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="29px" height="19.33px" viewBox="0 0 25.09 16.73" style={{marginBottom:"6px"}}>
            <path id="Trazado_28" data-name="Trazado 28" d="M129.773,20.949V16.07a1.4,1.4,0,0,0-1.395-1.395H111.655a1.4,1.4,0,0,0-1.395,1.395V30.01a1.4,1.4,0,0,0,1.395,1.395h16.724a1.4,1.4,0,0,0,1.395-1.395V25.131l5.577,5.577V15.372Z" transform="translate(-110.26 -14.675)" fill="#64c5dd" opacity="0.88" />
        </svg>
    );
};

export default Videorreunion;