import React from 'react';

import './Loader.css';

const Loader = () => {
    return (
        <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%"}}>
            <div class="preloader-caav">
                <div class="loader-caav"></div>
            </div>
        </div>
    );
};

export default Loader;