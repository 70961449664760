import React, { Fragment, useEffect, useState } from "react";
import Swal from "sweetalert2";
import M from "materialize-css";

// import Carousel from 'react-gallery-carousel';
// import 'react-gallery-carousel/dist/index.css';

import Loader from "../../../Components/Loader/Loader";

import "./Curso.css";

import CursosHelper from "../../../Helpers/Cursos";
import EtiquetasHelper from "../../../Helpers/Etiquetas";
import ProfesoresCursosHelpers from "../../../Helpers/ProfesoresCursos";
import MensajeHelpers from "../../../Helpers/Mensajes";
import BanersHelper from "../../../Helpers/Baners";

// formato
import img_curso from "../../../Images/escritorio/Cursos/iconos/curso.svg";
import img_diplomado from "../../../Images/escritorio/Cursos/iconos/diplomado.svg";
import img_taller from "../../../Images/escritorio/Cursos/iconos/taller.svg";
// modalidad
import img_classroom from "../../../Images/escritorio/Cursos/iconos/classroom.svg";
import img_hibrido from "../../../Images/escritorio/Cursos/iconos/hibrido.svg";
import img_video from "../../../Images/escritorio/Cursos/iconos/video.svg";
import img_presencial from "../../../Images/escritorio/Cursos/iconos/presencial.svg";
// otros
import img_nivel from "../../../Images/escritorio/Cursos/iconos/nivel.svg";
import img_warning from "../../../Images/escritorio/Cursos/iconos/warning.svg";
import img_requieres from "../../../Images/escritorio/Cursos/iconos/requieres.svg";
import img_whats from "../../../Images/escritorio/Cursos/iconos/whats.svg";
import CountdownTimer from "../../../Components/Baner/CountdownTimer ";

const Curso = () => {
  const baner = false;
  const area = sessionStorage.getItem("area");
  const [curso, setCurso] = useState({});
  const [etiquetas, setEtiquetas] = useState([]);
  const [profesoresCursos, setProfesoresCursos] = useState([]);
  const [flag, setFlag] = useState(false);
  const [instance, setInstance] = useState(0);

  const formato_img = ["", img_taller, img_curso, img_diplomado];
  const modalidad_img = [
    "",
    img_presencial,
    img_hibrido,
    img_video,
    img_classroom,
  ];

  const modalidadStorage = sessionStorage.getItem("modalidad");
  const formatoStorage = sessionStorage.getItem("formato");
  const areaTitulo = [
    "",
    "Animación, Animé y Cómic",
    "Audio y Voz",
    "Cine y Video",
    "Artes plásticas",
    "Diseño",
    "Emprendimiento",
    "Escritura",
    "Fotografía",
    "Teoría del arte",
    "Marketing y la Web",
    "Multimedia y Videojuegos",
    "Suite de Adobe",
    "Artes escénicas",
  ];
  const modailida = ["", "Presencial", "Híbrido", "Videorreunión", "Classroom"];
  const formato = ["", "Taller", "Curso", "Diplomado"];
  const nivel = [
    "",
    "Iniciación",
    "Requiere conocimientos previos",
    "- vacio -",
    " - vacio -",
  ];
  const meses = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  const diasSemana = [
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "On demand",
  ];

  const [descripcionCurso, setDescripcionCurso] = useState("");
  const [descripcionInterna, setDescripcionInterna] = useState("");
  const [contenido, setContenido] = useState("");
  const [fechas, setFechas] = useState([]);
  const [modalElement, setModalElement] = useState(false);
  const [hablemos, setHablemos] = useState(false);
  const [condiciones, setCondiciones] = useState(false);

  // informacion baner
  const [color, setColor] = useState("");

  const [state, setState] = useState({
    vista: "cursos",
    mensaje: "",
  });

  useEffect(() => {
    if (!modalElement) {
      obtenerConfiguracionBaner();
    } else {
      if (window.screen.width <= 980) {
        window.addEventListener("scroll", () => {
          // efecto movil
          let imagen_estatica = document.getElementById("seccion_curso_fixme");
          // console.log({imagen_estatica});
          let imagen_estatica_bottom =
            imagen_estatica.getBoundingClientRect().bottom;
          // console.log({imagen_estatica_bottom});
          if (imagen_estatica_bottom <= 90) {
            M.Modal.getInstance(modalElement[0]).open();
          } else {
            M.Modal.getInstance(modalElement[0]).close();
          }
        });
      } else {
        window.addEventListener("scroll", () => {
          // efecto escritorio
          let efecto_escritorio = document.getElementById("efecto_escritorio");
          let seccion_curso_fixme_2 = document.getElementById(
            "seccion_curso_fixme_2"
          );
          let fondo_curso = document.getElementById("fondo_curso");

          let seccion_curso_fixme_2_bottom =
            seccion_curso_fixme_2.getBoundingClientRect().bottom;
          let fondo_curso_bottom = fondo_curso.getBoundingClientRect().bottom;
          if (
            seccion_curso_fixme_2_bottom <= 130 &&
            fondo_curso_bottom >= 1100
          ) {
            efecto_escritorio.className =
              "col s12 m12 l4 xl4 visible_curso_etiqueta_sticky";
          } else {
            efecto_escritorio.className =
              "col s12 m12 l4 xl4 invisible_curso_etiqueta";
          }
        });
      }
    }
  }, [modalElement]);

  const obtenerConfiguracionBaner = async () => {
    // TODO: esta consulta debe de ser en un context para usarlo en multiples vistas y no hacer la consulta doble :v
    let res = await BanersHelper.obtenerConfiguracionBaners();

    if (res.success) {
      setColor(res.data.color);
      obtenerCurso();
    } else {
      Swal.fire(
        "Error!",
        "No se lograron obtener la configuracion de los baners, codigo: " +
          res.code,
        "error"
      );
    }
  };

  const obtenerCurso = async () => {
    let nombre = window.location.pathname.split("/")[2];
    let res = await CursosHelper.obtenerUnCursoNombre(nombre);
    if (res.success) {
      setCurso(res.data[0]);
      setState({
        ...state,
        mensaje: "Me interesa el curso: " + res.data[0].nombre,
      });
      setDescripcionCurso(res.data[0].descripcion_curso);
      setDescripcionInterna(res.data[0].descripcion_interna);
      setContenido(res.data[0].contenido);
      await obtenerEtiquetas(res.data[0].etiquetas);
      setFechas(res.data[0].fechas);
      obtenerProfesoresCursos();
    } else {
      Swal.fire(
        "Error!",
        "No se lograron obtener el curso, codigo: " + res.code,
        "error"
      );
    }
  };

  const obtenerProfesoresCursos = async () => {
    let res = await ProfesoresCursosHelpers.obtenerProfesores();
    if (res.success) {
      setProfesoresCursos(res.data);
      setFlag(true);
      setTimeout(() => {
        let elems = document.querySelectorAll(".collapsible");
        M.Collapsible.init(elems);

        let modal = document.querySelectorAll(".modal");
        M.Modal.init(modal, {
          preventScrolling: false,
          opacity: 0,
          dismissible: false,
          inDuration: 500,
          outDuration: 1000,
        });
        setModalElement(modal);

        var carousel = document.querySelectorAll(".carousel");
        let instance = M.Carousel.init(carousel, {
          fullWidth: true,
        });
        setInstance(instance[0]);
      }, 100);
    } else {
      Swal.fire(
        "Error!",
        "No se lograron obtener los profesores, codigo: " + res.code,
        "error"
      );
    }
  };

  const obtenerEtiquetas = async (ids) => {
    let res = [];

    if (ids) {
      res = await EtiquetasHelper.obtenerEtiquetasIDs(ids);
    } else {
      res = await EtiquetasHelper.obtenerEtiquetas();
    }

    if (res.success) {
      setEtiquetas(res.data);
    } else {
      Swal.fire(
        "Error!",
        "No se lograron obtener las etiquetas, codigo: " + res.code,
        "error"
      );
    }

    return true;
  };

  function createMarkup(materias) {
    return { __html: materias };
  }

  const obtenerProfesor = (id) => {
    let res = profesoresCursos.filter((profesor) => profesor._id === id);

    if (res.length > 0) {
      return res[0];
    } else {
      return {
        nombre: "- informacion invalida -",
        certificado: "- informacion invalida -",
        imagen: "- informacion invalida -",
      };
    }
  };

  const leerDato = (e) => {
    e.preventDefault();
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const seleccionarContacto = (e) => {
    e.preventDefault();
    let contacto;
    const target_select = document.getElementsByClassName("boton_pago_select");

    if (e.target.parentNode.id === "correo") {
      contacto = "Correo electrónico";
    } else if (e.target.parentNode.id === "llamda") {
      contacto = "Llamada";
    } else {
      contacto = "Whatsapp";
    }

    setState({ ...state, [e.target.className]: contacto });

    //efecto de seleccion en el boton
    if (target_select.length !== 0) {
      target_select[0].className = "boton_evaluacion";
    }
    e.target.parentNode.className = "boton_pago_select";
  };

  const enviarFormulario = async (e) => {
    e.preventDefault();
    setHablemos(false);

    let res = await MensajeHelpers.agregarMensaje(state);

    if (res.success) {
      window.location.replace("/mensaje-enviado-curso");
    } else {
      Swal.fire(
        "Error!",
        "No se pudo enviar la información, codigo: " + res.code,
        "error"
      ).then(() => {
        window.location.reload();
      });
    }
  };

  const todosFormato = () => {
    sessionStorage.removeItem("modalidad");
    sessionStorage.removeItem("formato");
    sessionStorage.setItem("formato", formatoStorage);
    window.location.replace(`/cursos-area/${area}`);
  };

  const todosModalidad = () => {
    sessionStorage.removeItem("modalidad");
    sessionStorage.removeItem("formato");
    sessionStorage.setItem("modalidad", modalidadStorage);
    window.location.replace(`/cursos-area/${area}`);
  };

  const todosAreas = () => {
    sessionStorage.removeItem("modalidad");
    sessionStorage.removeItem("formato");
    window.location.replace(`/cursos-area/${area}`);
  };

  const numeroFormateadoPunto = (numero) => {
    let numeroFormateadoPunto = parseFloat(numero.toFixed(2)).toLocaleString(
      undefined,
      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    );

    return numeroFormateadoPunto;
  };

  const numeroFormateado = (numero) => {
    let numeroFormateado = parseInt(numero).toLocaleString();

    return numeroFormateado;
  };

  const obtenerRangoFechas = (textoFin) => {
    let fechaFin = new Date(textoFin);

    const opcionesFecha = { year: "numeric", month: "long", day: "numeric" };

    const fechaFinFormateada = fechaFin.toLocaleDateString(
      undefined,
      opcionesFecha
    );

    return `al ${fechaFinFormateada}`;
  };

  const gtag_report_conversion = (url) => {
    let urlNueva = encodeURI(url);

    var callback = function () {
      if (typeof urlNueva != "undefined") {
        window.location = urlNueva;
      }
    };
    window.gtag("event", "conversion", {
      send_to: "AW-1004461178/itL1CPmlldMYEPq4-94D",
      event_callback: callback,
    });
    return false;
  };

  return (
    <Fragment>
      {hablemos && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            className="contenedor_pop_curso"
            onClick={() => {
              setHablemos(false);
            }}
          ></div>
          <div className="pop_curso">
            <div
              className="pop_salir"
              onClick={() => {
                setHablemos(false);
              }}
            />
            <p
              className="titulo_pop"
              style={{ color: "#575757", textAlign: "center", margin: "0" }}
            >
              ¿Te interesa este curso para un equipo?, <br />
              escríbenos para hacer un convenio
            </p>
            <form onSubmit={enviarFormulario}>
              <div className="row">
                <div
                  className="input-field col s12 m12 l6"
                  style={{ marginBottom: "-10px" }}
                >
                  <input
                    required
                    placeholder="Mi nombre"
                    id="nombre"
                    name="nombre"
                    onChange={leerDato}
                    type="text"
                    className="validate formulario"
                  />
                  <label for="nombre"></label>
                </div>
                <div
                  className="input-field col s12 m12 l6"
                  style={{ marginBottom: "-10px" }}
                >
                  <input
                    required
                    placeholder="Mi e-mail"
                    id="email"
                    name="email"
                    onChange={leerDato}
                    type="email"
                    className="validate formulario"
                  />
                  <label for="email"></label>
                </div>
                <div
                  className="input-field col s12 m12 l6"
                  style={{ marginBottom: "-10px" }}
                >
                  <input
                    required
                    placeholder="Mi celular"
                    id="celular"
                    name="celular"
                    onChange={leerDato}
                    type="text"
                    className="validate formulario"
                  />
                  <label for="celular"></label>
                </div>
                <div
                  className="input-field col s12 m12 l6"
                  style={{ marginBottom: "-10px" }}
                >
                  <input
                    required
                    placeholder="Mi empresa o institución"
                    id="empresa"
                    name="empresa"
                    onChange={leerDato}
                    type="text"
                    className="validate formulario"
                  />
                  <label for="empresa"></label>
                </div>
              </div>
              <p className="center" style={{ marginTop: "30px" }}>
                Quiero que me contacten por
              </p>
              <div className="row contenedor_aspirantes_pagos">
                <div className="col s12 m12 l4 xl4">
                  <div
                    className="boton_asp_form_cuatro_pagos"
                    id="correo"
                    onClick={seleccionarContacto}
                    style={{ fontStyle: "italic", width: "100%" }}
                  >
                    <p className="pago">Correo electrónico</p>
                  </div>
                </div>
                <div className="col s12 m12 l4 xl4">
                  <div
                    className="boton_asp_form_un_pago"
                    id="llamda"
                    onClick={seleccionarContacto}
                    style={{ fontStyle: 'italic, width:"100%"' }}
                  >
                    <p className="pago">Llamada</p>
                  </div>
                </div>
                <div className="col s12 m12 l4 xl4">
                  <div
                    className="boton_evaluacion"
                    id="evaluacion"
                    onClick={seleccionarContacto}
                    style={{ fontStyle: "italic", width: "100%" }}
                  >
                    <p className="pago">Whatsapp</p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "25px",
                }}
              >
                <button className="boton_curso" type="submit">
                  Enviar mensaje
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {condiciones && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            className="contenedor_pop_curso"
            onClick={() => {
              setCondiciones(false);
            }}
          ></div>
          <div className="pop_curso">
            <div
              className="pop_salir"
              onClick={() => {
                setCondiciones(false);
              }}
            />
            <p
              className="titulo_pop"
              style={{ color: color, textAlign: "center", margin: "0" }}
            >
              Éstas son las condiciones para ti:
            </p>
            <p>
              1. Debes ser estudiante vigente, regular, de una licenciatura del
              CAAV. <br />
              2. No debes tener adeudos administrativos, de colegiaturas, ni en
              biblioteca o videoteca. <br />
              3. Puedes inscribirte a cualquiera de los cursos, mientras haya
              cupo disponible. <br />
              4. Es importante que te inscribas con anticipación: tu límite es
              48 horas antes del inicio del curso. Después de este límite no
              será posible inscribirte. <br />
              5. Pide el formulario de inscripción en recepción, llénalo y
              entrégalo ahí mismo, o solicítalo en digital al correo
              cursos@caav.mx, llénalo y reenvíalo a ese mismo correo. <br />
              6. Debes saber que los cursos, talleres y diplomados son
              gratuitos, excepto unos pocos, que para estudiantes tienen un
              costo mínimo, de recuperación. <br />
              7. Para que tus cursos no tengan costo, o tengan costo
              preferencial, debes tener una asistencia mínima de 80%. Es decir,
              si tienes más de 20% de faltas o simplemente no los concluyes,
              deberás pagar 100% del precio al público. <br />
              8. Tus vacaciones de licenciatura no son aplicables a los cursos,
              porque son productos independientes. <br />
              9. Importante: cada curso puede suspenderse o postergarse, en caso
              de que no reúna el quórum mínimo necesario para éste. En ese caso,
              si decides suspender o cancelar tu participación, puedes hacerlo
              sin penalización de pago, o dejar abierta tu solicitud para cuando
              se abra una fecha nueva. Por otro lado, si decides no asistir o te
              das de baja, no recibirás devolución de tu pago, en caso de
              haberlo hecho. <br />
            </p>
          </div>
        </div>
      )}
      {!flag ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Loader />
        </div>
      ) : (
        <Fragment>
          <div className="ancho_imagen_curso">
            <img
              id="imagen_estatica"
              src={curso.imagen_interna}
              alt={curso.nombre}
              style={{
                width: "100%",
              }}
            />
          </div>
          <div
            className="ancho_imagen_curso navegacion_sticky"
            data-class={baner}
          >
            <div
              className={
                "navegacion_cursos_principal background_defecto background_" +
                area
              }
            >
              <b>
                <a href="/cursos-diplomados">Todos</a>
              </b>
              <div />
              {area !== "aleatorio" && (
                <Fragment>
                  <b style={{ cursor: "pointer" }} onClick={todosAreas}>
                    {areaTitulo[area]}
                  </b>
                  <div />
                </Fragment>
              )}
              <b>{curso.nombre}</b>
              {modalidadStorage && (
                <Fragment>
                  <div />
                  <b style={{ cursor: "pointer" }} onClick={todosModalidad}>
                    {modailida[modalidadStorage]}
                  </b>
                </Fragment>
              )}
              {formatoStorage && (
                <Fragment>
                  <div />
                  <b style={{ cursor: "pointer" }} onClick={todosFormato}>
                    {formato[formatoStorage]}
                  </b>
                </Fragment>
              )}
            </div>
          </div>
          <div className="container contenedor_grande_curso row">
            <div className="col s12 m12 l8 xl8 padding_right_curso">
              <p
                className={"titulo_1_nv color_defecto color_" + area}
                style={{
                  marginBottom: "10px",
                  marginBottom: "-15px",
                }}
              >
                {curso.nombre}
              </p>
              <div
                className={
                  "informacion_curso color_contenedor_defecto color_contenedor_" +
                  area
                }
                dangerouslySetInnerHTML={createMarkup(descripcionCurso)}
              />
              <div style={{ display: "flex" }}>
                <a
                  target="_blank"
                  href={`/cursos-profesor/${curso.fechas[0].tutor}`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "25px",
                    margin: "30px 0",
                  }}
                >
                  <img
                    style={{ width: "75px", borderRadius: "50%" }}
                    src={obtenerProfesor(curso.fechas[0].tutor).imagen}
                    alt="profesor"
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p className="etiqueta_profesor">
                        {obtenerProfesor(curso.fechas[0].tutor).nombre}
                      </p>
                      <p className="etiqueta_fin_profesor">.</p>
                    </div>
                    <p style={{ margin: "0", color: "#000" }}>
                      {obtenerProfesor(curso.fechas[0].tutor).certificado}
                    </p>
                  </div>
                </a>
              </div>
              <div>
                <div className="carousel carousel-slider">
                  {curso.carrusel_imagenes.map((imagen, index) => (
                    <a className="carousel-item">
                      <img
                        src={imagen}
                        alt={curso.nombre}
                        style={{ width: "100%" }}
                      />
                    </a>
                  ))}
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {curso.carrusel_imagenes.map((imagen, index) => (
                    <div style={{ width: "15.5%" }}>
                      <img
                        src={imagen}
                        alt={curso.nombre}
                        onClick={() => {
                          instance.set(index);
                        }}
                        style={{ width: "100%" }}
                      />
                    </div>
                  ))}
                </div>
              </div>
              {window.screen.width <= 980 && (
                <Fragment>
                  <div id="curso_fixme" className="curso_fijo">
                    <div className="tarjeta_fixme" style={{ marginTop: "0" }}>
                      <div className="seccion_curso_fixme">
                        <p
                          className="titulo_4_nv"
                          style={{ fontWeight: "bold", margin: "0" }}
                        >
                          Inversión total
                        </p>
                        {curso.estado_relampago !== undefined ? (
                          new Date(curso.estado_relampago.fecha_fin).getTime() >
                          new Date().getTime() ? (
                            <Fragment>
                              <p
                                className={
                                  "encabezadoPrecio color_defecto color_" + area
                                }
                                style={{
                                  fontWeight: "bold",
                                  margin: "0px",
                                  color: color,
                                }}
                              >
                                $
                                {numeroFormateadoPunto(
                                  parseFloat(
                                    parseFloat(curso.data_pago.costo_total) *
                                      ((100 -
                                        parseInt(
                                          curso.estado_relampago.descuento
                                        )) /
                                        100)
                                  )
                                )}{" "}
                                MXN
                              </p>
                              <p style={{ margin: "0" }}>
                                {curso.estado_relampago.descuento}% de descuento{" "}
                                <span
                                  style={{ textDecoration: "line-through" }}
                                >
                                  $
                                  {numeroFormateado(
                                    curso.data_pago.costo_total
                                  )}{" "}
                                  MXN
                                </span>
                              </p>
                              <p style={{ color: color, margin: "0" }}>
                                <b>
                                  La oferta termina en{" "}
                                  <CountdownTimer
                                    fecha={curso.estado_relampago.fecha_fin}
                                  />
                                </b>{" "}
                              </p>
                              <p style={{ color: color, marginTop: "0" }}>
                                *Oferta válida de hoy{" "}
                                {obtenerRangoFechas(
                                  curso.estado_relampago.fecha_fin
                                )}
                                , en pago de contado.
                              </p>
                            </Fragment>
                          ) : (
                            <p
                              className={
                                "encabezadoPrecio color_defecto color_" + area
                              }
                              style={{
                                fontWeight: "bold",
                                marginBottom: "1px",
                                marginTop: "0",
                              }}
                            >
                              ${numeroFormateado(curso.data_pago.costo_total)}{" "}
                              MXN
                            </p>
                          )
                        ) : (
                          <p
                            className={
                              "encabezadoPrecio color_defecto color_" + area
                            }
                            style={{
                              fontWeight: "bold",
                              marginBottom: "1px",
                              marginTop: "0",
                            }}
                          >
                            ${numeroFormateado(curso.data_pago.costo_total)} MXN
                          </p>
                        )}
                        {curso.estado_relampago !== undefined
                          ? !new Date(
                              curso.estado_relampago.fecha_fin
                            ).getTime() > new Date().getTime() &&
                            curso.data_pago.costo_mensual && (
                              <Fragment>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  O tres pagos:
                                </p>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  Pago inicial: $
                                  {numeroFormateado(
                                    curso.data_pago.costo_mensual / 2
                                  )}{" "}
                                  MXN
                                </p>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  Más dos mensualidades de: $
                                  {numeroFormateado(
                                    curso.data_pago.costo_mensual / 4
                                  )}{" "}
                                  MXN
                                </p>
                              </Fragment>
                            )
                          : curso.data_pago.costo_mensual && (
                              <Fragment>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  O tres pagos:
                                </p>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  Pago inicial: $
                                  {numeroFormateado(
                                    curso.data_pago.costo_mensual / 2
                                  )}{" "}
                                  MXN
                                </p>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  Más dos mensualidades de: $
                                  {numeroFormateado(
                                    curso.data_pago.costo_mensual / 4
                                  )}{" "}
                                  MXN
                                </p>
                              </Fragment>
                            )}
                        {curso.gratis && (
                          <p
                            style={{
                              fontWeight: "bold",
                              color: "#8A6AAC",
                              marginTop: "1px",
                            }}
                          >
                            Si eres estudiante de nuestras licenciaturas{" "}
                            {curso.gratis_costo
                              ? "$" + numeroFormateado(curso.gratis_costo) + " "
                              : "$ 000 "}
                            MXN
                            <button
                              style={{
                                color: "#00B5C1",
                                border: "none",
                                background: "transparent",
                                fontWeight: "bold",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setCondiciones(true);
                              }}
                            >
                              Ver condiciones
                            </button>
                          </p>
                        )}
                      </div>
                      <div className="divider" />
                      <div className="seccion_curso_fixme">
                        <div style={{ margin: "15px 0" }}>
                          <div style={{ marginTop: "15px" }}>
                            <p
                              style={{
                                margin: "0px",
                                fontWeight: "bold",
                                color: "#464646",
                              }}
                            >
                              Nivel
                            </p>
                            <div className="icono_texto">
                              <img
                                src={img_nivel}
                                alt="nivel del curso"
                                style={{ height: "15px" }}
                              />
                              <p style={{ margin: "0px", color: "#5ABF3F" }}>
                                {nivel[curso.nivel]}
                              </p>
                            </div>
                          </div>
                          <div style={{ marginTop: "15px" }}>
                            <p
                              style={{
                                margin: "0px",
                                fontWeight: "bold",
                                color: "#464646",
                              }}
                            >
                              Formato
                            </p>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {curso.formato.map((formato_numero, index) => (
                                <div className="icono_texto">
                                  <img
                                    src={formato_img[formato_numero]}
                                    alt="formato del curso"
                                    style={{ width: "18px" }}
                                  />
                                  <p
                                    style={{
                                      margin: "0 5px 0 0",
                                      color: "#F49A3F",
                                    }}
                                  >
                                    {formato[formato_numero]}{" "}
                                    {index < curso.formato.length - 1 && ","}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div style={{ marginTop: "15px" }}>
                            <p
                              style={{
                                margin: "0px",
                                fontWeight: "bold",
                                color: "#464646",
                              }}
                            >
                              Modalidad
                            </p>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {curso.modalidad.map((modaidad_numero, index) => (
                                <div className="icono_texto">
                                  <img
                                    src={modalidad_img[modaidad_numero]}
                                    alt="modalidad del curso"
                                    style={{ height: "13px" }}
                                  />
                                  <p
                                    style={{
                                      margin: "0 5px 0 0",
                                      color: "#64C5DD",
                                    }}
                                  >
                                    {modailida[modaidad_numero]}{" "}
                                    {index < curso.modalidad.length - 1 && ","}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            marginBottom: "15px",
                          }}
                        >
                          <div className="col s6">
                            <div className="icono_texto">
                              <img
                                src={img_requieres}
                                alt="formato del curso"
                              />
                              <p
                                style={{
                                  margin: "0px",
                                  fontWeight: "bold",
                                  color: color,
                                }}
                              >
                                Requieres
                              </p>
                            </div>
                            {curso.requisitos.split(",").map((requiere) => (
                              <p style={{ margin: "0px", color: "#464646" }}>
                                {requiere}
                              </p>
                            ))}
                          </div>
                          <div className="col s6">
                            <p
                              style={{
                                margin: "0px",
                                fontWeight: "bold",
                                color: "#464646",
                              }}
                            >
                              Días
                            </p>
                            {curso.dias.map((dia) => (
                              <a
                                target="_blank"
                                href={`/cursos-en-dias/${dia}`}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    className="etiqueta"
                                    style={{ background: "#F0B100" }}
                                  >
                                    {diasSemana[dia]}
                                  </p>
                                  <p
                                    className="etiqueta_fin"
                                    style={{ background: "#F0B100" }}
                                  >
                                    .
                                  </p>
                                </div>
                              </a>
                            ))}
                          </div>
                        </div>

                        <div>
                          <p
                            style={{
                              margin: "0px",
                              fontWeight: "bold",
                              color: "#464646",
                            }}
                          >
                            Relacionados con tu búsqueda:
                          </p>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "0 10px",
                            }}
                          >
                            {etiquetas.map((etiqueta) => (
                              <a
                                target="_blank"
                                href={`/cursos-relacion/${etiqueta._id}`}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <p className="etiqueta">{etiqueta.nombre}</p>
                                  <p className="etiqueta_fin">.</p>
                                </div>
                              </a>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="divider" />
                      <div
                        className="seccion_curso_fixme"
                        style={{ marginTop: "15px" }}
                      >
                        <a
                          onClick={() => {
                            gtag_report_conversion(
                              "https://api.whatsapp.com/send?phone=3318958778&text=Hola me interesa " +
                                curso.nombre +
                                " ¿puedes ayudarme a inscribirme?"
                            );
                          }}
                          className="boton_curso"
                          target="_blank"
                          href={encodeURI(
                            "https://api.whatsapp.com/send?phone=3318958778&text=Hola me interesa " +
                              curso.nombre +
                              " ¿puedes ayudarme a inscribirme?"
                          )}
                        >
                          <img src={img_whats} alt="WhatsApp" />
                          <p style={{ margin: "0px" }}>¡Quiero inscribirme!</p>
                        </a>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <img
                            src={img_warning}
                            alt="Apertura sujeta a un quórum mínimo"
                          />
                          <p
                            style={{
                              color: "#A80B38",
                              fontWeight: "bold",
                              fontSize: "13px",
                            }}
                          >
                            Apertura sujeta a un quórum mínimo
                          </p>
                        </div>
                        <div id="seccion_curso_fixme">
                          <p
                            style={{
                              fontWeight: "bold",
                              color: "#00476F",
                              textAlign: "center",
                              fontSize: "15px",
                              margin: "0",
                            }}
                          >
                            ¿Te interesa este curso para un equipo?
                          </p>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            <button
                              className="boton_curso"
                              onClick={() => {
                                setHablemos(true);
                              }}
                            >
                              <p style={{ margin: "0px", fontSize: "15px" }}>
                                Hablemos
                              </p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sombra_etiqueta_movil modal bottom-sheet">
                    <div
                      className="row"
                      style={{ padding: "10px 20px 0 20px", margin: "0" }}
                    >
                      <p
                        className="titulo_4_nv"
                        style={{
                          fontWeight: "bold",
                          margin: "0",
                          textAlign: "center",
                        }}
                      >
                        Inversión total
                      </p>
                      <div className="col s12 center">
                        {curso.estado_relampago !== undefined ? (
                          new Date(curso.estado_relampago.fecha_fin).getTime() >
                          new Date().getTime() ? (
                            <Fragment>
                              <p
                                className={
                                  "encabezadoPrecio color_defecto color_" + area
                                }
                                style={{
                                  fontWeight: "bold",
                                  margin: "0px",
                                  color: color,
                                }}
                              >
                                $
                                {numeroFormateadoPunto(
                                  parseFloat(
                                    parseFloat(curso.data_pago.costo_total) *
                                      ((100 -
                                        parseInt(
                                          curso.estado_relampago.descuento
                                        )) /
                                        100)
                                  )
                                )}{" "}
                                MXN
                              </p>
                              <p style={{ margin: "0" }}>
                                {curso.estado_relampago.descuento}% de descuento{" "}
                                <span
                                  style={{ textDecoration: "line-through" }}
                                >
                                  $
                                  {numeroFormateado(
                                    curso.data_pago.costo_total
                                  )}{" "}
                                  MXN
                                </span>
                              </p>
                              <p style={{ color: color, margin: "0" }}>
                                <b>
                                  La oferta termina en{" "}
                                  <CountdownTimer
                                    fecha={curso.estado_relampago.fecha_fin}
                                  />
                                </b>{" "}
                              </p>
                              <p style={{ color: color, marginTop: "0" }}>
                                *Oferta válida de hoy{" "}
                                {obtenerRangoFechas(
                                  curso.estado_relampago.fecha_fin
                                )}
                                , en pago de contado.
                              </p>
                            </Fragment>
                          ) : (
                            <p
                              className={
                                "encabezadoPrecio color_defecto color_" + area
                              }
                              style={{
                                fontWeight: "bold",
                                margin: "1px auto",
                              }}
                            >
                              ${numeroFormateado(curso.data_pago.costo_total)}{" "}
                              MXN
                            </p>
                          )
                        ) : (
                          <p
                            className={
                              "encabezadoPrecio color_defecto color_" + area
                            }
                            style={{
                              fontWeight: "bold",
                              margin: "1px auto",
                            }}
                          >
                            ${numeroFormateado(curso.data_pago.costo_total)} MXN
                          </p>
                        )}
                        {curso.estado_relampago !== undefined
                          ? !new Date(
                              curso.estado_relampago.fecha_fin
                            ).getTime() > new Date().getTime() &&
                            curso.data_pago.costo_mensual && (
                              <Fragment>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  O tres pagos:
                                </p>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  Pago inicial: $
                                  {numeroFormateado(
                                    curso.data_pago.costo_mensual / 2
                                  )}{" "}
                                  MXN
                                </p>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  Más dos mensualidades de: $
                                  {numeroFormateado(
                                    curso.data_pago.costo_mensual / 4
                                  )}{" "}
                                  MXN
                                </p>
                              </Fragment>
                            )
                          : curso.data_pago.costo_mensual && (
                              <Fragment>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  O tres pagos:
                                </p>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  Pago inicial: $
                                  {numeroFormateado(
                                    curso.data_pago.costo_mensual / 2
                                  )}{" "}
                                  MXN
                                </p>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  Más dos mensualidades de: $
                                  {numeroFormateado(
                                    curso.data_pago.costo_mensual / 4
                                  )}{" "}
                                  MXN
                                </p>
                              </Fragment>
                            )}
                        {/* {curso.gratis && !curso.data_pago.costo_mensual && (
                          <p
                            style={{
                              fontWeight: "bold",
                              color: "#8A6AAC",
                              marginTop: "1px",
                            }}
                          >
                            Si eres estudiante de nuestras licenciaturas{" "}
                            {curso.gratis_costo
                              ? "$" + numeroFormateado(curso.gratis_costo) + " "
                              : "$ 000 "}
                            MXN
                          </p>
                        )} */}
                      </div>
                      <div className="col s12" style={{ textAlign: "center" }}>
                        <a
                          onClick={() => {
                            gtag_report_conversion(
                              "https://api.whatsapp.com/send?phone=3318958778&text=Hola me interesa " +
                                curso.nombre +
                                " ¿puedes ayudarme a inscribirme?"
                            );
                          }}
                          className="boton_curso"
                          target="_blank"
                          href={encodeURI(
                            "https://api.whatsapp.com/send?phone=3318958778&text=Hola me interesa " +
                              curso.nombre +
                              " ¿puedes ayudarme a inscribirme?"
                          )}
                        >
                          <p style={{ margin: "0px" }}>¡Quiero inscribirme!</p>
                        </a>
                        <p
                          style={{
                            color: "#A80B38",
                            fontWeight: "bold",
                            fontSize: "13px",
                          }}
                        >
                          Apertura sujeta a un quórum mínimo
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        background: "#00476F",
                        padding: "10px 15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          color: "#ffffff",
                          textAlign: "center",
                        }}
                      >
                        ¿Te interesa este curso para un equipo?
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <button
                          className="boton_curso"
                          onClick={() => {
                            setHablemos(true);
                          }}
                        >
                          <p style={{ margin: "0px", fontSize: "13px" }}>
                            Hablemos
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
              <div
                className={
                  "informacion_curso color_contenedor_defecto color_contenedor_" +
                  area
                }
                dangerouslySetInnerHTML={createMarkup(descripcionInterna)}
              />
              {curso.url_youtube && (
                <div className="contenedor_video">
                  <iframe
                    className="video"
                    src={curso.url_youtube}
                    title={curso.nombre}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
              )}
              <ul class="collapsible collapsible_curso">
                <li
                  className={"background_defecto background_" + area}
                  style={{ borderRadius: "20px" }}
                >
                  <div
                    class={
                      "arrow_curso collapsible-header collapsible-header_curso background_defecto background_" +
                      area
                    }
                  >
                    Temario
                  </div>
                  <div
                    class={
                      "collapsible-body collapsible-body_curso background_defecto background_" +
                      area
                    }
                    dangerouslySetInnerHTML={createMarkup(contenido)}
                  ></div>
                </li>
              </ul>
              <p
                className={"encabezadoPrecio color_defecto color_" + area}
                style={{ fontWeight: "bold" }}
              >
                Próximas fechas
              </p>
              {curso.estado_relampago !== undefined &&
                new Date(curso.estado_relampago.fecha_fin).getTime() >
                  new Date().getTime() && (
                  <div style={{ marginBottom: "25px", color: color }}>
                    <p
                      className={"titulo_2_nv color_defecto color_" + area}
                      style={{ margin: "0px", color: color }}
                    >
                      Oferta Relámpago:
                    </p>
                    <p style={{ fontWeight: "bold", margin: "0px" }}>
                      Del{" "}
                      {new Date(curso.fecha_relampago.fecha_inicio).getDate()}{" "}
                      de{" "}
                      {
                        meses[
                          new Date(
                            curso.fecha_relampago.fecha_inicio
                          ).getMonth()
                        ]
                      }{" "}
                      al {new Date(curso.fecha_relampago.fecha_fin).getDate()}{" "}
                      de{" "}
                      {
                        meses[
                          new Date(curso.fecha_relampago.fecha_fin).getMonth()
                        ]
                      }{" "}
                      de{" "}
                      {new Date(curso.fecha_relampago.fecha_fin).getFullYear()}
                    </p>
                    <p style={{ margin: "0px" }}>
                      <b>Días:</b> {curso.fecha_relampago.dias}
                    </p>
                    <p style={{ margin: "0px" }}>
                      <b>Horario:</b> {curso.fecha_relampago.horario} horas
                    </p>
                    <p style={{ margin: "0px" }}>
                      <b>Número de sesiones:</b>{" "}
                      {curso.fecha_relampago.sesiones}
                    </p>
                    <p style={{ margin: "0px" }}>
                      <b>Total de horas:</b> {curso.fecha_relampago.duracion}
                    </p>
                    <p style={{ margin: "0px" }}>
                      <b>Docente:</b>{" "}
                      {obtenerProfesor(curso.fecha_relampago.tutor).nombre}
                    </p>
                  </div>
                )}
              {fechas.map((fecha, index) => (
                <div style={{ marginBottom: "25px" }}>
                  <p
                    className={"titulo_2_nv color_defecto color_" + area}
                    style={{ margin: "0px" }}
                  >
                    Fecha {index + 1}:
                  </p>
                  <p style={{ fontWeight: "bold", margin: "0px" }}>
                    Del {new Date(curso.fechas[index].fecha_inicio).getDate()}{" "}
                    de{" "}
                    {
                      meses[
                        new Date(curso.fechas[index].fecha_inicio).getMonth()
                      ]
                    }{" "}
                    al {new Date(curso.fechas[index].fecha_fin).getDate()} de{" "}
                    {meses[new Date(curso.fechas[index].fecha_fin).getMonth()]}{" "}
                    de {new Date(curso.fechas[index].fecha_fin).getFullYear()}
                  </p>
                  <p style={{ margin: "0px" }}>
                    <b>Días:</b> {fecha.dias}
                  </p>
                  <p style={{ margin: "0px" }}>
                    <b>Horario:</b> {fecha.horario} horas
                  </p>
                  <p style={{ margin: "0px" }}>
                    <b>Número de sesiones:</b> {fecha.sesiones}
                  </p>
                  <p style={{ margin: "0px" }}>
                    <b>Total de horas:</b> {fecha.duracion}
                  </p>
                  <p style={{ margin: "0px" }}>
                    <b>Docente:</b> {obtenerProfesor(fecha.tutor).nombre}
                  </p>
                </div>
              ))}
              <div
                className={"inversiones background_defecto background_" + area}
              >
                <p
                  className="encabezadoPrecio"
                  style={{ fontWeight: "bold", marginBottom: "15px" }}
                >
                  Inversión
                </p>
                {curso.data_pago.costo_total &&
                  !curso.data_pago.costo_contado && (
                    <p
                      className="encabezadoPrecio"
                      style={{ fontWeight: "bold" }}
                    >
                      ${numeroFormateado(curso.data_pago.costo_total)} MXN de
                      contado
                    </p>
                  )}
                {curso.data_pago.costo_contado && (
                  <p
                    className="encabezadoPrecio"
                    style={{ fontWeight: "bold" }}
                  >
                    ${numeroFormateado(curso.data_pago.costo_contado)} MXN de
                    contado
                  </p>
                )}
                {curso.data_pago.costo_mensual && (
                  <Fragment>
                    <p>O tres pagos:</p>
                    <p>
                      Pago inicial $
                      {numeroFormateado(curso.data_pago.costo_mensual / 2)} MXN
                    </p>
                    <p>
                      Más dos mensualidades de: $
                      {numeroFormateado(curso.data_pago.costo_mensual / 4)} MXN
                    </p>
                  </Fragment>
                )}
                {curso.gratis && (
                  <p style={{ fontWeight: "bold", marginTop: "20px" }}>
                    Si eres estudiante de nuestras licenciaturas{" "}
                    {curso.gratis_costo
                      ? "$" + numeroFormateado(curso.gratis_costo) + " "
                      : "$ 000 "}
                    MXN
                  </p>
                )}
                <p style={{ fontStyle: "italic" }}>
                  Precios sujetos a cambio sin previo aviso.
                </p>
              </div>
              <img
                src={curso.imagen_indvidual}
                alt={curso.nombre}
                style={{ width: "100%", marginBottom: "35px" }}
              />
              {/* <div className="pagar_curso">
                <p
                  className="encabezadoPrecio"
                  style={{ fontWeight: "bold", marginBottom: "15px" }}
                >
                  Cómo pagar
                </p>
                <p>1. Solicita tu ficha de inscripción</p>
                <p>2. Haz tu pago:</p>
                <p style={{ fontWeight: "bold" }}>
                  En persona, en la Caja General de la Sede Gance:
                </p>
                <p style={{ marginLeft: "35px" }}>• En efectivo</p>
                <p style={{ marginLeft: "35px" }}>
                  • Con tarjeta de débito o crédito
                </p>
                <p style={{ marginLeft: "35px" }}>• Con cheque</p>
                <p style={{ fontWeight: "bold" }}>
                  En ventanilla de Banorte o Banamex.
                </p>
                <p style={{ fontWeight: "bold" }}>
                  O desde la app de tu banco, por transferencia bancaria.
                </p>
                <p>
                  3. Envía tu ficha de inscripción llena y tu comprobante de
                  pago aquí para apartar tu lugar.
                </p>
                <p>
                  4. Recibirás un correo de bienvenida para confirmar tu lugar.
                </p>
              </div> */}
              <div
                className={"inversiones background_defecto background_" + area}
              >
                <p
                  className="encabezadoPrecio"
                  style={{ fontWeight: "bold", marginBottom: "15px" }}
                >
                  Importante:
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Todo curso, taller o diplomado puede suspenderse o
                  postergarse, en caso de que no reúna el quórum mínimo
                  necesario y te devolveremos tu dinero o puedes usarlo para
                  tomarlo cuando éste se abra, o a cuenta de otro producto de
                  nuestro catálogo.
                  <br />
                  <br />
                  Por otro lado, si decides no asistir o te das de baja, no
                  recibirás devolución de tu pago.
                </p>
              </div>
            </div>

            {/* seccion de precios y datos del curso */}
            {window.screen.width > 980 && (
              <Fragment>
                <div className="col s12 m12 l4 xl4">
                  <div id="curso_fixme" className="curso_fijo">
                    <div className="tarjeta_fixme">
                      <div className="seccion_curso_fixme">
                        <p
                          className="titulo_4_nv"
                          style={{ fontWeight: "bold", margin: "0" }}
                        >
                          Inversión total
                        </p>
                        {curso.estado_relampago !== undefined ? (
                          new Date(curso.estado_relampago.fecha_fin).getTime() >
                          new Date().getTime() ? (
                            <Fragment>
                              <p
                                className={
                                  "encabezadoPrecio color_defecto color_" + area
                                }
                                style={{
                                  fontWeight: "bold",
                                  margin: "0px",
                                  color: color,
                                }}
                              >
                                $
                                {numeroFormateadoPunto(
                                  parseFloat(
                                    parseFloat(curso.data_pago.costo_total) *
                                      ((100 -
                                        parseInt(
                                          curso.estado_relampago.descuento
                                        )) /
                                        100)
                                  )
                                )}{" "}
                                MXN
                              </p>
                              <p style={{ margin: "0" }}>
                                {curso.estado_relampago.descuento}% de descuento{" "}
                                <span
                                  style={{ textDecoration: "line-through" }}
                                >
                                  $
                                  {numeroFormateado(
                                    curso.data_pago.costo_total
                                  )}{" "}
                                  MXN
                                </span>
                              </p>
                              <p style={{ color: color, margin: "0" }}>
                                <b>
                                  La oferta termina en{" "}
                                  <CountdownTimer
                                    fecha={curso.estado_relampago.fecha_fin}
                                  />
                                </b>{" "}
                              </p>
                              <p style={{ color: color, marginTop: "0" }}>
                                *Oferta válida de hoy{" "}
                                {obtenerRangoFechas(
                                  curso.estado_relampago.fecha_fin
                                )}
                                , en pago de contado.
                              </p>
                            </Fragment>
                          ) : (
                            <p
                              className={
                                "encabezadoPrecio color_defecto color_" + area
                              }
                              style={{
                                fontWeight: "bold",
                                marginBottom: "1px",
                                marginTop: "0",
                              }}
                            >
                              ${numeroFormateado(curso.data_pago.costo_total)}{" "}
                              MXN
                            </p>
                          )
                        ) : (
                          <p
                            className={
                              "encabezadoPrecio color_defecto color_" + area
                            }
                            style={{
                              fontWeight: "bold",
                              marginBottom: "1px",
                              marginTop: "0",
                            }}
                          >
                            ${numeroFormateado(curso.data_pago.costo_total)} MXN
                          </p>
                        )}
                        {curso.estado_relampago !== undefined
                          ? !new Date(
                              curso.estado_relampago.fecha_fin
                            ).getTime() > new Date().getTime() &&
                            curso.data_pago.costo_mensual && (
                              <Fragment>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  O tres pagos:
                                </p>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  Pago inicial: $
                                  {numeroFormateado(
                                    curso.data_pago.costo_mensual / 2
                                  )}{" "}
                                  MXN
                                </p>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  Más dos mensualidades de: $
                                  {numeroFormateado(
                                    curso.data_pago.costo_mensual / 4
                                  )}{" "}
                                  MXN
                                </p>
                              </Fragment>
                            )
                          : curso.data_pago.costo_mensual && (
                              <Fragment>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  O tres pagos:
                                </p>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  Pago inicial: $
                                  {numeroFormateado(
                                    curso.data_pago.costo_mensual / 2
                                  )}{" "}
                                  MXN
                                </p>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  Más dos mensualidades de: $
                                  {numeroFormateado(
                                    curso.data_pago.costo_mensual / 4
                                  )}{" "}
                                  MXN
                                </p>
                              </Fragment>
                            )}
                        {curso.gratis && (
                          <p
                            style={{
                              fontWeight: "bold",
                              color: "#8A6AAC",
                              marginTop: "1px",
                            }}
                          >
                            Si eres estudiante de nuestras licenciaturas{" "}
                            {curso.gratis_costo
                              ? "$" + numeroFormateado(curso.gratis_costo) + " "
                              : "$ 000 "}
                            MXN
                            <button
                              style={{
                                color: "#00B5C1",
                                border: "none",
                                background: "transparent",
                                fontWeight: "bold",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setCondiciones(true);
                              }}
                            >
                              Ver condiciones
                            </button>
                          </p>
                        )}
                      </div>
                      <div className="divider" />
                      <div className="seccion_curso_fixme">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            marginBottom: "15px",
                          }}
                        >
                          <div className="col s6">
                            <p
                              style={{
                                margin: "0px",
                                fontWeight: "bold",
                                color: "#464646",
                              }}
                            >
                              Formato
                            </p>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {curso.formato.map((formato_numero, index) => (
                                <div className="icono_texto">
                                  <img
                                    src={formato_img[formato_numero]}
                                    alt="formato del curso"
                                    style={{ height: "16px" }}
                                  />
                                  <p
                                    style={{
                                      margin: "0 5px 0 0",
                                      color: "#F49A3F",
                                    }}
                                  >
                                    {formato[formato_numero]}{" "}
                                    {index < curso.formato.length - 1 && ","}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="col s6">
                            <p
                              style={{
                                margin: "0px",
                                fontWeight: "bold",
                                color: "#464646",
                              }}
                            >
                              Modalidad
                            </p>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {curso.modalidad.map((modaidad_numero, index) => (
                                <div className="icono_texto">
                                  <img
                                    src={modalidad_img[modaidad_numero]}
                                    alt="modalidad del curso"
                                    style={{ height: "13px" }}
                                  />
                                  <p
                                    style={{
                                      margin: "0 5px 0 0",
                                      color: "#64C5DD",
                                    }}
                                  >
                                    {modailida[modaidad_numero]}{" "}
                                    {index < curso.modalidad.length - 1 && ","}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="col s12">
                            <p
                              style={{
                                margin: "0px",
                                fontWeight: "bold",
                                color: "#464646",
                              }}
                            >
                              Nivel
                            </p>
                            <div className="icono_texto">
                              <img
                                src={img_nivel}
                                alt="nivel del curso"
                                style={{ height: "15px" }}
                              />
                              <p style={{ margin: "0px", color: "#5ABF3F" }}>
                                {nivel[curso.nivel]}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            marginBottom: "15px",
                          }}
                        >
                          <div className="col s6">
                            <div className="icono_texto">
                              <img
                                src={img_requieres}
                                alt="formato del curso"
                              />
                              <p
                                style={{
                                  margin: "0px",
                                  fontWeight: "bold",
                                  color: color,
                                }}
                              >
                                Requieres
                              </p>
                            </div>
                            {curso.requisitos.split(",").map((requiere) => (
                              <p style={{ margin: "0px", color: "#464646" }}>
                                {requiere}
                              </p>
                            ))}
                          </div>
                          <div className="col s6">
                            <p
                              style={{
                                margin: "0px",
                                fontWeight: "bold",
                                color: "#464646",
                              }}
                            >
                              Días
                            </p>
                            {curso.dias.map((dia) => (
                              <a
                                target="_blank"
                                href={`/cursos-en-dias/${dia}`}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    className="etiqueta"
                                    style={{ background: "#F0B100" }}
                                  >
                                    {diasSemana[dia]}
                                  </p>
                                  <p
                                    className="etiqueta_fin"
                                    style={{ background: "#F0B100" }}
                                  >
                                    .
                                  </p>
                                </div>
                              </a>
                            ))}
                          </div>
                        </div>

                        <div>
                          <p
                            style={{
                              margin: "0px",
                              fontWeight: "bold",
                              color: "#464646",
                            }}
                          >
                            Relacionados con tu búsqueda:
                          </p>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "0 10px",
                            }}
                          >
                            {etiquetas.map((etiqueta) => (
                              <a
                                target="_blank"
                                href={`/cursos-relacion/${etiqueta._id}`}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <p className="etiqueta">{etiqueta.nombre}</p>
                                  <p className="etiqueta_fin">.</p>
                                </div>
                              </a>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="divider" />
                      <div
                        className="seccion_curso_fixme"
                        style={{ marginTop: "15px" }}
                      >
                        <a
                          onClick={() => {
                            gtag_report_conversion(
                              "https://api.whatsapp.com/send?phone=3318958778&text=Hola me interesa " +
                                curso.nombre +
                                " ¿puedes ayudarme a inscribirme?"
                            );
                          }}
                          className="boton_curso"
                          target="_blank"
                          href={encodeURI(
                            "https://api.whatsapp.com/send?phone=3318958778&text=Hola me interesa " +
                              curso.nombre +
                              " ¿puedes ayudarme a inscribirme?"
                          )}
                        >
                          <img src={img_whats} alt="WhatsApp" />
                          <p style={{ margin: "0px" }}>¡Quiero inscribirme!</p>
                        </a>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <img
                            src={img_warning}
                            alt="Apertura sujeta a un quórum mínimo"
                          />
                          <p
                            style={{
                              color: "#A80B38",
                              fontWeight: "bold",
                              fontSize: "13px",
                            }}
                          >
                            Apertura sujeta a un quórum mínimo
                          </p>
                        </div>
                        <div>
                          <p
                            style={{
                              fontWeight: "bold",
                              color: "#00476F",
                              textAlign: "center",
                              fontSize: "15px",
                              margin: "0",
                            }}
                          >
                            ¿Te interesa este curso para un equipo?
                          </p>
                          <div
                            id="seccion_curso_fixme_2"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            <button
                              className="boton_curso"
                              onClick={() => {
                                setHablemos(true);
                              }}
                            >
                              <p style={{ margin: "0px", fontSize: "15px" }}>
                                Hablemos
                              </p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* aqui empieza los datos del curso que te siguen */}
                <div
                  id="efecto_escritorio"
                  className="col s12 m12 l4 xl4 invisible_curso_etiqueta"
                >
                  <div id="curso_fixme" className="curso_fijo">
                    <div className="tarjeta_fixme">
                      <div className="seccion_curso_fixme">
                        <p
                          className="titulo_4_nv"
                          style={{ fontWeight: "bold", margin: "0" }}
                        >
                          Inversión total
                        </p>

                        {curso.estado_relampago !== undefined ? (
                          new Date(curso.estado_relampago.fecha_fin).getTime() >
                          new Date().getTime() ? (
                            <Fragment>
                              <p
                                className={
                                  "encabezadoPrecio color_defecto color_" + area
                                }
                                style={{
                                  fontWeight: "bold",
                                  margin: "0px",
                                  color: color,
                                }}
                              >
                                $
                                {numeroFormateadoPunto(
                                  parseFloat(
                                    parseFloat(curso.data_pago.costo_total) *
                                      ((100 -
                                        parseInt(
                                          curso.estado_relampago.descuento
                                        )) /
                                        100)
                                  )
                                )}{" "}
                                MXN
                              </p>
                              <p style={{ margin: "0" }}>
                                {curso.estado_relampago.descuento}% de descuento{" "}
                                <span
                                  style={{ textDecoration: "line-through" }}
                                >
                                  $
                                  {numeroFormateado(
                                    curso.data_pago.costo_total
                                  )}{" "}
                                  MXN
                                </span>
                              </p>
                              <p style={{ color: color, margin: "0" }}>
                                <b>
                                  La oferta termina en{" "}
                                  <CountdownTimer
                                    fecha={curso.estado_relampago.fecha_fin}
                                  />
                                </b>{" "}
                              </p>
                              <p style={{ color: color, marginTop: "0" }}>
                                *Oferta válida de hoy{" "}
                                {obtenerRangoFechas(
                                  curso.estado_relampago.fecha_fin
                                )}
                                , en pago de contado.
                              </p>
                            </Fragment>
                          ) : (
                            <p
                              className={
                                "encabezadoPrecio color_defecto color_" + area
                              }
                              style={{
                                fontWeight: "bold",
                                marginBottom: "1px",
                                marginTop: "0",
                              }}
                            >
                              ${numeroFormateado(curso.data_pago.costo_total)}{" "}
                              MXN
                            </p>
                          )
                        ) : (
                          <p
                            className={
                              "encabezadoPrecio color_defecto color_" + area
                            }
                            style={{
                              fontWeight: "bold",
                              marginBottom: "1px",
                              marginTop: "0",
                            }}
                          >
                            ${numeroFormateado(curso.data_pago.costo_total)} MXN
                          </p>
                        )}
                        {curso.estado_relampago !== undefined
                          ? !new Date(
                              curso.estado_relampago.fecha_fin
                            ).getTime() > new Date().getTime() &&
                            curso.data_pago.costo_mensual && (
                              <Fragment>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  O tres pagos:
                                </p>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  Pago inicial: $
                                  {numeroFormateado(
                                    curso.data_pago.costo_mensual / 2
                                  )}{" "}
                                  MXN
                                </p>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  Más dos mensualidades de: $
                                  {numeroFormateado(
                                    curso.data_pago.costo_mensual / 4
                                  )}{" "}
                                  MXN
                                </p>
                              </Fragment>
                            )
                          : curso.data_pago.costo_mensual && (
                              <Fragment>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  O tres pagos:
                                </p>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  Pago inicial: $
                                  {numeroFormateado(
                                    curso.data_pago.costo_mensual / 2
                                  )}{" "}
                                  MXN
                                </p>
                                <p style={{ margin: "0px", color: "#464646" }}>
                                  Más dos mensualidades de: $
                                  {numeroFormateado(
                                    curso.data_pago.costo_mensual / 4
                                  )}{" "}
                                  MXN
                                </p>
                              </Fragment>
                            )}
                        {curso.gratis && (
                          <p
                            style={{
                              fontWeight: "bold",
                              color: "#8A6AAC",
                              marginTop: "1px",
                            }}
                          >
                            Si eres estudiante de nuestras licenciaturas{" "}
                            {curso.gratis_costo
                              ? "$" + numeroFormateado(curso.gratis_costo) + " "
                              : "$ 000 "}
                            MXN
                            <button
                              style={{
                                color: "#00B5C1",
                                border: "none",
                                background: "transparent",
                                fontWeight: "bold",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setCondiciones(true);
                              }}
                            >
                              Ver condiciones
                            </button>
                          </p>
                        )}
                      </div>
                      <div className="divider" />
                      <div className="seccion_curso_fixme">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            margin: "15px 0",
                          }}
                        >
                          <div className="col s6">
                            <p
                              style={{
                                margin: "0px",
                                fontWeight: "bold",
                                color: "#464646",
                              }}
                            >
                              Formato
                            </p>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {curso.formato.map((formato_numero, index) => (
                                <div className="icono_texto">
                                  <img
                                    src={formato_img[formato_numero]}
                                    alt="formato del curso"
                                    style={{ height: "16px" }}
                                  />
                                  <p
                                    style={{
                                      margin: "0 5px 0 0",
                                      color: "#F49A3F",
                                    }}
                                  >
                                    {formato[formato_numero]}{" "}
                                    {index < curso.formato.length - 1 && ","}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="col s6">
                            <p
                              style={{
                                margin: "0px",
                                fontWeight: "bold",
                                color: "#464646",
                              }}
                            >
                              Modalidad
                            </p>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {curso.modalidad.map((modaidad_numero, index) => (
                                <div className="icono_texto">
                                  <img
                                    src={modalidad_img[modaidad_numero]}
                                    alt="modalidad del curso"
                                    style={{ height: "13px" }}
                                  />
                                  <p
                                    style={{
                                      margin: "0 5px 0 0",
                                      color: "#64C5DD",
                                    }}
                                  >
                                    {modailida[modaidad_numero]}{" "}
                                    {index < curso.modalidad.length - 1 && ","}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="col s12">
                            <p
                              style={{
                                margin: "0px",
                                fontWeight: "bold",
                                color: "#464646",
                              }}
                            >
                              Nivel
                            </p>
                            <div className="icono_texto">
                              <img
                                src={img_nivel}
                                alt="nivel del curso"
                                style={{ height: "15px" }}
                              />
                              <p style={{ margin: "0px", color: "#5ABF3F" }}>
                                {nivel[curso.nivel]}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            marginBottom: "15px",
                          }}
                        >
                          <div className="col s6">
                            <div className="icono_texto">
                              <img
                                src={img_requieres}
                                alt="formato del curso"
                              />
                              <p
                                style={{
                                  margin: "0px",
                                  fontWeight: "bold",
                                  color: color,
                                }}
                              >
                                Requieres
                              </p>
                            </div>
                            {curso.requisitos.split(",").map((requiere) => (
                              <p style={{ margin: "0px", color: "#464646" }}>
                                {requiere}
                              </p>
                            ))}
                          </div>
                          <div className="col s6">
                            <p
                              style={{
                                margin: "0px",
                                fontWeight: "bold",
                                color: "#464646",
                              }}
                            >
                              Días
                            </p>
                            {curso.dias.map((dia) => (
                              <a
                                target="_blank"
                                href={`/cursos-en-dias/${dia}`}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    className="etiqueta"
                                    style={{ background: "#F0B100" }}
                                  >
                                    {diasSemana[dia]}
                                  </p>
                                  <p
                                    className="etiqueta_fin"
                                    style={{ background: "#F0B100" }}
                                  >
                                    .
                                  </p>
                                </div>
                              </a>
                            ))}
                          </div>
                        </div>

                        <div>
                          <p
                            style={{
                              margin: "0px",
                              fontWeight: "bold",
                              color: "#464646",
                            }}
                          >
                            Relacionados con tu búsqueda:
                          </p>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "0 10px",
                            }}
                          >
                            {etiquetas.map((etiqueta) => (
                              <a
                                target="_blank"
                                href={`/cursos-relacion/${etiqueta._id}`}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <p className="etiqueta">{etiqueta.nombre}</p>
                                  <p className="etiqueta_fin">.</p>
                                </div>
                              </a>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="divider" />
                      <div
                        className="seccion_curso_fixme"
                        style={{ marginTop: "15px" }}
                      >
                        <a
                          onClick={() => {
                            gtag_report_conversion(
                              "https://api.whatsapp.com/send?phone=3318958778&text=Hola me interesa " +
                                curso.nombre +
                                " ¿puedes ayudarme a inscribirme?"
                            );
                          }}
                          className="boton_curso"
                          target="_blank"
                          href={encodeURI(
                            "https://api.whatsapp.com/send?phone=3318958778&text=Hola me interesa " +
                              curso.nombre +
                              " ¿puedes ayudarme a inscribirme?"
                          )}
                        >
                          <img src={img_whats} alt="WhatsApp" />
                          <p style={{ margin: "0px" }}>¡Quiero inscribirme!</p>
                        </a>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <img
                            src={img_warning}
                            alt="Apertura sujeta a un quórum mínimo"
                          />
                          <p
                            style={{
                              color: "#A80B38",
                              fontWeight: "bold",
                              fontSize: "13px",
                            }}
                          >
                            Apertura sujeta a un quórum mínimo
                          </p>
                        </div>
                        <div>
                          <p
                            style={{
                              fontWeight: "bold",
                              color: "#00476F",
                              textAlign: "center",
                              fontSize: "15px",
                              margin: "0",
                            }}
                          >
                            ¿Te interesa este curso para un equipo?
                          </p>
                          <div
                            id="seccion_curso_fixme_2"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            <button
                              className="boton_curso"
                              onClick={() => {
                                setHablemos(true);
                              }}
                            >
                              <p style={{ margin: "0px", fontSize: "15px" }}>
                                Hablemos
                              </p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Curso;
