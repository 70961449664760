import React from "react";
import Swal from "sweetalert2";
import Downloader from "js-file-downloader";

import { Carousel, ScrollingCarousel } from "@trendyol-js/react-carousel";

import icon64 from "../../../Images/Icon/64.svg";
import Arrow from "../../../Images/arrow";

import pdafReinscripcion from "../../../Docs/from_reinscripcion.pdf";

const download = () => {
  new Downloader({
    url: pdafReinscripcion,
  })
    .then(function () {
      // console.log("descargando");
    })
    .catch(function (error) {
      console.log(error);
      Swal.fire("Error", "Error al descargar archivo", "error");
    });
};

const CarruselPersona = () => {
  return window.innerWidth > 550 ? (
    <Carousel
      show={window.innerWidth > 550 ? window.innerWidth / 330 : 2.1}
      slide={1}
      swiping={false}
      infinite={false}
      rightArrow={
        <button className="btn-izquierda">
          <Arrow width={"30px"} />
        </button>
      }
      leftArrow={
        <button className="btn-derecha">
          <Arrow width={"30px"} rotate={"rotate(180deg)"} />
        </button>
      }
    >
      <div className="card-reinscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#F9027A" }}
          >
            1
            <br />
            Debes estar al corriente
          </p>
          <p>
            en el pago de tus colegiaturas, <br /> y si las tienes, <br /> pagar
            tus materias a repetir.
          </p>
        </div>
      </div>
      <div className="card-reinscripcion">
        <p
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", color: "#EFB100" }}
        >
          2 <br />
          Paga <br /> tu reinscripción
        </p>
        <a
          className="boton-rein-nv"
          style={{ background: "#EFB100" }}
          href="#banco"
        >
          Aquí los detalles
        </a>
      </div>
      <div className="card-reinscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#8A6AAC" }}
          >
            3 <br />
            Llena tu formato <br />
            de reinscripción en PDF.
          </p>
          <p>
            Pídelo en recepción <br /> o descárgalo aquí
          </p>
        </div>
        <button
          onClick={download}
          className="boton-rein-nv"
          style={{ background: "#8A6AAC" }}
        >
          Descargar
        </button>
      </div>
      <div className="card-reinscripcion">
        <p
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", color: "#BA2049" }}
        >
          4 <br />
          Envía tu formato
        </p>
        <a
          className="boton-rein-nv"
          style={{ background: "#BA2049" }}
          href="mailto:tesoreria@caav.mx"
          target="_blanck"
        >
          Aquí
        </a>
      </div>
      <div className="card-reinscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#B52C93" }}
          >
            5 <br />
            Realiza el pago
          </p>
          <p>en la Caja de Gance</p>
        </div>
      </div>
      <div className="card-reinscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#02B5C1" }}
          >
            6 <br />
            Recibe correo de confirmación
          </p>
          <p>
            En 48 horas <br />
            confirmaremos <br />
            tu reinscripción.
          </p>
        </div>
        <a
          className="boton-rein-nv"
          style={{ background: "#02B5C1" }}
          href="mailto:tesoreria@caav.mx"
          target="_blanck"
        >
          Si no lo recibes, escribe aquí
        </a>
      </div>
      <div className="card-reinscripcion" style={{ background: "#BA2049" }}>
        <img src={icon64} alt="CAAV" />
        <p
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", color: "#ffffff" }}
        >
          Sólo hasta <br />
          que tengas la <br />
          confirmación <br />
          estarás reinscrito <br />o reinscrita.
        </p>
      </div>
      <div style={{ width: "20%" }}></div>
    </Carousel>
  ) : (
    <ScrollingCarousel
      show={window.innerWidth > 550 ? 3.8 : 2.1}
      slide={1}
      swiping={true}
      infinite={false}
      rightArrow={<div></div>}
      leftArrow={<div></div>}
    >
      <div className="card-reinscripcion" style={{ marginLeft: "5%" }}>
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#F9027A" }}
          >
            1
            <br />
            Debes estar al corriente
          </p>
          <p>
            en el pago de tus colegiaturas, <br /> y si las tienes, <br /> pagar
            tus materias a repetir.
          </p>
        </div>
      </div>
      <div className="card-reinscripcion">
        <p
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", color: "#EFB100" }}
        >
          2 <br />
          Paga <br /> tu reinscripción
        </p>
        <a
          className="boton-rein-nv"
          style={{ background: "#EFB100" }}
          href="#banco"
        >
          Aquí los detalles
        </a>
      </div>
      <div className="card-reinscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#8A6AAC" }}
          >
            3 <br />
            Llena tu formato <br />
            de reinscripción en PDF.
          </p>
          <p>
            Pídelo en recepción <br />o descárgalo aquí
          </p>
        </div>
        <button className="boton-rein-nv" style={{ background: "#8A6AAC" }}>
          Descargar
        </button>
      </div>
      <div className="card-reinscripcion">
        <p
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", color: "#BA2049" }}
        >
          4 <br />
          Envía tu formato
        </p>
        <a
          className="boton-rein-nv"
          style={{ background: "#BA2049" }}
          href="mailto:tesoreria@caav.mx"
          target="_blanck"
        >
          Aquí
        </a>
      </div>
      <div className="card-reinscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#B52C93" }}
          >
            5 <br />
            Realiza el pago
          </p>
          <p>en la Caja de Gance</p>
        </div>
      </div>
      <div className="card-reinscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#02B5C1" }}
          >
            6 <br />
            Recibe correo de confirmación
          </p>
          <p>
            En 48 horas <br />
            confirmaremos <br />
            tu reinscripción.
          </p>
        </div>
        <a
          className="boton-rein-nv"
          style={{ background: "#02B5C1" }}
          href="mailto:tesoreria@caav.mx"
          target="_blanck"
        >
          Si no lo recibes, escribe aquí
        </a>
      </div>
      <div
        className="card-reinscripcion space-card"
        style={{ background: "#BA2049" }}
      >
        <img src={icon64} alt="CAAV" />
        <p
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", color: "#ffffff" }}
        >
          Sólo hasta <br />
          que tengas la <br />
          confirmación <br />
          estarás reinscrito <br />o reinscrita.
        </p>
      </div>
      <div style={{ width: "20%" }}></div>
    </ScrollingCarousel>
  );
};

export default CarruselPersona;
