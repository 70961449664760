import React from 'react';

const Taller = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={18} height="30.86" viewBox="0 0 18 30.86">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectángulo_363" data-name="Rectángulo 363" width="7.539" height="3.987" fill="#f49a3f" />
                </clipPath>
                <clipPath id="clip-path-2">
                    <rect id="Rectángulo_364" data-name="Rectángulo 364" width="7.539" height="1.474" fill="#f49a3f" />
                </clipPath>
                <clipPath id="clip-path-3">
                    <rect id="Rectángulo_365" data-name="Rectángulo 365" width={18} height="26.873" fill="#f49a3f" />
                </clipPath>
            </defs>
            <g id="Grupo_20" data-name="Grupo 20" transform="translate(0 0)">
                <path id="Trazado_44" data-name="Trazado 44" d="M41.986,125.78a.308.308,0,0,0,.3-.314v-.3a.318.318,0,0,0-.3-.324H37.255a.317.317,0,0,0-.3.324v.3a.307.307,0,0,0,.3.314Z" transform="translate(-30.627 -100.709)" fill="#f49a3f" />
                <path id="Trazado_45" data-name="Trazado 45" d="M41.986,126.771a.317.317,0,0,0,.3-.324v-.295a.312.312,0,0,0-.3-.324H37.255a.311.311,0,0,0-.3.324v.295a.316.316,0,0,0,.3.324Z" transform="translate(-30.627 -98.719)" fill="#f49a3f" />
                <g id="Grupo_12" data-name="Grupo 12" transform="translate(5.713 26.873)">
                    <g id="Grupo_14" data-name="Grupo 14" transform="translate(0 0)" clipPath="url(#clip-path)">
                        <path id="Trazado_38" data-name="Trazado 38" d="M41.986,127.762a.317.317,0,0,0,.3-.324v-.3a.309.309,0,0,0-.3-.314H37.255a.308.308,0,0,0-.3.314v.3a.316.316,0,0,0,.3.324Z" transform="translate(-36.115 -125.199)" fill="#f49a3f" />
                    </g>
                </g>
                <g id="Grupo_13" data-name="Grupo 13" transform="translate(5.713 29.386)">
                    <g id="Grupo_16" data-name="Grupo 16" transform="translate(0 0)" clipPath="url(#clip-path-2)">
                        <path id="Trazado_39" data-name="Trazado 39" d="M40.865,127.838a1.749,1.749,0,0,1-1.56.933,1.806,1.806,0,0,1-1.562-.933Z" transform="translate(-35.8 -127.307)" fill="#f49a3f" />
                    </g>
                </g>
                <g id="Grupo_14-2" data-name="Grupo 14" transform="translate(0 0)">
                    <g id="Grupo_18" data-name="Grupo 18" transform="translate(0 0)" clipPath="url(#clip-path-3)">
                        <path id="Trazado_40" data-name="Trazado 40" d="M43.139,112.335a9.062,9.062,0,0,0-8.994,9.123,9.538,9.538,0,0,0,1.194,4.561A24.224,24.224,0,0,1,38.5,134.5c.3,1.842.711,2.268,2.092,2.268h5.092c1.381,0,1.8-.425,2.092-2.268a24.224,24.224,0,0,1,3.162-8.484,9.538,9.538,0,0,0,1.194-4.561,9.062,9.062,0,0,0-8.994-9.123" transform="translate(-34.145 -112.319)" fill="#f49a3f" />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Taller;