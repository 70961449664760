import React from 'react';

const Presencial = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Grupo_10726" data-name="Grupo 10726" width="25px" height="27.63px" viewBox="0 0 10.862 12.008">
            <path id="Trazado_25" data-name="Trazado 25" d="M10.957,9.607H.095S.234,4.345,5.526,4.251c0,0,5.431,0,5.431,5.356" transform="translate(-0.095 2.401)" fill="#64c5dd" />
            <path id="Trazado_26" data-name="Trazado 26" d="M1.686,2.916A2.863,2.863,0,0,1,4.576.084a2.864,2.864,0,0,1,2.9,2.832,2.863,2.863,0,0,1-2.9,2.826,2.861,2.861,0,0,1-2.89-2.826" transform="translate(0.854 -0.084)" fill="#64c5dd" />
        </svg>
    );
};

export default Presencial;