import Swal from 'sweetalert2';
import ReactDOM from 'react-dom';
import Loader from '../Components/Loader/Loader';
import { Fragment } from 'react';

var url_json = require("../location.json");

if (window.location.origin.includes("localhost")) {
    url_json = url_json.local;
} else {
    url_json = url_json.production;
}

const Enterate = {
    obtenerNoticias: async () => {
        let url = url_json + "/noticias"
        let request = {
            method:'GET',
            headers: {
                "Content-type":"application/json; charset=UTF-8"
            }
        }

        let response = await fetch(url, request);
        let json = await response.json();
        return json;
    }
}

export default Enterate;