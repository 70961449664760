import React from 'react';

const Curso = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={27} height="18.83" viewBox="0 0 27 18.83" style={{marginBottom:"7px"}}>
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectángulo_361" data-name="Rectángulo 361" width={27} height="18.83" fill="#f49a3f" />
                </clipPath>
            </defs>
            <g id="Grupo_19" data-name="Grupo 19" transform="translate(0 0)">
                <g id="Grupo_10" data-name="Grupo 10" transform="translate(0 0)" clipPath="url(#clip-path)">
                    <path id="Trazado_32" data-name="Trazado 32" d="M35.5.009c-.024,0-.1.011-.169.018a4.546,4.546,0,0,0-1.372.382,14.623,14.623,0,0,1-4.839,1.168l-.285.007V16.668h.028c.05,0,.471-.051.749-.09A20.9,20.9,0,0,0,33.961,15.5a4.341,4.341,0,0,1,1.315-.331,10.167,10.167,0,0,1,5.2,1.4c.107.058.2.107.2.107s.008-3.4.008-7.543V1.582l-.172-.106A10.193,10.193,0,0,0,36.567.032C36.406.012,35.621,0,35.5.009m10.137,0-.181.018a10.284,10.284,0,0,0-4.12,1.51l-.058.036V9.122c0,4.152,0,7.547.01,7.544l.214-.115a10.465,10.465,0,0,1,4.838-1.395,3.912,3.912,0,0,1,1.6.318A20.49,20.49,0,0,0,51.814,16.5c.473.078,1.17.171,1.284.171h.032V1.584l-.3-.008A14.635,14.635,0,0,1,48.005.412a4.677,4.677,0,0,0-1.328-.38C46.542.012,45.759,0,45.637.009M28.184,2.5c-.052,0-.25.013-.442.018l-.347.01V18.811h.053c.08,0,.686-.07,1.037-.12a24.7,24.7,0,0,0,4.767-1.148,5.069,5.069,0,0,1,1.818-.369,12.49,12.49,0,0,1,5.507,1.482c.17.085.312.155.316.155s.185-.087.4-.193a11.318,11.318,0,0,1,6.011-1.4,5.118,5.118,0,0,1,1.241.325,24.669,24.669,0,0,0,4.717,1.14c.42.061.992.127,1.086.127H54.4V2.52h-.229c-.206,0-.317-.006-.664-.033l-.109-.008V17.169h-.047c-.185,0-1.186-.137-1.819-.25a21.9,21.9,0,0,1-3.609-.947,4.827,4.827,0,0,0-.8-.231,10.091,10.091,0,0,0-5.9,1.267l-.319.159-.351-.174A10.415,10.415,0,0,0,34.894,15.7a4.335,4.335,0,0,0-1.009.263,23.515,23.515,0,0,1-5.374,1.2l-.115.008V2.483l-.059,0c-.033,0-.1.006-.153.011" transform="translate(-27.395 0)" fill="#f49a3f" fillRule="evenodd" />
                </g>
            </g>
        </svg>
    );
};

export default Curso;