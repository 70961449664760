//carusel
import img1 from '../../../Images/escritorio/Index/carousel/1.png';
import img2 from '../../../Images/escritorio/Index/carousel/2.png';
import img3 from '../../../Images/escritorio/Index/carousel/3.png';
import img4 from '../../../Images/escritorio/Index/carousel/4.png';
import img5 from '../../../Images/escritorio/Index/carousel/5.png';
import img6 from '../../../Images/escritorio/Index/carousel/6.png';
import img7 from '../../../Images/escritorio/Index/carousel/7.png';
import img8 from '../../../Images/escritorio/Index/carousel/8.png';
import img9 from '../../../Images/escritorio/Index/carousel/9.jpg';
import img10 from '../../../Images/escritorio/Index/carousel/10.jpg';
import img11 from '../../../Images/escritorio/Index/carousel/11.JPG';
import img12 from '../../../Images/escritorio/Index/carousel/12.JPG';
import img13 from '../../../Images/escritorio/Index/carousel/13.JPG';
import img14 from '../../../Images/escritorio/Index/carousel/14.JPG';
import img15 from '../../../Images/escritorio/Index/carousel/15.jpg';
import img16 from '../../../Images/escritorio/Index/carousel/16.jpg';
import img17 from '../../../Images/escritorio/Index/carousel/17.jpg';
import img18 from '../../../Images/escritorio/Index/carousel/18.jpg';
import img19 from '../../../Images/escritorio/Index/carousel/19.jpg';
import img20 from '../../../Images/escritorio/Index/carousel/20.jpg';
import img21 from '../../../Images/escritorio/Index/carousel/21.jpg';

const imagenes = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21
];

export default imagenes;