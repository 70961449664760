import React from "react";

import { Carousel, ScrollingCarousel } from "@trendyol-js/react-carousel";

import icon64 from "../../../Images/Icon/64.svg";
import Arrow from "../../../Images/arrow";

const CarruselLinea = () => {
  return window.innerWidth > 550 ? (
    <Carousel
      show={window.innerWidth > 550 ? window.innerWidth / 330 : 2.1}
      slide={1}
      swiping={false}
      infinite={false}
      rightArrow={
        <button className="btn-izquierda">
          <Arrow width={"30px"} />
        </button>
      }
      leftArrow={
        <button className="btn-derecha">
          <Arrow width={"30px"} rotate={"rotate(180deg)"} />
        </button>
      }
    >
      <div className="card-reinscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#5BBF3F" }}
          >
            1
            <br />
            Debes estar al corriente
          </p>
          <p>
            en el pago de tus colegiaturas, <br /> y si las tienes, <br /> pagar
            tus materias a repetir.
          </p>
        </div>
      </div>
      <div className="card-reinscripcion">
        <p
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", color: "#B52C93" }}
        >
          2
          <br />
          Paga tu reinscripción en el banco
        </p>
        <a
          className="boton-rein-nv"
          style={{ background: "#B52C93" }}
          href="#banco"
        >
          Aquí los detalles
        </a>
      </div>
      <div className="card-reinscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#F49A3F" }}
          >
            3 <br />
            Llena tu formato
          </p>
          <p>
            <b>de reinscripción, en línea</b> <br />
            y adjunta tu comprobante <br />
            de pago en PDF.
          </p>
        </div>
        <a
          className="boton-rein-nv"
          style={{ background: "#F49A3F" }}
          href="#formulario"
        >
          Aquí
        </a>
      </div>
      <div className="card-reinscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#02B5C1" }}
          >
            4 <br />
            Recibe
            <br />
            correo de confirmación
          </p>
          <p>
            En 48 horas <br />
            confirmaremos <br />
            tu reinscripción.
          </p>
        </div>
        <a
          className="boton-rein-nv"
          style={{ background: "#02B5C1" }}
          href="mailto:tesoreria@caav.mx"
          target="_blanck"
        >
          Si no lo recibes, escribe aquí
        </a>
      </div>
      <div className="card-reinscripcion" style={{ background: "#BA2049" }}>
        <img src={icon64} alt="CAAV" />
        <p
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", color: "#ffffff" }}
        >
          Sólo hasta <br />
          que tengas la <br />
          confirmación <br />
          estarás reinscrito <br />o reinscrita.
        </p>
      </div>
      <div style={{ width: "20%" }}></div>
    </Carousel>
  ) : (
    <ScrollingCarousel infinite={false}>
      <div className="card-reinscripcion" style={{ marginLeft: "5%" }}>
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#5BBF3F" }}
          >
            1
            <br />
            Debes estar al corriente
          </p>
          <p>
            en el pago de tus colegiaturas, <br /> y si las tienes, <br /> pagar
            tus materias a repetir.
          </p>
        </div>
      </div>
      <div className="card-reinscripcion">
        <p
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", color: "#B52C93" }}
        >
          2
          <br />
          Paga tu reinscripción en el banco
        </p>
        <a
          className="boton-rein-nv"
          style={{ background: "#B52C93" }}
          href="#banco"
        >
          Aquí los detalles
        </a>
      </div>
      <div className="card-reinscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#F49A3F" }}
          >
            3 <br />
            Llena tu formato
          </p>
          <p>
            <b>de reinscripción, en línea</b> <br />
            y adjunta tu comprobante <br />
            de pago en PDF.
          </p>
        </div>
        <a
          className="boton-rein-nv"
          style={{ background: "#F49A3F" }}
          href="#formulario"
        >
          Aquí
        </a>
      </div>
      <div className="card-reinscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#02B5C1" }}
          >
            4 <br />
            Recibe
            <br />
            correo de confirmación
          </p>
          <p>
            En 48 horas <br />
            confirmaremos <br />
            tu reinscripción.
          </p>
        </div>
        <a
          className="boton-rein-nv"
          style={{ background: "#02B5C1" }}
          href="mailto:tesoreria@caav.mx"
          target="_blanck"
        >
          Si no lo recibes, escribe aquí
        </a>
      </div>
      <div
        className="card-reinscripcion space-card"
        style={{ background: "#BA2049" }}
      >
        <img src={icon64} alt="CAAV" />
        <p
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", color: "#ffffff" }}
        >
          Sólo hasta <br />
          que tengas la <br />
          confirmación <br />
          estarás reinscrito <br />o reinscrita.
        </p>
      </div>
      <div style={{ width: "20%" }}></div>
    </ScrollingCarousel>
  );
};

export default CarruselLinea;
