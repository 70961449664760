import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import BanersHelper from "../../Helpers/Baners";

import "./Baner.css";

const Baner = ({ color }) => {
  const [baners, setBaner] = useState([]);

  useEffect(() => {
    obtenerBanersOferta();
  }, []);

  const obtenerBanersOferta = async () => {
    let res = await BanersHelper.obtenerBanersRegulares();

    if (res.success) {
      setBaner(res.data);
    } else {
      Swal.fire(
        "Error!",
        "No se lograron obtener la configuracion de los baners, codigo: " +
          res.code,
        "error"
      );
    }
  };

  return (
    <div className="body__inner-wrapper">
      <div className="marquee" style={{ background: color }}>
        <div className="marquee__inner-wrap">
          <div className="marquee__inner" data-columns={baners.length}>
            {baners.map((noticia) => (
              <span className="banerSpam">{noticia.titulo}</span>
            ))}
            {baners.map((noticia) => (
              <span className="banerSpam" aria-hidden="true">
                {noticia.titulo}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Baner;
