import React, { Fragment, useEffect, useState } from "react";
import Downloader from "js-file-downloader";
import Swal from "sweetalert2";

import "./Becas.css";

import Loader from "../../Components/Loader/Loader";

import img1 from "../../Images/escritorio/Becas/1.png";
import movil1 from "../../Images/movil/Becas/1.png";

import icono4 from "../../Images/Icon/33.png";
import icono5 from "../../Images/Icon/34.png";
import icono6 from "../../Images/Icon/35.png";
import icono7 from "../../Images/Icon/36.png";

import beca from "../../Docs/beca.pdf";

import VideosHelper from "../../Helpers/Videos";

const Becas = () => {
  const convocatoria = false;
  const proximaConvocatoria = true;
  const [data, setData] = useState([]);
  const [dataFiltro, setDataFiltro] = useState([]);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    obtenerVideos();
  }, []);

  const download = () => {
    new Downloader({
      url: beca,
    })
      .then(function () {
        // console.log("descargando");
      })
      .catch(function (error) {
        Swal.fire("Error", "Error al descargar archivo", "error");
      });
  };

  const obtenerVideos = async () => {
    let res = await VideosHelper.ObtenerVideosCampo("2");

    if (res.success) {
      let filtro = [],
        resto = [];

      res.data.map((video) => {
        if (video.vista === "mejor_opcion") {
          resto.push(video);
        } else {
          filtro.push(video);
        }
      });

      setData(resto);
      setDataFiltro(filtro);
      setFlag(true);
    } else {
      Swal.fire(
        "Error!",
        "No se logro obtener los videos, codigo: " + res.code,
        "error"
      );
    }
  };

  return (
    <Fragment>
      {window.screen.width > 550 ? (
        <img src={img1} style={{ width: "100%" }} />
      ) : (
        <img src={movil1} style={{ width: "100%" }} />
      )}

      <p
        className="titulo_1_nv center"
        style={{ marginBottom: "20px", marginTop: "15px" }}
      >
        Becas
      </p>
      {/* <div className="container center">
        <p style={{ marginTop: "0px" }}>Existen dos formatos:</p>
        <p
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", marginBottom: "0px" }}
        >
          Becas por mérito,
        </p>
        <p style={{ margin: "0px" }}>
          de 10% a 100%, a partir del segundo cuatrimestre.
        </p>
        <p
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", marginBottom: "0px" }}
        >
          Gran Beca CAAV Anual
        </p>
        <p style={{ margin: "0 0 15px 0" }}>de 100% por toda tu licenciatura</p>
      </div> */}

      <div style={{ padding: "1px", backgroundColor: "rgb(244, 244, 244)" }}>
        <p
          className="titulo_1_nv center"
          style={{ marginBottom: "10px", marginTop: "5px" }}
        >
          Becas por mérito
        </p>

        <div className="container center">
          <p>
            El porcentaje de beca puede ser del 10% hasta el 100% en el costo de
            tu mensualidad, <br />
            según el reglamento de becas estatales de SEP (SICYT).
          </p>
          <p>
            Se otorgan a partir del segundo cuatrimestre. <br />
            Es decir, una vez que concluyas el primer cuatrimestre, podrás
            solicitarla si cumples con estos requisitos:
          </p>
          <div className="row">
            <div className="col s12 m6 l3">
              <p
                className="titulo_4_nv"
                style={{
                  fontFamily: "Montserrat-Bold",
                  marginBottom: "0px",
                  color: "#00496C",
                }}
              >
                Documentación
              </p>
              <p>
                Documentación de ingreso completa y entregada desde primer
                cuatrimestre (acta de nacimiento, certificado de bachillerato,
                CURP, fotografía tamaño infantil, constancia de validación de
                certificado de bachillerato)
              </p>
            </div>
            <div className="col s12 m6 l3">
              <p
                className="titulo_4_nv"
                style={{
                  fontFamily: "Montserrat-Bold",
                  marginBottom: "0px",
                  color: "#00496C",
                }}
              >
                Calificaciones
              </p>
              <p>
                Tener un promedio mínimo de 9. La escala de calificaciones de
                nuestro reglamento institucional es de 0 a 10, y la mínima
                aprobatoria es 7.
              </p>
            </div>
            <div className="col s12 m6 l3">
              <p
                className="titulo_4_nv"
                style={{
                  fontFamily: "Montserrat-Bold",
                  marginBottom: "0px",
                  color: "#00496C",
                }}
              >
                Materias
              </p>
              <p style={{ marginLeft: "5px" }}>
                Aprobar todas tus materias en <br />
                exámenes ordinarios y no tener <br />
                ninguna materia a repetir. <br />
              </p>
            </div>
            <div className="col s12 m6 l3">
              <p
                className="titulo_4_nv"
                style={{
                  fontFamily: "Montserrat-Bold",
                  marginBottom: "0px",
                  color: "#00496C",
                }}
              >
                Entrega de formatos
              </p>
              <p style={{ padding: "0px 4px", marginLeft: "3px" }}>
                Llenar los formatos para solicitud de beca y presentarlos en
                Control Escolar en las fechas indicadas.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="fondo_3">
        <div className="container center">
          <p style={{ fontWeight: "bold" }}>
            Esta información es analizada por un comité que asigna las becas a
            quienes demuestren necesitarla <br />
            por su situación económica y merecerla por su desempeño académico.
          </p>
        </div>
      </div>

      <div
        style={{ backgroundColor: "rgb(244, 244, 244)", paddingBottom: "50px" }}
      >
        <div className="container center">
          <p
            className="titulo_1_nv"
            style={{ margin: "0", paddingTop: "10px" }}
          >
            Gran Beca CAAV Anual
          </p>
          {proximaConvocatoria ? (
            <div>
              <p style={{ marginTop: "0px" }}>
                Esta beca se otorga sólo una vez cada año, a la ganadora o
                ganador de nuestro concurso de video. <br />
                Próxima convocatoria: marzo 2025
              </p>
            </div>
          ) : (
            <div>
              <p style={{ marginTop: "0px" }}>
                Esta beca se otorga sólo una vez cada año, a la ganadora o
                ganador de nuestro concurso de video. <br />
                Cada año se determina el tema del video, que debe tener estas
                características:
              </p>
              <div className="row" style={{ marginBottom: "0px" }}>
                <div className="col s12 m12 l6">
                  <img src={icono7} className="icon_becas_2"></img>
                  <p
                    className="titulo_4_nv"
                    style={{
                      fontFamily: "Montserrat-Bold",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    Creatividad
                  </p>
                  <p>
                    <span>
                      Debe ser creativo y original.
                      <br />
                    </span>
                    No importa si es tomado con celular, si es animado o en otro
                    formato: lo que más nos interesa es el contenido y tu
                    creatividad para resolverlo.
                  </p>
                </div>
                <div className="col s12 m12 l6">
                  <img src={icono4} className="icon_becas_2"></img>
                  <p
                    className="titulo_4_nv"
                    style={{
                      fontFamily: "Montserrat-Bold",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    Entrega
                  </p>
                  {convocatoria && (
                    <p>
                      <span>
                        Debes enviar tu video
                        <a
                          href="mailto:concursos@caav.mx"
                          target="blanck"
                          style={{ color: "#A80938" }}
                        >
                          <u> aquí</u>
                        </a>
                      </span>
                    </p>
                  )}
                  <p style={{ textWrap: "pretty" }}>
                    1.- Sube tu video en mp4 a Drive y envía el link con acceso
                    totalmente abierto. <br />
                    2.- Manda el link de Drive a{" "}
                    <a
                      href="mailto:concursos@caav.mx"
                      target="blanck"
                      style={{ color: "#A80938" }}
                    >
                      concursos@caav.mx
                    </a>{" "}
                    con estos datos.
                    <br />
                    3.- Recibirás un correo donde confirmarán tu participación
                    en el concurso por la beca.
                  </p>
                </div>
                <div className="col s12 m12 l6">
                  <img src={icono5} className="icon_becas_2"></img>
                  <p
                    className="titulo_4_nv"
                    style={{
                      fontFamily: "Montserrat-Bold",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    Duración
                  </p>
                  <p>
                    <span>
                      Debe durar máximo 59 segundos.
                      <br />
                    </span>
                    Si pasa de esta duración, será automáticamente
                    descalificado.
                  </p>
                </div>
                <div className="col s12 m12 l6">
                  <img src={icono6} className="icon_becas_2"></img>
                  <p
                    className="titulo_4_nv"
                    style={{
                      fontFamily: "Montserrat-Bold",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    Temática
                  </p>
                  <p style={{ textWrap: "pretty" }}>
                    El tema de 2024 es ¿Por qué es importante tener: cineastas
                    animadores, especialistas en marketing o expertos en arte
                    digital y videojuegos? (Elige sólo uno de los cuatro
                    perfiles, según la licenciatura que quieras estudiar)
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button onClick={download} className="boton_licenciatura_2">
                  Descarga la convocatoria aquí
                </button>
              </div>
            </div>
          )}
        </div>

        {/* FIXME: este comentario se activa y desctiva */}
        {convocatoria && (
          <Fragment>
            <div className="fondo_3">
              <div className="container center">
                <p style={{ fontWeight: "bold" }}>
                  ¡Participa! La fecha límite para mandar tu video es el lunes
                  10 de abril de 2023, a las 23:59, hora del centro de México.
                </p>
              </div>
            </div>
            <div className="container center">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "25px 0",
                }}
              >
                <a href={beca} target="blanck">
                  <div
                    className="boton_1_nv"
                    style={{ width: "250px", margin: "0px" }}
                  >
                    <p>Conoce las bases del 2023</p>
                  </div>
                </a>
              </div>
            </div>
          </Fragment>
        )}
      </div>

      <div className="container contenedor_chico center">
        <p className="encabezadosCaav" style={{ marginTop: "10px" }}>
          <span className="encabezadosMonserrat">Conoce al ganador de la</span>
          <br />
          Gran Beca CAAV Anual
        </p>
        <p>
          Tú también podrías ganar la beca,
          <br />
          si haces un video muy original y creativo.
        </p>
        <p>Inspírate con estos ejemplos, sin tratar de imitarlos.</p>
      </div>

      <div
        className="becas"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!flag ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
              width: "100%",
            }}
          >
            <Loader />
          </div>
        ) : (
          <iframe
            src={data[0].video}
            title="YouTube video beca caav player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )}
      </div>

      <div className="container">
        <p className="titulo_1_nv center" style={{ marginTop: "10px" }}>
          Más ganadores de la Gran Beca CAAV Anual
        </p>
      </div>
      <div className="flex_padre_7 becas_2">
        {!flag ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
              width: "100%",
            }}
          >
            <Loader />
          </div>
        ) : (
          dataFiltro.map((video) => (
            <div className="flex_2">
              <iframe
                src={video.video}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <p>{video.titulo}</p>
            </div>
          ))
        )}
      </div>
    </Fragment>
  );
};

export default Becas;
