import React from "react";

import { ScrollingCarousel } from "@trendyol-js/react-carousel";

const CarruselPagos = () => {
  return window.innerWidth > 550 ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "15px",
      }}
    >
      <div className="card-pago margin-left-ins">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#DE2657" }}
          >
            En la Caja General,
            <br />
            de la sede Gance
          </p>
          <p>
            <b>
              En efectivo, con cheque
              <br />o con tarjeta de crédito o débito.
            </b>
            <br />
            <br />
            Abierta de lunes a viernes,
            <br className="invisible_escritorio" />
            de 10 am a 6 pm.
            <br />
            <br />Y sábados de 10 am a 2 pm.
            <br />
            <br />
            Está en la Sede Gance
            <br />
            <a
              href="!#"
              style={{ textDecoration: "underline", color: "#6E6E73" }}
            >
              Lerdo de Tejada 2091,
            </a>
            <br className="invisible_escritorio" />
            esquina Marsella.
          </p>
        </div>
      </div>
      <div className="card-pago">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#DE2657" }}
          >
            Banorte
          </p>
          <p>
            <b>
              Depósito en efectivo
              <br className="invisible_escritorio" />o con cheque.
            </b>
            <br />
            <br />
            <b>Número de empresa:</b>
            <br />
            CEP 62841 (CAAV)
            <br />
            <br />
            <b>Da al cajero:</b>
            <br />
            primer nombre y primer
            <br className="invisible_escritorio" />
            apellido del aspirante,
            <br />
            seguidos de las letras AB.
          </p>
        </div>
      </div>
      <div className="card-pago">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#DE2657" }}
          >
            Multiva
          </p>
          <p>
            <b>Sólo transferencia.</b>
            <br />
            <br />
            <b>Beneficiario:</b>
            <br />
            Centro de Formación y
            <br />
            Capacitación Audiovisual, S.C.
            <br />
            <br />
            <b>RFC.</b> CFC950627R85
            <br />
            <br />
            <b>Email:</b> tesoreria@caav.mx
            <br />
            <br />
            <b>Concepto:</b> Nombre completo
            <br />
            del estudiante de quien realizas el pago.
            <br />
            <br />
            <b>Clabe:</b> 132180000090096391
          </p>
        </div>
      </div>
    </div>
  ) : (
    <ScrollingCarousel rightArrow={<div></div>} leftArrow={<div></div>}>
      <div className="card-pago margin-left-ins">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#DE2657" }}
          >
            En la Caja General,
            <br />
            de la sede Gance
          </p>
          <p>
            <b>
              En efectivo, con cheque
              <br />o con tarjeta de crédito o débito.
            </b>
            <br />
            <br />
            Abierta de lunes a viernes,
            <br className="invisible_escritorio" />
            de 10 am a 6 pm.
            <br />
            <br />Y sábados de 10 am a 2 pm.
            <br />
            <br />
            Está en la Sede Gance
            <br />
            <a
              href="!#"
              style={{ textDecoration: "underline", color: "#6E6E73" }}
            >
              Lerdo de Tejada 2091,
            </a>
            <br className="invisible_escritorio" />
            esquina Marsella.
          </p>
        </div>
      </div>
      <div className="card-pago">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#DE2657" }}
          >
            Banorte
          </p>
          <p>
            <b>
              Depósito en efectivo
              <br className="invisible_escritorio" />o con cheque.
            </b>
            <br />
            <br />
            <b>Número de empresa:</b>
            <br />
            CEP 62841 (CAAV)
            <br />
            <br />
            <b>Da al cajero:</b>
            <br />
            primer nombre y primer
            <br className="invisible_escritorio" />
            apellido del aspirante,
            <br />
            seguidos de las letras AB.
          </p>
        </div>
      </div>
      <div className="card-pago">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#DE2657" }}
          >
            Multiva
          </p>
          <p>
            <b>Sólo transferencia.</b>
            <br />
            <br />
            <b>Beneficiario:</b>
            <br />
            Centro de Formación y
            <br className="invisible_escritorio" />
            Capacitación Audiovisual, S.C.
            <br />
            <br />
            <b>RFC.</b> CFC950627R85
            <br />
            <br />
            <b>Email:</b> tesoreria@caav.mx
            <br />
            <br />
            <b>Concepto:</b> Nombre completo
            <br />
            del estudiante de quien realizas el pago.
            <br />
            <br />
            <b>Clabe:</b> 132180000090096391
          </p>
        </div>
      </div>
    </ScrollingCarousel>
  );
};

export default CarruselPagos;
