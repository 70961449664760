import Swal from "sweetalert2";

var url_json = require("../location.json");

if (window.location.origin.includes("localhost")) {
  url_json = url_json.local;
} else {
  url_json = url_json.production;
}

const Profesores = {
  agregarBlogs: async (data) => {
    let url = url_json + "/blogs";
    let body = {
      contenido: data.contenido,
      imagen: data.imagen,
      imagenExterna: data.imagenExterna,
      licenciatura: data.licenciatura,
      titulo: data.titulo,
      etiquetas: data.etiquetas,
    };

    let request = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let res = await response.json();

    console.log(res);
    if (res.success) {
      Swal.fire(
        "Inserción exitosa!",
        "El blog fue agregado correctamente",
        "success"
      ).then(() => {
        window.location.reload();
      });
    } else {
      Swal.fire(
        "Error!",
        "No se pudo guardar el blog, codigo: " + res.code,
        "error"
      ).then(() => {
        window.location.reload();
      });
    }
    return res;
  },
  obtenerBlogs: async () => {
    let url = url_json + "/blogs";
    let request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
  obtenerUnBlog: async (id) => {
    let url = url_json + "/blog/" + id;
    let request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
  editarBlog: async (data, id) => {
    Swal.fire({
      icon: "question",
      title: "¿Esta seguro que desea modificar el blog?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let url = url_json + "/actualizar-blog/" + id;

        let body = {
          contenido: data.contenido,
          imagen: data.imagen,
          imagenExterna: data.imagenExterna,
          licenciatura: data.licenciatura,
          titulo: data.titulo,
          etiquetas: data.etiquetas,
        };
        let request = {
          method: "PUT",
          body: JSON.stringify(body),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };

        let response = await fetch(url, request);
        let res = await response.json();
        if (res.success) {
          Swal.fire(
            "Modificación exitosa!",
            "Blog modificado correctamente",
            "success"
          ).then(() => {
            window.location.reload();
          });
        } else {
          Swal.fire("Error!", "No se pudo modificar el blog", "error").then(
            () => {
              window.location.reload();
            }
          );
        }
        return res;
      } else if (result.isDenied) {
        Swal.fire("Modificación cancelada", "El campo quedo intacto", "succes");
      }
    });
  },
  eliminarBlog: async (id) => {
    Swal.fire({
      icon: "question",
      title: "¿Esta seguro que desea eliminar el blog?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let url = url_json + "/eliminar-blog/" + id;
        let request = {
          method: "DELETE",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };

        let response = await fetch(url, request);
        let res = await response.json();
        if (res.success) {
          Swal.fire(
            "Eliminación exitosa!",
            "Blog eliminado correctamente",
            "success"
          ).then(() => {
            window.location.reload();
          });
        } else {
          Swal.fire("Error!", "No se pudo eliminar el blog", "error").then(
            () => {
              window.location.reload();
            }
          );
        }
        return res;
      } else if (result.isDenied) {
        Swal.fire("Eliminación cancelada", "El campo quedo intacto", "succes");
      }
    });
  },
  obtenerBlogTitulo: async (titulo) => {
    let url = url_json + "/blog-titulo";
    let body = {
      titulo,
    };

    let request = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let res = await response.json();
    return res;
  },
  obtenerBlogUrl: async (url) => {
    let url_c = url_json + "/blog-url";
    let body = {
      url,
    };

    let request = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url_c, request);
    let res = await response.json();
    return res;
  },
  obtenerBlogsAutor: async (autor) => {
    let url_c = url_json + "/blogs-autor";
    let body = {
      autor,
    };

    let request = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url_c, request);
    let res = await response.json();
    return res;
  },
  obtenerBlogsRecientes: async () => {
    let url = url_json + "/blogs-recientes";
    let request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
  obtenerBlogsRelacionado: async () => {
    let url = url_json + "/blogs-aleatorios";
    let request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
};

export default Profesores;
