import React, { useEffect, useState, Fragment } from "react";
import M from "materialize-css";
import Loader from "../../Components/Loader/Loader";

import "./NuevaSede.css";

import img1 from "../../Images/escritorio/NuevaSede/1.png";
import movil1 from "../../Images/movil/NuevaSede/1.png";

import FotosHelper from "../../Helpers/Fotos";
import Swal from "sweetalert2";

const NuevaSede = () => {
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    var elems2 = document.querySelectorAll(".collapsible");
    M.Collapsible.init(elems2);

    obtenerFotos();
  }, []);

  const obtenerFotos = async () => {
    let res = await FotosHelper.ObtenerFotosCampo("1");

    if (res.success) {
      setData(res.data);
      setFlag(true);

      setTimeout(() => {
        console.log("cargar");
        var elems = document.querySelectorAll(".materialboxed");
        M.Materialbox.init(elems);
      }, 1000);
    } else {
      Swal.fire(
        "Error!",
        "No se pudo obtener las imagenes, codigo: " + res.code,
        "error"
      );
    }
  };

  return (
    <Fragment>
      {window.screen.width > 550 ? (
        <img src={img1} style={{ width: "100%" }} />
      ) : (
        <img src={movil1} style={{ width: "100%" }} />
      )}

      <p
        className="titulo_1_nv"
        style={{ textAlign: "center", margin: "15px 0 0 0" }}
      >
        Nueva sede
      </p>
      <div className="container contenedor_chico contenedor_chico_mueve center">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontWeight: "bold", marginBottom: "0px" }}
          >
            ¿Dónde estará?
          </p>
          <p>
            La ubicación del nuevo campus aún no la revelaremos, <br />
            pero puedes tener la tranquilidad de que está en la misma <br />
            zona de nuestras sedes actuales, para no cambiar tus rutas.
          </p>
        </div>
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontWeight: "bold", marginBottom: "0px" }}
          >
            ¿Cuándo estará lista?
          </p>
          <p>Todo indica que para 2024.</p>
        </div>
      </div>

      <div className="container center">
        <p className="titulo_1_nv">¿Qué está pasando?</p>
        <div className="contenedor_media">
          <iframe
            className="media_contenido"
            src="https://www.youtube.com/embed/yoqaOaeOU0A"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <p
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", marginBottom: "0px" }}
        >
          La trama
        </p>
        <p>
          En esta época desconcertante, hemos crecido. <br />
          Por eso estamos construyendo un campus nuevo, espacioso, equipado y
          moderno. <br />
          Cerraremos la sede Greenaway, y las demás seguirán en operación.
        </p>
        <p className="titulo_4_nv" style={{ fontFamily: "Montserrat-Bold" }}>
          El plan de rodaje
        </p>
        <p
          className="titulo_4_nv"
          style={{ margin: "10px 0", fontWeight: "bold", marginBottom: "0px" }}
        >
          Construir nueva locación
        </p>
        <p>
          En unos meses habremos concluido la <br />
          construcción del nuevo campus.
          <br />
          Pronto daremos a conocer su ubicación, <br />
          muy cerca del anterior,
          <br />
          en la misma zona de la ciudad.
        </p>
        <p
          className="titulo_4_nv"
          style={{ margin: "10px 0", fontWeight: "bold", marginBottom: "0px" }}
        >
          Seguir con nuestra vida académica
        </p>
        <p>
          Con excepción de Greenaway, nuestras <br />
          sedes seguirán en funciones mientras <br />
          terminamos la construcción.
        </p>
        <p
          className="titulo_4_nv"
          style={{ margin: "10px 0 50px", fontWeight: "bold" }}
        >
          Seguir creando, creyendo y creciendo
        </p>
      </div>

      <p className="titulo_1_nv" style={{ textAlign: "center" }}>
        Carpeta de arte
      </p>

      <div className="contenedor_mueve_galeria container">
        {!flag ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Loader />
          </div>
        ) : (
          <div className="flex_mueve">
            {data.map((imagen, index) => (
              <div key={index} className="hijo_1_mueve">
                <img
                  src={imagen.imagen}
                  className="imagenes_mueve materialboxed"
                ></img>
              </div>
            ))}
          </div>
        )}
      </div>

      <div
        className="fondo_mueve_preguntas"
        style={{ padding: "20px 0% 40px" }}
      >
        <div className="container">
          <ul class="collapsible" style={{ boxShadow: "none", border: "none" }}>
            <li>
              <div
                class="collapsible-header collapsible-header_propio"
                style={{
                  justifyContent: "center",
                  background: "transparent",
                  border: "none",
                }}
              >
                <i
                  class="material-icons iconDown"
                  style={{ marginTop: "10px" }}
                >
                  arrow_drop_down
                </i>
                <i class="material-icons iconUp" style={{ marginTop: "10px" }}>
                  arrow_drop_up
                </i>
                <p className="titulo_1_nv" style={{ textAlign: "center" }}>
                  Preguntas frecuentes
                </p>
              </div>
              <div class="collapsible-body">
                <p className="texto_pregunta">
                  ¿Por qué el CAAV se mueve? <br />
                </p>
                <p className="texto_respuesta">
                  Porque la historia del CAAV es una historia de cambio y
                  evolución. Comenzamos hace ya 26 años en nuestra sede Griffith
                  y fuimos creciendo hasta tener cuatro sedes. Pero desde hace
                  ya varios años, hemos tenido el sueño de contar con un campus
                  que podamos diseñar y construir desde cero.
                  <br />
                  <br />
                </p>

                <p className="texto_pregunta">
                  ¿Se elevará el costo de mensualidades o inscripción ahora que
                  tendrá nueva sede CAAV?
                  <br />
                </p>
                <p className="texto_respuesta">
                  No. Nuestros precios seguirán siendo igual de accesibles. Como
                  habrás notado, todas nuestras cuotas han sido reducidas
                  temporalmente, como apoyo ante la pandemia de COVID. En cuanto
                  sea posible, las cuotas volverán a la normalidad y sufrirán
                  sólo los ajustes estándar, que se hacen anualmente. Pero estar
                  en un campus más moderno no implicará costos altos para ti.
                  <br />
                  <br />
                </p>

                <p className="texto_pregunta">
                  ¿Cuándo estará lista la nueva sede?
                  <br />
                </p>
                <p className="texto_respuesta">
                  Todo indica que en 2024.
                  <br />
                  <br />
                </p>

                <p className="texto_pregunta">
                  ¿Afectará el cambio de sede al cuatrimestre o curso que ya
                  estoy realizando?
                  <br />
                </p>
                <p className="texto_respuesta">
                  No, el cambio de sede se planeará sin que afecte los estudios
                  en curso.
                  <br />
                  <br />
                </p>

                <p className="texto_pregunta">
                  ¿En dónde estará la nueva sede?
                  <br />
                </p>
                <p className="texto_respuesta">
                  De momento es una sorpresa la ubicación exacta. Pero ten la
                  tranquilidad de que estará en la misma zona de la sede
                  Greenaway, Griffith y Gance, muy cerca.
                  <br />
                  <br />
                </p>

                <p className="texto_pregunta">
                  ¿Qué pasará con las actuales sedes del CAAV?
                  <br />
                </p>
                <p className="texto_respuesta">
                  Por el momento solo vamos a cerrar nuestra sede Greenaway. Las
                  otras tres, permanecerán abiertas hasta que contemos con el
                  espacio suficiente en nuestra nueva sede.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      {/* <div className='fondo_mueve'>
                <p className='titulo_caav_mueve_1' style={{ textAlign:"center", color: '#00496C', margin: '20px'}}>
                    Platiquemos
                </p>
                <p style={{ textAlign:"center", color: '#00496C', margin: '0', lineHeight: '15px' }}>
                    Si tienes alguna duda o inquietud sobre este tema, escríbenos para resolverlas.
                </p>
                <div style={{display:"flex", justifyContent:"center", flexWrap: "wrap"}}>
                    <a href='!#' type="">                          
                        <button className='boton_platicar'>
                            <div style={{marginTop: '15px', marginLeft: '-160px'}}>
                                <i class="fab fa-whatsapp"></i>
                            </div>
                            <div style={{marginTop: '-32px', marginLeft: '15px'}}>
                                <p>Hablar con un agente</p>
                            </div>
                        </button>
                    </a>
                </div>
            </div> */}
    </Fragment>
  );
};

export default NuevaSede;
