import Swal from "sweetalert2";

var url_json = require("../location.json");

if (window.location.origin.includes("localhost")) {
  url_json = url_json.local;
} else {
  url_json = url_json.production;
}

const Autores = {
  agregarAutor: async (data) => {
    let url = url_json + "/agregar-autor-blog";
    let body = {
      nombre: data.nombre,
      certificado: data.certificado,
      imagen: data.imagen,
    };

    let request = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let res = await response.json();

    if (res.success) {
      Swal.fire(
        "Inserción exitosa!",
        "Profesor agregado correctamente",
        "success"
      ).then(() => {
        window.location.reload();
      });
    } else {
      Swal.fire(
        "Error!",
        "No se pudo guardar el profesor, codigo: " + res.code,
        "error"
      ).then(() => {
        window.location.reload();
      });
    }
    return res;
  },
  obtnenreAutores: async () => {
    let url = url_json + "/autores-blogs";
    let request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
  obtenerUnAutor: async (id) => {
    let url = url_json + "/autor-blog/" + id;
    let request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let json = await response.json();
    return json;
  },
  editarAutor: async (data, id) => {
    let url = url_json + "/actualizar-autor-blog/" + id;

    let body = {
      nombre: data.nombre,
      certificado: data.certificado,
      imagen: data.imagen,
    };

    let request = {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let res = await response.json();
    if (res.success) {
      Swal.fire(
        "Modificación exitosa!",
        "Profesor modificado correctamente",
        "success"
      ).then(() => {
        window.location.reload();
      });
    } else {
      Swal.fire("Error!", "No se pudo modificar el profesor", "error").then(
        () => {
          window.location.reload();
        }
      );
    }
    return res;
  },
  eliminarAutor: async (id) => {
    let url = url_json + "/eliminar-autor-blog/" + id;
    let request = {
      method: "DELETE",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    let response = await fetch(url, request);
    let res = await response.json();
    if (res.success) {
      Swal.fire(
        "Eliminación exitosa!",
        "Profesor eliminado correctamente",
        "success"
      ).then(() => {
        window.location.reload();
      });
    } else {
      Swal.fire("Error!", "No se pudo eliminar el profesor", "error").then(
        () => {
          window.location.reload();
        }
      );
    }
    return res;
  },
};

export default Autores;
