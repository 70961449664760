import React, { Fragment } from 'react';
import Whatsapp from './Whatsapp/Whatsapp.js';

import logo from '../Images/logo.png';

import face from '../Images/Icon/40.svg';
import insta from '../Images/Icon/41.svg';
import twit from '../Images/Icon/45.svg';
import you from '../Images/Icon/48.svg';
// import whats from '../Images/Icon/47.svg';
import tiktok from '../Images/Icon/62.png';


const Redes = () => {

    return(
        <Fragment>
            {!window.location.pathname.includes("curso") && (
                <Fragment>
                    <div className='invisible_movil_redes'
                        style={{
                            position:"fixed", 
                            top:"202px", 
                            right:"0px",
                            display:"flex", 
                            justifyContent:"center", 
                            alignItems:"center",
                            flexDirection:"column",
                            margin:"0px",
                            zIndex:"999"
                        }}
                    >
                        <div style={{background:"#fff"}}>
                            <a href="/">
                                <img src={logo} alt="CAAV Universidad de Medios Audiovisuales" style={{width:"170px", margin:"10px 30px 0px 30px"}} />
                            </a>
                        </div>
                        <div style={{
                                background:"#00496C", 
                                display:"flex", 
                                justifyContent:"space-around", 
                                alignItems:"center",
                                width:"100%",
                                padding:"0px 20px"
                        }}>
                            <div style={{marginTop:"9px"}}>
                                <a target='blanck' href="https://www.facebook.com/caavuniversidad">
                                    <img src={face} alt="Pagina Facebook CAAV" style={{width:"25px"}} />
                                </a>
                            </div>
                            <div>
                                <a target='blanck' href="https://www.instagram.com/caavuniversidad/">
                                    <img src={insta} alt="Perfil Instagram CAAV" style={{width:"25px", marginTop:"10px"}} />
                                </a>
                            </div>
                            <div>
                                <a target='blanck' href="https://twitter.com/caavuniversidad">
                                    <img src={twit} alt="Perfil Twitter CAAV" style={{width:"25px", marginTop:"10px"}} />
                                </a>
                            </div>
                            <div>
                                <a target='blanck' href="https://www.youtube.com/c/CaavMxUniversidad">
                                    <img src={you} alt="Canal YouTube CAAV" style={{width:"25px", marginTop:"10px"}} />
                                </a>
                            </div>
                            <div>
                                <a 
                                    target='blanck' href='https://www.tiktok.com/@caavuniversidad?_t=8XPN7jDvG8k&_r=1'
                                    rel="noopener noreferrer"
                                    style={{cursor:"pointer"}}
                                >
                                    <img src={tiktok} alt="Canal tiktok CAAV" style={{width:"25px", marginTop:"10px"}} />
                                </a>
                            </div>
                        </div>
                        {/* <div style={{background:"#a80938", color:"#fff", width:"100%", textAlign:"center"}}>
                            <p style={{margin:"0"}}>Próximo inicio: 8 de mayo</p>
                        </div> */}
                    </div>
                    <div className='invisible_escritorio_redes'
                        style={{
                            position:"fixed", 
                            top:"122px", 
                            right:"0px",
                            background:"#A80938", 
                            display:"flex", 
                            justifyContent:"center", 
                            alignItems:"center", 
                            flexDirection:"column",
                            padding:"10px",
                            margin:"0px",
                            zIndex:"9999"
                        }}
                    >
                        <div>
                            <a target='blanck' href="https://www.facebook.com/caavuniversidad">
                                <img src={face} alt="Pagina Facebook" style={{width:"25px"}} />
                            </a>
                        </div>
                        <div>
                            <a target='blanck' href="https://www.instagram.com/caavuniversidad/">
                                <img src={insta} alt="Perfil Instagram" style={{width:"25px", marginTop:"10px"}} />
                            </a>
                        </div>
                        <div>
                            <a target='blanck' href="https://twitter.com/caavuniversidad">
                                <img src={twit} alt="Perfil Twitter" style={{width:"25px", marginTop:"10px"}} />
                            </a>
                        </div>
                        <div>
                            <a target='blanck' href="https://www.youtube.com/c/CaavMxUniversidad">
                                <img src={you} alt="" style={{width:"25px", marginTop:"10px"}} />
                            </a>
                        </div>
                        <div>
                            <a target='blanck' href='https://www.tiktok.com/@caavuniversidad?_t=8XPN7jDvG8k&_r=1'>
                                <img src={tiktok} alt="Numero WhatsApp" style={{width:"25px", marginTop:"10px"}} />
                            </a>
                        </div>
                    </div>
                </Fragment>
            )}
            <Whatsapp />
        </Fragment>
    );
}

export default Redes;