import React from 'react';
import "./Error.css";

import img1 from "../../Images/escritorio/Error/1.png";
import img2 from "../../Images/escritorio/Error/2.png";

const Error = () => {
    return (
        <div className='fondo_nuevo'>
            <div className='row' style={{marginBottom:"0px", height:"calc(100vh - 330px)", display:"flex", alignItems:"center"}}>
                <div className='col s12 m12 l4 invisible_nuevo' style={{padding:"0px"}}>
                    <img src={img1} alt="" style={{width:"100%"}} />
                </div>
                <div className='col s12 m4 l4 center'>
                    <p className='titulo_error_1'>Error</p>
                    <p className='titulo_error_2'>404</p>
                    <p className='titulo_error_3'>Oops...</p>
                    <p className='texto_error_1'>¡Algo salió mal!</p>
                    <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
                        <div className='boton_1_nv' style={{width:"280px"}}>
                            <a href="/">
                                <p>
                                    Regresar a la página principal
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='col s12 m4 l4 invisible_nuevo' style={{padding:"0px"}}>
                    <img src={img2} alt="" style={{width:"100%"}} />
                </div>
            </div>
        </div>
    );
};

export default Error;