import React, { Fragment, useEffect, useState } from "react";
import Swal from "sweetalert2";

import "./Cursos.css";

import Loader from "../../Components/Loader/Loader";
import Etiqueta from "../../Components/Cursos/Etiqueta";
import Mensaje from "../../Components/Mensaje";

import CursosHelper from "../../Helpers/Cursos";
import BanersHelper from "../../Helpers/Baners";

import img1 from "../../Images/escritorio/Cursos/1.png";
import movil1 from "../../Images/movil/Cursos/1.png";

import icono1 from "../../Images/escritorio/Cursos/iconos/1.png";
import icono2 from "../../Images/escritorio/Cursos/iconos/2.png";
import icono3 from "../../Images/escritorio/Cursos/iconos/3.png";
import icono4 from "../../Images/escritorio/Cursos/iconos/4.png";
import icono5 from "../../Images/escritorio/Cursos/iconos/5.png";
import icono6 from "../../Images/escritorio/Cursos/iconos/6.png";
import icono7 from "../../Images/escritorio/Cursos/iconos/7.png";
import icono8 from "../../Images/escritorio/Cursos/iconos/8.png";
import icono9 from "../../Images/escritorio/Cursos/iconos/9.png";
import icono10 from "../../Images/escritorio/Cursos/iconos/10.png";
import icono11 from "../../Images/escritorio/Cursos/iconos/11.png";
import icono12 from "../../Images/escritorio/Cursos/iconos/12.png";
import icono13 from "../../Images/escritorio/Cursos/iconos/13.png";

const Cursos = () => {
  const [data, setData] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [banderaBuscador, setBanderaBuscador] = useState(true);
  const [banderaCursos, setBanderaCursos] = useState(true);
  const [poppop, setPoppop] = useState(true);
  const [flag, setFlag] = useState(false);
  const [cursoSeleccionado, setCursoSeleccion] = useState([]);
  const [indexCurso, setIndexCurso] = useState(0);

  // informacion baner
  const [color, setColor] = useState("");

  useEffect(() => {
    sessionStorage.setItem("area", "aleatorio");
    sessionStorage.removeItem("modalidad");
    sessionStorage.removeItem("formato");

    if (sessionStorage.getItem("__cv_pp__")) {
      sessionStorage.removeItem("__cv_pp__");
      // setPoppop(false); //NOTA: si quieren que salga el mensaje otra ves solo descomentar esto
    }

    obtenerConfiguracionBaner();
  }, []);

  const obtenerConfiguracionBaner = async () => {
    // TODO: esta consulta debe de ser en un context para usarlo en multiples vistas y no hacer la consulta doble :v
    let res = await BanersHelper.obtenerConfiguracionBaners();

    if (res.success) {
      setColor(res.data.color);
      obtenerCursos();
    } else {
      Swal.fire(
        "Error!",
        "No se lograron obtener la configuracion de los baners, codigo: " +
          res.code,
        "error"
      );
    }
  };

  const obtenerCursos = async () => {
    let res = await CursosHelper.ObtenerCursosEtiquetas();
    // let fechaActual = new Date();

    if (res.success) {
      let arrayTemporal = [];
      let arrayCursos = [];
      let indexCurso = 0;

      let ordenado = res.data.sort((x, y) => {
        var firstDate = new Date(x.fechas[0].fecha_inicio);
        var secondDate = new Date(y.fechas[0].fecha_inicio);

        // Evaluar el campo estado_relampago.fecha_fin
        if (x.estado_relampago && x.estado_relampago.fecha_fin) {
          var currentDate = new Date();
          var xFechaFin = new Date(x.estado_relampago.fecha_fin);

          // Comprobar si x.estado_relampago.fecha_fin no ha pasado
          if (xFechaFin > currentDate) {
            // x.estado_relampago.fecha_fin no ha pasado, colocar x al comienzo
            return -1;
          }
        }

        if (firstDate < secondDate) return -1;
        if (firstDate > secondDate) return 1;
        return 0;
      });

      let ordenado_2 = ordenado.sort((x, y) => {
        if (x.estado_relampago && x.estado_relampago.fecha_fin) {
          var currentDate = new Date();
          var xFechaFin = new Date(x.estado_relampago.fecha_fin);

          // Comprobar si x.estado_relampago.fecha_fin no ha pasado
          if (xFechaFin > currentDate) {
            // x.estado_relampago.fecha_fin no ha pasado, colocar x al comienzo
            var firstDate = new Date(x.fecha_relampago.fecha_inicio);
            var secondDate = new Date(y.fecha_relampago.fecha_inicio);

            if (firstDate < secondDate) return -1;
            if (firstDate > secondDate) return 1;
            return 0;
          }
        } else {
          return 0;
        }
      });

      ordenado_2.map((curso, index, array) => {
        arrayTemporal.push(curso);

        indexCurso = indexCurso + 1;
        if (indexCurso % 8 === 0 && indexCurso !== 0) {
          arrayCursos.push(arrayTemporal);
          arrayTemporal = [];
          indexCurso = 0;
        } else if (index + 1 === array.length) {
          arrayCursos.push(arrayTemporal);
          arrayTemporal = [];
        }
      });

      setCursos(arrayCursos);
      setCursoSeleccion(arrayCursos[0]);
      setFlag(true);
      setData(res.data);
      setFlag(true);
    } else {
      Swal.fire(
        "Error!",
        "No se pudo obtener el directorio, codigo: " + res.code,
        "error"
      );
    }
  };

  const mostrarMas = (clic) => {
    if (cursos.length === indexCurso + 1 && !cursos[indexCurso + 1]) {
      Swal.fire("Se acabaron", "Son todos los cursos del CAAV", "success");
    } else {
      let masCursos = cursoSeleccionado;
      let index = indexCurso + 1;
      cursos[index].map((curso) => {
        masCursos.push(curso);
      });
      setCursoSeleccion(masCursos);
      setIndexCurso(index);
    }
  };

  const busqueda = (e) => {
    setBanderaBuscador(false);
    let letras = e.target.value;
    let cursoFiltro = [...data];
    setTimeout(() => {
      let arrayTemporal = [];
      let arrayCursos = [];
      let indexCurso = 0;
      if (letras === "") {
        data.map((curso, index, array) => {
          arrayTemporal.push(curso);
          indexCurso = indexCurso + 1;
          if (indexCurso % 8 === 0 && indexCurso !== 0) {
            arrayCursos.push(arrayTemporal);
            arrayTemporal = [];
            indexCurso = 0;
          } else if (index + 1 === array.length) {
            arrayCursos.push(arrayTemporal);
            arrayTemporal = [];
          }
        });
        setCursos(arrayCursos);
        setCursoSeleccion(arrayCursos[0]);
        setBanderaBuscador(true);
      } else {
        cursoFiltro.filter((curso, index, array) => {
          let busqueda = curso.nombre
            .toUpperCase()
            .indexOf(letras.toUpperCase());
          if (busqueda >= 0) {
            indexCurso = indexCurso + 1;
            arrayTemporal.push(curso);
            // return true;
          }
          if (indexCurso % 8 === 0 && indexCurso !== 0) {
            arrayCursos.push(arrayTemporal);
            arrayTemporal = [];
            indexCurso = 0;
          } else if (index + 1 === array.length) {
            arrayCursos.push(arrayTemporal);
            arrayTemporal = [];
          }
        });
        setCursos(arrayCursos);
        setCursoSeleccion(arrayCursos[0]);
        setBanderaBuscador(true);
      }
    }, 1000);
  };

  const enter = (e) => {
    let tecla = document.all ? e.keyCode : e.which;
    if (tecla == 13) {
      window.location.replace("#cursos");
    }
  };

  return (
    <Fragment>
      {!poppop && <Mensaje />}

      {window.screen.width > 550 ? (
        <img src={img1} style={{ width: "100%" }} />
      ) : (
        <img src={movil1} style={{ width: "100%" }} />
      )}

      <div className="container center">
        <h1
          className="titulo_1_nv"
          style={{ marginBottom: "0px", marginTop: "15px" }}
        >
          Cursos, talleres y diplomados
        </h1>
        <p>
          Tenemos más de 130 opciones para elegir. <br />
          No necesitas ser alumno del CAAV para tomarlos. <br />
          Son para todo tipo de público, a partir de los 15 años.
        </p>
      </div>

      {!flag ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Loader />
        </div>
      ) : (
        <Fragment>
          <p
            className="center titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", marginBottom: "0px" }}
          >
            ¿Qué área te interesa?
          </p>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div className="flex_d1 container contenedor_mediano center">
              <div className="col s6 m4 l2 background_1 etiquetas-cursos">
                <a href="/cursos-area/animacion-anime-y-comic">
                  <p style={{ marginTop: "3px" }}>
                    Animación,
                    <br />
                    Animé y Cómic
                  </p>
                  <img
                    src={icono1}
                    alt="Animación, Animé y Cómic"
                    style={{ width: "100%" }}
                  />
                </a>
              </div>
              <div className="col s6 m4 l2 background_4 etiquetas-cursos">
                <a href="/cursos-area/artes-plásticas">
                  <p style={{ marginTop: "3px" }}>
                    Artes
                    <br />
                    plásticas
                  </p>
                  <img
                    src={icono4}
                    alt="Artes plásticas"
                    style={{ width: "100%" }}
                  />
                </a>
              </div>
              <div className="col s6 m4 l2 background_13 etiquetas-cursos">
                <a href="/cursos-area/artes-escenicas">
                  <p style={{ marginTop: "3px" }}>
                    Artes
                    <br />
                    escénicas
                  </p>
                  <img
                    src={icono13}
                    alt="Artes escénicas"
                    style={{ width: "100%" }}
                  />
                </a>
              </div>
              <div className="col s6 m4 l2 background_2 etiquetas-cursos">
                <a href="/cursos-area/audio-y-voz">
                  <p style={{ marginTop: "3px" }}>
                    Audio
                    <br />y Voz
                  </p>
                  <img
                    src={icono2}
                    alt="Audio y Voz"
                    style={{ width: "100%" }}
                  />
                </a>
              </div>
              <div className="col s6 m4 l2 background_3 etiquetas-cursos">
                <a href="/cursos-area/cine-y-video">
                  <p style={{ marginTop: "3px" }}>
                    Cine
                    <br />y Video
                  </p>
                  <img
                    src={icono3}
                    alt="Cine y Video"
                    style={{ width: "100%" }}
                  />
                </a>
              </div>
              <div className="col s6 m4 l2 background_5 etiquetas-cursos-2">
                <a href="/cursos-area/diseño">
                  <p style={{ marginTop: "15px" }}>Diseño</p>
                  <img src={icono5} alt="Diseño" style={{ width: "100%" }} />
                </a>
              </div>
              <div className="col s6 m4 l2 background_6 etiquetas-cursos">
                <a href="/cursos-area/emprendimiento">
                  <p style={{ marginTop: "15px" }}>Emprendimiento</p>
                  <img
                    src={icono7}
                    alt="Emprendimiento"
                    style={{ width: "100%" }}
                  />
                </a>
              </div>
              <div className="col s6 m4 l2 background_7 etiquetas-cursos">
                <a href="/cursos-area/escritura">
                  <p style={{ marginTop: "15px" }}>Escritura</p>
                  <img src={icono8} alt="Escritura" style={{ width: "100%" }} />
                </a>
              </div>
              <div className="col s6 m4 l2 background_8 etiquetas-cursos">
                <a href="/cursos-area/fotografia">
                  <p style={{ marginTop: "15px" }}>Fotografía</p>
                  <img
                    src={icono9}
                    alt="Fotografía"
                    style={{ width: "100%" }}
                  />
                </a>
              </div>
              <div className="col s6 m4 l2 background_10 etiquetas-cursos">
                <a href="/cursos-area/marketing-y-la-web">
                  <p style={{ marginTop: "3px" }}>
                    Marketing
                    <br />y la Web
                  </p>
                  <img
                    src={icono11}
                    alt="Marketing y la Web"
                    style={{ width: "100%" }}
                  />
                </a>
              </div>
              <div className="col s6 m4 l2 background_11 etiquetas-cursos">
                <a href="/cursos-area/multimedia-y-videojuegos">
                  <p style={{ marginTop: "3px" }}>
                    Multimedia
                    <br />y Videojuegos
                  </p>
                  <img
                    src={icono12}
                    alt="Multimedia y Videojuegos"
                    style={{ width: "100%" }}
                  />
                </a>
              </div>
              <div className="col s6 m4 l2 background_12 etiquetas-cursos">
                <a href="/cursos-area/suite-de-adobe">
                  <p style={{ marginTop: "3px" }}>
                    Suite
                    <br />
                    de Adobe
                  </p>
                  <img
                    src={icono6}
                    alt="Suite de Adobe"
                    style={{ width: "100%" }}
                  />
                </a>
              </div>
              <div className="col s6 m4 l2 background_9 etiquetas-cursos">
                <a href="/cursos-area/teoria-del-arte">
                  <p style={{ marginTop: "3px" }}>
                    Teoría
                    <br />
                    del arte
                  </p>
                  <img
                    src={icono10}
                    alt="Teoría del arte"
                    style={{ width: "100%" }}
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="container contenedor_curso">
            <div className="flex_padre_2_d3">
              {!banderaCursos ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  <Loader />
                </div>
              ) : (
                <Fragment>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      margin: "40px 0 60px 0",
                    }}
                  >
                    <div className="busqueda_cursos">
                      <input
                        onKeyPress={(e) => {
                          enter(e);
                        }}
                        onChange={busqueda}
                        placeholder="Buscar por palabra clave…"
                        id="nombre_materia"
                        name="nombre_materia"
                        type="text"
                        className="validate formulario"
                      />
                      <label for="nombre_materia"></label>
                    </div>
                  </div>

                  <div
                    id="cursos"
                    className="flex_padre_2_d3"
                    style={{ width: "100%" }}
                  >
                    {!banderaBuscador ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "60vh",
                        }}
                      >
                        <Loader />
                      </div>
                    ) : cursos.length > 0 ? (
                      cursoSeleccionado.map((curso) => (
                        <Etiqueta color={color} curso={curso} />
                      ))
                    ) : (
                      <p
                        className="center titulo_4_nv"
                        style={{
                          fontFamily: "Montserrat-Bold",
                          marginBottom: "0px",
                        }}
                      >
                        Actualmente no hay cursos disponibles en esta área.
                      </p>
                    )}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div className="boton_7_nv">
                      <button
                        onClick={() => {
                          mostrarMas();
                        }}
                      >
                        <p>Ver más</p>
                      </button>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Cursos;
