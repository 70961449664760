import React from "react";

const Principal = ({ blog }) => {
  const licenciaturas = [
    "CINE DIGITAL",
    "MULTIMEDIA",
    "MARKETING DIGITAL",
    "ANIMACIÓN",
    "CULTURA GENERAL",
  ];
  const meses = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];

  return (
    <a className="col s12 m12 l6" href={`/caav-blog/${blog.url}`}>
      <div className="etiqueta-blog-grande">
        <img src={blog.imagenExterna} alt={blog.titulo} />
        <p className="licenciatura-hastag">
          #{licenciaturas[blog.licenciatura]}
        </p>
        <p className="titulo_2_nv">{blog.titulo}</p>
        <p className="fecha-blog-etiqueta">
          {new Date(blog.createdAt).getDate() +
            " de " +
            meses[new Date(blog.createdAt).getMonth()] +
            " " +
            new Date(blog.createdAt).getFullYear()}
        </p>
      </div>
    </a>
  );
};

export default Principal;
