import React from "react";
import ReactDOM from "react-dom";
import M from "materialize-css";

import "./Titulacion.css";

import img1 from "../../Images/escritorio/Titulacion/1.png";
import movil1 from "../../Images/movil/Titulacion/1.png";

import Animacion from "../../Components/Titulaciones/Animacion/Animacion";
import Cine from "../../Components/Titulaciones/Cine/Cine";
import Publicidad from "../../Components/Titulaciones/Publicidad/Publicidad";
import Multimedia from "../../Components/Titulaciones/Multimedia/Multimedia";

const Titulacion = () => {
  const cambiarLicenciatura = (licenciatura) => {
    // console.log("buscar licenciatura");
    if (licenciatura === 0) {
      //animacion
      ReactDOM.render(
        <Animacion />,
        document.getElementById("contenedor_licenciatura")
      );
    } else if (licenciatura === 1) {
      //cine
      ReactDOM.render(
        <Cine />,
        document.getElementById("contenedor_licenciatura")
      );
    } else if (licenciatura === 2) {
      //publicidad
      ReactDOM.render(
        <Publicidad />,
        document.getElementById("contenedor_licenciatura")
      );
    } else if (licenciatura === 3) {
      //multimedia
      ReactDOM.render(
        <Multimedia />,
        document.getElementById("contenedor_licenciatura")
      );
    }
    setTimeout(() => {
      var elems = document.querySelectorAll(".collapsible");
      M.Collapsible.init(elems);
    }, 500);
  };

  return (
    <div>
      {window.screen.width > 550 ? (
        <img src={img1} style={{ width: "100%" }} />
      ) : (
        <img src={movil1} style={{ width: "100%" }} />
      )}

      <div className="container contenedor_xch center">
        <p className="titulo_1_nv" style={{ margin: "15px 0 10px 0" }}>
          Titulación
        </p>
        <p style={{ marginTop: "0px" }}>
          En nuestra universidad, el plan de estudios está diseñado para que
          tengas tu título justo al terminar el noveno cuatrimestre.
        </p>
        <p className="titulo_4_nv" style={{ fontFamily: "Montserrat-Bold" }}>
          Éstos son los pasos:
        </p>
        <p style={{ fontWeight: "bold", marginBottom: "0px" }}>Inscripción</p>
        <p style={{ marginTop: "0px" }}>
          Solicita tu formato de inscripción en la oficina de Control Escolar o{" "}
          <a
            target="blanck"
            href="mailto:controlescolar@caav.mx"
            style={{
              color: "#00496C",
              textDecoration: "underline",
              fontWeight: "bold",
            }}
          >
            aquí
          </a>{" "}
          y llénala con tus datos.
        </p>
        <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
          Presentación de documentos
        </p>
        <p style={{ marginTop: "0px" }}>
          Entrega en Control Escolar, en la Sede Griffith:
          <ul className="puntos">
            <li>Ficha de inscripción llena.</li>
            <li>Carta de liberación de servicio social.</li>
            <li>Certificado de licenciatura.</li>
            <li>El importe de $6,500 MXN correspondiente.</li>
          </ul>
        </p>
        <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
          Espera las indicaciones que te dará Control Escolar
        </p>
        <p style={{ fontWeight: "bold", marginBottom: "0px" }}>Revisión</p>
        <p style={{ marginTop: "0px" }}>
          En un término de tres semanas, tres sinodales revisarán tu proyecto.
          <br />
          Al terminar este plazo, te entregarán el formato APA con sus
          correcciones y observaciones. A partir de ese momento tendrás una
          semana para hacer las correcciones y enviar a aprobación final.
        </p>
        <p style={{ fontWeight: "bold", marginBottom: "0px" }}>Diseño final</p>
        <p style={{ marginTop: "0px" }}>
          Una vez probado tu proyecto, debes darle diseño editorial para
          presentación final y entregar los ejemplares que se te indique.
        </p>
        <p style={{ fontWeight: "bold", marginBottom: "0px" }}>Examen</p>
        <p style={{ marginTop: "0px" }}>
          Se asignará una fecha para tu examen profesional.
        </p>
        <div className="row" style={{ margin: "0px", marginBottom: "10px" }}>
          <div className="col s6 m3 l3 xl3">
            <div className="boton_1_d3">
              <button
                onClick={() => {
                  cambiarLicenciatura(0);
                }}
                type=""
              >
                <p>Animación</p>
              </button>
            </div>
          </div>
          <div className="col s6 m3 l3 xl3">
            <div className="boton_2_d3">
              <button
                onClick={() => {
                  cambiarLicenciatura(1);
                }}
                type=""
              >
                <p>Cine Digital</p>
              </button>
            </div>
          </div>
          <div className="col s6 m3 l3 xl3">
            <div className="boton_3_d3">
              <button
                onClick={() => {
                  cambiarLicenciatura(3);
                }}
                type=""
              >
                <p>Multimedia</p>
              </button>
            </div>
          </div>
          <div className="col s6 m3 l3 xl3">
            <div className="boton_4_d3">
              <button
                onClick={() => {
                  cambiarLicenciatura(2);
                }}
                type=""
              >
                <p>Marketing</p>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="contenedor_licenciatura" style={{ marginBottom: "20px" }}>
        {/* <Animacion /> */}
      </div>
    </div>
  );
};

export default Titulacion;
