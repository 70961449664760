import React from 'react';

const Google = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Grupo_11465" data-name="Grupo 11465" width="25px" height="18.25px" viewBox="0 0 25.92 18.924">
            <path id="Trazado_29" data-name="Trazado 29" d="M133.664,68.084a4.555,4.555,0,1,0,4.552,4.555,4.553,4.553,0,0,0-4.552-4.555" transform="translate(-120.704 -63.179)" fill="#64c5dd" />
            <path id="Trazado_30" data-name="Trazado 30" d="M131.722,67.668a1.051,1.051,0,0,1,.024,1.486,7.042,7.042,0,0,0,0,9.749,1.051,1.051,0,0,1-1.507,1.465,9.142,9.142,0,0,1,0-12.68,1.047,1.047,0,0,1,1.483-.021m9.434,0a1.051,1.051,0,0,1,1.486.021,9.148,9.148,0,0,1,0,12.68,1.051,1.051,0,1,1-1.507-1.465,7.042,7.042,0,0,0,0-9.749,1.051,1.051,0,0,1,.021-1.486" transform="translate(-123.48 -64.566)" fill="#64c5dd" fillRule="evenodd" />
            <path id="Trazado_31" data-name="Trazado 31" d="M131.537,66.726a1.053,1.053,0,0,1,0,1.486,10.829,10.829,0,0,0,0,15.332,1.052,1.052,0,1,1-1.486,1.489,12.934,12.934,0,0,1,0-18.307,1.048,1.048,0,0,1,1.486,0m15.355,0a1.048,1.048,0,0,1,1.486,0,12.934,12.934,0,0,1,0,18.307,1.052,1.052,0,1,1-1.486-1.489,10.829,10.829,0,0,0,0-15.332,1.053,1.053,0,0,1,0-1.486" transform="translate(-126.255 -66.417)" fill="#64c5dd" fillRule="evenodd" />
        </svg>
    );
};

export default Google;