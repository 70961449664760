import Swal from 'sweetalert2';

var url_json = require("../location.json");

if (window.location.origin.includes("localhost")) {
    url_json = url_json.local;
} else {
    url_json = url_json.production;
}

const InscripcionesDataHelper = {
    ObtenerInscripcionesData: async () => {
        let url = url_json + "/inscripciones-data";
        let request = {
            method: 'GET',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }

        let response = await fetch(url, request);
        let res = await response.json();
        return res;
    },
    ObtenerUnInscripcionesData: async (id) =>{
        let url = url_json + "/inscripcion-data/" + id;
        let request = {
            method: 'GET',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }

        let response = await fetch(url, request);
        let res = await response.json();
        return res;
    },
    AgregarInscripcionesData: async (state) => {
        let url = url_json + "/agregar-inscripcion-data"
        let body = {
            archivado: state.archivado,
            bachiller: state.bachiller,
            celular: state.celular,
            ciudad: state.ciudad,
            ciudadBachiller: state.ciudadBachiller,
            codigoPostal: state.codigoPostal,
            colonia: state.colonia,
            condicion: state.condicion,
            condicionEspecial: state.condicionEspecial,
            domicilio: state.domicilio,
            edad: state.edad,
            email: state.email,
            enfermedad: state.enfermedad,
            enfermedadEspecificacion: state.enfermedadEspecificacion,
            estado: state.estado,
            estadoAlumno: state.estadoAlumno,
            fecha: state.fecha,
            fechaNacimiento: state.fechaNacimiento,
            generacion: state.generacion,
            licenciatura: state.licenciatura,
            medioEncontrado: state.medioEncontrado,
            nombre: state.nombre,
            nombreEmergencia: state.nombreEmergencia,
            nombreMadre: state.nombreMadre,
            nombrePadre: state.nombrePadre,
            pago: state.pago,
            pais: state.pais,
            pdfs: state.pdfs,
            periodo: state.periodo ,
            telefonoEmergencia: state.telefonoEmergencia,
            telefonoMadre: state.telefonoMadre,
            telefonoPadre: state.telefonoPadre,
            tipoSangre: state.tipoSangre
        }
        let request = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }
        let response = await fetch(url, request);
        let res = await response.json();
        if (res.success) {
            Swal.fire(
              'Inserción exitosa!',
              'campo guardado correctamente' ,
              'success'
            ).then(() => {
            //   window.location.reload();
            })
        } else {
            Swal.fire(
                'Error!',
                'No se pudo guardar el campo, codigo: ' + res.code ,
                'error'
            ).then(() => {
                // window.location.reload();
            })
        }
        return res;
    },
    ActualizarInscripcionesData: async (id, state) => {
        let url = url_json + "/actualizar-inscripcion-data/" + id;
        let body = {
            archivado: state.archivado,
            bachiller: state.bachiller,
            celular: state.celular,
            ciudad: state.ciudad,
            ciudadBachiller: state.ciudadBachiller,
            codigoPostal: state.codigoPostal,
            colonia: state.colonia,
            condicion: state.condicion,
            condicionEspecial: state.condicionEspecial,
            domicilio: state.domicilio,
            edad: state.edad,
            email: state.email,
            enfermedad: state.enfermedad,
            enfermedadEspecificacion: state.enfermedadEspecificacion,
            estado: state.estado,
            estadoAlumno: state.estadoAlumno,
            fecha: state.fecha,
            fechaNacimiento: state.fechaNacimiento,
            generacion: state.generacion,
            licenciatura: state.licenciatura,
            medioEncontrado: state.medioEncontrado,
            nombre: state.nombre,
            nombreEmergencia: state.nombreEmergencia,
            nombreMadre: state.nombreMadre,
            nombrePadre: state.nombrePadre,
            pago: state.pago,
            pais: state.pais,
            pdfs: state.pdfs,
            periodo: state.periodo ,
            telefonoEmergencia: state.telefonoEmergencia,
            telefonoMadre: state.telefonoMadre,
            telefonoPadre: state.telefonoPadre,
            tipoSangre: state.tipoSangre
        }
        let request = {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }
        let response = await fetch(url, request);
        let res = await response.json();
        if (res.success) {
            Swal.fire(
              'Inserción exitosa!',
              'campo actualizado correctamente' ,
              'success'
            ).then(() => {
            //   window.location.reload();
            })
        } else {
            Swal.fire(
                'Error!',
                'No se pudo actualizar el campo, codigo: ' + res.code ,
                'error'
            ).then(() => {
                // window.location.reload();
            })
        }
        return res;
    },
    EliminarInscripcionesData: async (id) => {
        let url = url_json + "/eliminar-inscripcion-data/" + id;
        let request = {
            method:'DELETE',
            headers: {
                "Content-type":"application/json; charset=UTF-8"
            }
        }

        let response = await fetch(url, request);
        let json = await response.json();
        if (json.success) {
            Swal.fire(
                'Eliminacion exitosa!',
                'Campo eliminado correctamente',
                'success'
            ).then(e => {
                if (e.isConfirmed || e.isDismissed) {
                    // console.log("entro", json);
                    // window.location.href = "/admin/contactos";
                }
            })
        } else {
            Swal.fire(
                'Error !',
                'No se pudo eliminar el campo',
                'error'
            )
        }
        return json;
    }
}

export default (InscripcionesDataHelper);