import React from "react";

const Info = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        width: "20px",
        height: "20px",
        position: "absolute",
        marginTop: "5px",
        marginLeft: "50px",
      }}
      zoomAndPan="magnify"
      viewBox="0 0 810 809.999993"
      preserveAspectRatio="xMidYMid meet"
      version={1.0}
    >
      <defs>
        <clipPath id="b47ffd7f88">
          <path
            d="M 46.769531 46.769531 L 763 46.769531 L 763 763 L 46.769531 763 Z M 46.769531 46.769531 "
            clipRule="nonzero"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#b47ffd7f88)">
        <path
          fill="#64C5DD"
          d="M 762.957031 404.863281 C 762.773438 601.9375 603.695312 763.019531 404.679688 762.898438 C 207 762.773438 46.828125 603.210938 46.890625 404.679688 C 46.949219 205.90625 207.789062 46.890625 404.984375 46.828125 C 602.605469 46.769531 762.957031 207.242188 762.957031 404.863281 Z M 466.769531 483.277344 C 466.769531 439.15625 466.710938 395.089844 466.832031 350.964844 C 466.890625 334.277344 460.945312 320.132812 448.621094 309.148438 C 429.199219 291.789062 406.621094 288.148438 382.40625 296.707031 C 359.28125 304.898438 343.804688 327.113281 343.925781 352.242188 C 344.410156 440.125 344.167969 528.011719 344.046875 615.894531 C 344.046875 665.542969 386.59375 673.917969 406.925781 673.859375 C 437.515625 673.738281 467.558594 649.09375 466.953125 613.710938 C 466.164062 570.253906 466.769531 526.734375 466.769531 483.277344 Z M 470.957031 201.292969 C 470.898438 164.636719 441.644531 135.441406 404.921875 135.441406 C 368.144531 135.441406 338.765625 164.878906 338.828125 201.660156 C 338.886719 238.015625 368.566406 267.632812 404.984375 267.632812 C 441.644531 267.632812 470.957031 238.136719 470.957031 201.292969 Z M 470.957031 201.292969 "
          fillOpacity={1}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default Info;
