import React, { useEffect } from "react";

import img1 from "../../Images/escritorio/Mensaje/1.png";

// correo a enviar: cursos@caav.mx

const Mensaje = () => {
  useEffect(() => {
    // solo para el mensaje de seguimineeton de las campañas
    // TODO: agregar filtro de url para que solo funcione el las campañas
    console.log("entro");

    window.gtag("event", "conversion", {
      send_to: "AW-1004461178/-j-eCNSHnOAYEPq4-94D",
    });
  }, []);

  return (
    <div className="fondo_mensaje">
      <div
        className="row"
        style={{
          marginBottom: "0px",
          height: "calc(100vh - 330px)",
          minHeight: "625px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="col s12 m6 offset-m3 center">
          <img
            src={img1}
            style={{ width: "80%" }}
            alt="Envio exitoso del formulario del CAAV"
          />
          <p className="titulo_error_1">Envío exitoso</p>
          <p className="texto_error_1">
            Gracias por completar el formulario con tus datos.
            <br />
            En breve nos pondremos en contacto contigo.
          </p>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="boton_1_nv" style={{ width: "280px" }}>
              <a href="/">
                <p>Regresar a la página principal</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mensaje;
