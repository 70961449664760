import React, { useEffect, useState } from "react";

const CountdownTimer = ({ fecha }) => {
  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    // Convierte la fecha en formato ISO a un objeto Date
    let countDownDate = new Date(fecha).getTime();

    countDownDate += 21600000;
    // let date = new Date(fecha).setHours(6);
    // let countDownDate = new Date(date).getTime();

    const timer = setInterval(() => {
      // Obtiene la fecha y hora actual en UTC
      const now = new Date().getTime();

      // Calcula la distancia entre la fecha actual y la fecha de finalización
      const distance = countDownDate - now;

      // Calcula los días, horas, minutos y segundos restantes
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Formatea la cuenta regresiva
      const countdownText = `${days}d : ${hours}h : ${minutes}m : ${seconds}s`;

      // Actualiza el estado del contador
      setCountdown(countdownText);

      // Si la cuenta regresiva ha terminado, limpia el temporizador
      if (distance < 0) {
        clearInterval(timer);
        setCountdown("¡La cuenta regresiva ha terminado!");
      }
    }, 1000);

    // Limpia el temporizador cuando el componente se desmonte para evitar fugas de memoria
    return () => clearInterval(timer);
  }, []);

  return <span>{countdown}</span>;
};

export default CountdownTimer;
