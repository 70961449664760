import React, { Fragment, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

import Etiqueta from "../../Components/Cursos/Etiqueta";
import Loader from "../../Components/Loader/Loader";

import CursosHelper from "../../Helpers/Cursos";
import ProfesoresCursosHelpers from "../../Helpers/ProfesoresCursos";

const CursoProfesor = () => {
  const area = sessionStorage.getItem("area");
  let { id } = useParams("area");
  const [profesor, setProfesor] = useState("Cargando");

  const [data, setData] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [banderaBuscador, setBanderaBuscador] = useState(true);
  const [banderaCursos, setBanderaCursos] = useState(true);
  const [flag, setFlag] = useState(false);
  const [cursoSeleccionado, setCursoSeleccion] = useState([]);
  const [indexCurso, setIndexCurso] = useState(0);

  useEffect(() => {
    obtenerCursos();
  }, []);

  const obtenerCursos = async () => {
    let res = await CursosHelper.obtenerUnCursoProfesor(id);

    if (res.success) {
      let arrayTemporal = [];
      let arrayCursos = [];
      let indexCurso = 0;

      res.data.map((curso, index, array) => {
        arrayTemporal.push(curso);
        indexCurso = indexCurso + 1;
        if (indexCurso % 8 === 0 && indexCurso !== 0) {
          arrayCursos.push(arrayTemporal);
          arrayTemporal = [];
          indexCurso = 0;
        } else if (index + 1 === array.length) {
          arrayCursos.push(arrayTemporal);
          arrayTemporal = [];
        }
      });

      setCursos(arrayCursos);
      setCursoSeleccion(arrayCursos[0]);
      setData(res.data);
      obtenerProfesor();
    } else {
      Swal.fire(
        "Error!",
        "No se pudo obtener los cursos, codigo: " + res.code,
        "error"
      );
    }
  };

  const obtenerProfesor = async () => {
    let res = await ProfesoresCursosHelpers.obtenerUnProfesor(id);
    if (res.success) {
      setProfesor(res.data);
      setFlag(true);
    } else {
      Swal.fire(
        "Error!",
        "No se lograron obtener el profesor, codigo: " + res.code,
        "error"
      );
    }
  };

  const mostrarMas = (clic) => {
    if (cursos.length === indexCurso + 1 && !cursos[indexCurso + 1]) {
      Swal.fire("Se acabaron", "Son todos los cursos del CAAV", "success");
    } else {
      let masCursos = cursoSeleccionado;
      let index = indexCurso + 1;
      cursos[index].map((curso) => {
        masCursos.push(curso);
      });
      setCursoSeleccion(masCursos);
      setIndexCurso(index);
    }
  };

  const busqueda = (e) => {
    setBanderaBuscador(false);
    let letras = e.target.value;
    let cursoFiltro = [...data];
    setTimeout(() => {
      let arrayTemporal = [];
      let arrayCursos = [];
      let indexCurso = 0;
      if (letras === "") {
        data.map((curso, index, array) => {
          arrayTemporal.push(curso);
          indexCurso = indexCurso + 1;
          if (indexCurso % 8 === 0 && indexCurso !== 0) {
            arrayCursos.push(arrayTemporal);
            arrayTemporal = [];
            indexCurso = 0;
          } else if (index + 1 === array.length) {
            arrayCursos.push(arrayTemporal);
            arrayTemporal = [];
          }
        });
        setCursos(arrayCursos);
        setCursoSeleccion(arrayCursos[0]);
        setBanderaBuscador(true);
      } else {
        cursoFiltro.filter((curso, index, array) => {
          let busqueda = curso.nombre
            .toUpperCase()
            .indexOf(letras.toUpperCase());
          if (busqueda >= 0) {
            indexCurso = indexCurso + 1;
            arrayTemporal.push(curso);
            // return true;
          }
          if (indexCurso % 8 === 0 && indexCurso !== 0) {
            arrayCursos.push(arrayTemporal);
            arrayTemporal = [];
            indexCurso = 0;
          } else if (index + 1 === array.length) {
            arrayCursos.push(arrayTemporal);
            arrayTemporal = [];
          }
        });
        setCursos(arrayCursos);
        setCursoSeleccion(arrayCursos[0]);
        setBanderaBuscador(true);
      }
    }, 1000);
  };

  const enter = (e) => {
    let tecla = document.all ? e.keyCode : e.which;
    if (tecla == 13) {
      window.location.replace("#cursos");
    }
  };

  return (
    <Fragment>
      {!flag ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Loader />
        </div>
      ) : (
        <Fragment>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "25px",
              margin: "30px 0",
            }}
          >
            <img
              style={{ width: "100px", borderRadius: "50%" }}
              src={profesor.imagen}
              alt="profesor"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <p style={{ fontWeight: "bold", fontSize: "20px", margin: "0" }}>
                {profesor.nombre}
              </p>
              <p style={{ margin: "0" }}>{profesor.certificado}</p>
            </div>
          </div>

          <div className="container contenedor_curso">
            <div className="flex_padre_2_d3">
              {!banderaCursos ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  <Loader />
                </div>
              ) : (
                <Fragment>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      margin: "40px 0 60px 0",
                    }}
                  >
                    <div className="busqueda_cursos">
                      <input
                        onKeyPress={(e) => {
                          enter(e);
                        }}
                        onChange={busqueda}
                        placeholder="Buscar por palabra clave…"
                        id="nombre_materia"
                        name="nombre_materia"
                        type="text"
                        className="validate formulario"
                      />
                      <label for="nombre_materia"></label>
                    </div>
                  </div>

                  <div className="navegacion_cursos navegacion_ancho">
                    <b>Buscaste</b>
                    <div />
                    <b className={"color_defecto color_" + area}>
                      {profesor.nombre}
                    </b>
                  </div>
                  <div
                    id="cursos"
                    className="flex_padre_2_d3"
                    style={{ width: "100%" }}
                  >
                    {!banderaBuscador ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "60vh",
                        }}
                      >
                        <Loader />
                      </div>
                    ) : cursos.length > 0 ? (
                      cursoSeleccionado.map((curso) => (
                        <Etiqueta curso={curso} />
                      ))
                    ) : (
                      <p
                        className="center titulo_4_nv"
                        style={{
                          fontFamily: "Montserrat-Bold",
                          marginBottom: "0px",
                        }}
                      >
                        Actualmente no hay cursos disponibles en esta área.
                      </p>
                    )}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div className="boton_7_nv">
                      <button
                        onClick={() => {
                          mostrarMas();
                        }}
                      >
                        <p>Ver más</p>
                      </button>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default CursoProfesor;
