import React from 'react';

const Diplomado = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="22.777" viewBox="0 0 35 22.777" style={{marginBottom:"5px"}}>
            <g id="Grupo_21" data-name="Grupo 21" transform="translate(0 0)">
                <path id="Trazado_41" data-name="Trazado 41" d="M10.944,223.619l-4.594,1.8L0,227.9l8.268,3.23.342.133L13.4,233.13l4.1,1.6,4.679-1.828h0l4.211-1.648,1.1-.426,7.507-2.934h0L27.072,224.8l0-.007h-.007l-5.468-2.136h0v-.006h-.007l-4.085-1.6v0Z" transform="translate(0 -221.058)" fill="#f49a3f"/>
                <path id="Trazado_42" data-name="Trazado 42" d="M6.391,228.842v3.667a4.81,4.81,0,0,0,2.462,3.9,11.081,11.081,0,0,0,6.023,1.639c.3,0,.6-.011.9-.031a10.31,10.31,0,0,0,5.669-2,4.545,4.545,0,0,0,1.92-3.508V232.5h0v-3.659l-8.487,3.474Z" transform="translate(2.623 -217.864)" fill="#f49a3f"/>
                <path id="Trazado_43" data-name="Trazado 43" d="M5.716,237.663l-.006-.049-.3-3.137L5.4,234.34l-.145-1.511h.123v-1.679a.826.826,0,0,0,.2-.963.839.839,0,0,0-.392-.395v-2.041l-.729-.272v2.32a.826.826,0,0,0-.181,1.351v1.679H4.4l-.089.9-.11,1.124-.271,2.772v.01l-.148,1.5-.131,1.344a.387.387,0,0,0,0,.09c.044.37.6.647,1.247.622s1.133-.344,1.089-.712Z" transform="translate(1.498 -218.422)" fill="#f49a3f"/>
            </g>
        </svg>

    );
};

export default Diplomado;