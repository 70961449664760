import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import BanersHelper from "../../Helpers/Baners";

import CountdownTimer from "./CountdownTimer ";
import "./Baner.css";

const BanerContador = ({ fecha, color }) => {
  const [baners, setBaner] = useState([]);

  useEffect(() => {
    obtenerBanersOferta();
  }, []);

  const obtenerBanersOferta = async () => {
    let res = await BanersHelper.obtenerBanersOferta();

    if (res.success) {
      setBaner(res.data);
    } else {
      Swal.fire(
        "Error!",
        "No se lograron obtener la configuracion de los baners, codigo: " +
          res.code,
        "error"
      );
    }
  };

  if (window.screen.width < 980) {
    return (
      <div className="body__inner-wrapper">
        <div className="marquee color-marquee-1" style={{ background: color }}>
          <div className="marquee__inner-wrap">
            <div className="marquee__inner" data-columns={baners.length}>
              {baners.map((noticia) => (
                <span className="banerSpam">{noticia.titulo}</span>
              ))}
              {baners.map((noticia) => (
                <span className="banerSpam" aria-hidden="true">
                  {noticia.titulo}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="body__inner-wrapper">
        <div className="marquee color-marquee-1" style={{ background: color }}>
          <div className="marquee__inner-wrap">
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              {baners.map((noticia) => (
                <span>
                  <b>{noticia.titulo}</b>
                </span>
              ))}
              <div
                style={{
                  maxHeight: "52px",
                  display: "flex",
                }}
              >
                <span>
                  <b>
                    <CountdownTimer fecha={fecha} />
                  </b>
                </span>
                <span>
                  <a
                    target="_blanck"
                    href="https://api.whatsapp.com/send?phone=3318958778"
                    className="boton_8_nv"
                  >
                    Pide detalles a tu asesora
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default BanerContador;
