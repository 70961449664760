import React from "react";

import { ScrollingCarousel, Carousel } from "@trendyol-js/react-carousel";

import icon64 from "../../../Images/Icon/64.svg";
import Arrow from "../../../Images/arrow";

const CarruselPasos = () => {
  return window.innerWidth > 550 ? (
    <Carousel
      show={window.innerWidth > 550 ? window.innerWidth / 330 : 2.1}
      slide={1}
      swiping={false}
      infinite={false}
      rightArrow={
        <button className="btn-izquierda">
          <Arrow width={"30px"} />
        </button>
      }
      leftArrow={
        <button className="btn-derecha">
          <Arrow width={"30px"} rotate={"rotate(180deg)"} />
        </button>
      }
    >
      <div className="card-inscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#5BBF3F" }}
          >
            1 <br />
            Contacta <br /> a una asesora
          </p>
          <p>
            Para que te guíe <br className="invisible_escritorio" />
            paso a paso.
          </p>
        </div>
        <a
          className="boton-rein-nv"
          style={{ background: "#5BBF3F" }}
          href="https://wa.link/ncvl3t"
          target="_blanck"
        >
          Da clic aquí
        </a>
      </div>
      <div className="card-inscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#B52C93" }}
          >
            2 <br />
            Evaluación <br />
            de perfil
          </p>
          <p>
            Llena en línea para conocer <br />
            tu perfil creativo <br />
            (no es un examen).
            <br />
            <br />
            <b style={{ color: "#B52C93" }}>Cuesta $500 MXN.</b> <br />
            Envía comprobante <br className="invisible_escritorio" />
            de tu pago.
          </p>
        </div>
        <a
          className="boton-rein-nv"
          style={{ background: "#B52C93" }}
          href="mailto:lupita.rocha@caav.mx"
          target="_blanck"
        >
          Aquí
        </a>
      </div>
      <div className="card-inscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#02B5C1" }}
          >
            3 <br />
            Haz tu <br />
            pago inicial
          </p>
          <p>
            Inscripción y <br />
            primera colegiatura.
          </p>
        </div>
        <div>
          <a
            className="boton-rein-nv"
            style={{ background: "#02B5C1" }}
            href="#precios"
          >
            Ver precios
          </a>
          <a
            className="boton-rein-nv"
            style={{ background: "#02B5C1", marginTop: "10px" }}
            href="#banco"
          >
            Dónde pagar
          </a>
        </div>
      </div>
      <div className="card-inscripcion">
        <p
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", color: "#F49A3F" }}
        >
          4 <br />
          Llena tu <br className="invisible_escritorio" />
          formulario <br />
          de inscripción
        </p>
        <a
          className="boton-rein-nv"
          style={{ background: "#F49A3F" }}
          href="#formulario"
        >
          Aquí
        </a>
      </div>
      <div className="card-inscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#8A6AAC" }}
          >
            5 <br />
            Recibe correo <br />
            de confirmación
          </p>
          <p>
            En 48 horas o menos, <br />
            te escribiremos <br />
            y te daremos fecha y hora <br />
            de tu charla de bienvenida.
          </p>
        </div>
        <a
          className="boton-rein-nv"
          style={{ background: "#8A6AAC", lineHeight: "14px" }}
          href="mailto:tesoreria@caav.mx"
          target="_blanck"
        >
          Si no lo recibes, <br className="invisible_escritorio" />
          escribe aquí
        </a>
      </div>
      <div className="card-inscripcion" style={{ background: "#BA2049" }}>
        <img src={icon64} alt="CAAV" />
        <p
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", color: "#ffffff" }}
        >
          Sólo hasta que tengas la confirmación estarás inscrito
          <br className="invisible_escritorio" /> o inscrita.
        </p>
      </div>
      <div className="card-inscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#F9027A" }}
          >
            6 <br />
            Entrega pronto <br />
            tus papeles
          </p>
          <p>
            En la oficina <br />
            de Control Escolar.
          </p>
        </div>
        <a
          className="boton-rein-nv"
          style={{ background: "#F9027A" }}
          href="!#"
        >
          Éstos son los papeles
        </a>
      </div>
      <div className="card-inscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#5BBF3F" }}
          >
            7 <br />
            Entra <br />
            a tu charla <br />
            de bienvenida
          </p>
          <p>
            para que conozcas <br />
            a tu grupo y a la directora.
            <br /> <br />
            Es en línea, con el link <br />
            que te llegará en tu correo <br />
            de confirmación.
          </p>
        </div>
      </div>
      <div style={{ width: "20%" }}></div>
    </Carousel>
  ) : (
    <ScrollingCarousel infinite={false}>
      <div className="card-inscripcion" style={{ marginLeft: "5%" }}>
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#5BBF3F" }}
          >
            1 <br />
            Contacta <br /> a una asesora
          </p>
          <p>
            Para que te guíe <br className="invisible_escritorio" />
            paso a paso.
          </p>
        </div>
        <a
          className="boton-rein-nv"
          style={{ background: "#5BBF3F" }}
          href="https://wa.link/ncvl3t"
          target="_blanck"
        >
          Da clic aquí
        </a>
      </div>
      <div className="card-inscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#B52C93" }}
          >
            2 <br />
            Evaluación <br />
            de perfil
          </p>
          <p>
            Llena en línea para conocer <br />
            tu perfil creativo <br />
            (no es un examen).
            <br />
            <br />
            <b style={{ color: "#B52C93" }}>Cuesta $500 MXN.</b> <br />
            Envía comprobante <br className="invisible_escritorio" />
            de tu pago.
          </p>
        </div>
        <a
          className="boton-rein-nv"
          style={{ background: "#B52C93" }}
          href="mailto:lupita.rocha@caav.mx"
          target="_blanck"
        >
          Aquí
        </a>
      </div>
      <div className="card-inscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#02B5C1" }}
          >
            3 <br />
            Haz tu <br />
            pago inicial
          </p>
          <p>
            Inscripción y <br />
            primera colegiatura.
          </p>
        </div>
        <div>
          <a
            className="boton-rein-nv"
            style={{ background: "#02B5C1" }}
            href="#precios"
          >
            Ver precios
          </a>
          <a
            className="boton-rein-nv"
            style={{ background: "#02B5C1", marginTop: "10px" }}
            href="#banco"
          >
            Dónde pagar
          </a>
        </div>
      </div>
      <div className="card-inscripcion">
        <p
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", color: "#F49A3F" }}
        >
          4 <br />
          Llena tu <br className="invisible_escritorio" />
          formulario <br />
          de inscripción
        </p>
        <a
          className="boton-rein-nv"
          style={{ background: "#F49A3F" }}
          href="#formulario"
        >
          Aquí
        </a>
      </div>
      <div className="card-inscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#8A6AAC" }}
          >
            5 <br />
            Recibe correo <br />
            de confirmación
          </p>
          <p>
            En 48 horas o menos, <br />
            te escribiremos <br />
            y te daremos fecha y hora <br />
            de tu charla de bienvenida.
          </p>
        </div>
        <a
          className="boton-rein-nv"
          style={{ background: "#8A6AAC", lineHeight: "14px" }}
          href="mailto:tesoreria@caav.mx"
          target="_blanck"
        >
          Si no lo recibes, <br className="invisible_escritorio" />
          escribe aquí
        </a>
      </div>
      <div className="card-inscripcion" style={{ background: "#BA2049" }}>
        <img src={icon64} alt="CAAV" />
        <p
          className="titulo_4_nv"
          style={{ fontFamily: "Montserrat-Bold", color: "#ffffff" }}
        >
          Sólo hasta que tengas la confirmación estarás inscrito
          <br className="invisible_escritorio" /> o inscrita.
        </p>
      </div>
      <div className="card-inscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#F9027A" }}
          >
            6 <br />
            Entrega pronto <br />
            tus papeles
          </p>
          <p>
            En la oficina <br />
            de Control Escolar.
          </p>
        </div>
        <a
          className="boton-rein-nv"
          style={{ background: "#F9027A" }}
          href="!#"
        >
          Éstos son los papeles
        </a>
      </div>
      <div className="card-inscripcion">
        <div>
          <p
            className="titulo_4_nv"
            style={{ fontFamily: "Montserrat-Bold", color: "#5BBF3F" }}
          >
            7 <br />
            Entra <br />
            a tu charla <br />
            de bienvenida
          </p>
          <p>
            para que conozcas <br />
            a tu grupo y a la directora.
            <br /> <br />
            Es en línea, con el link <br />
            que te llegará en tu correo <br />
            de confirmación.
          </p>
        </div>
      </div>
      <div style={{ width: "20%" }}></div>
    </ScrollingCarousel>
  );
};

export default CarruselPasos;
