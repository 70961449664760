import React, { Fragment } from "react";

const AvisoPrivacidad = () => {
  return (
    <Fragment>
      <p className="encabezadosCaav center" style={{ margin: "15px" }}>
        <br />
        Aviso de privacidad
      </p>

      <div
        className="contenedor_3"
        style={{ marginTop: "100px", marginBottom: "100px" }}
      >
        <p>
          El Centro de Formación y Capacitación Audiovisual, S. C, mejor
          conocido como CAAV/Universidad de Medios Audiovisuales, con domicilio
          en calle Lerdo de Tejada No. 2071, Colonia Americana, en la ciudad de
          Guadalajara, Jal., C.P. 44160, Jalisco, México, y portal de internet{" "}
          <a href="www.caav.mx">www.caav.mx</a>, es el responsable del uso y
          protección de sus datos personales, y al respecto le informamos lo
          siguiente:
        </p>

        <p>
          Prospectos y Aspirantes. El CAAV como parte de su registro como
          prospecto o aspirante recabará y tratará datos personales de
          identificación y de contacto. Asimismo, le informamos que el CAAV no
          recabará ni tratará datos personales sensibles.
        </p>

        <p>Sus datos personales serán utilizados para los siguientes fines:</p>

        <ul>
          <li>
            Para contactarle con el propósito de ofrecerle nuestros servicios
            educativos
          </li>
          <li>
            Para dar seguimiento a sus solicitudes de información sobre los
            servicios que ofrecemos.
          </li>
        </ul>

        <p>
          Alumnos: El CAAV como parte de su registro como alumno recabará y
          tratará datos personales de identificación, de contacto, laborales,
          familiares, financieros y de salud.
        </p>

        <p>
          Sus datos personales serán utilizados para los siguientes fines
          necesarios para cumplir con nuestras obligaciones derivadas de nuestra
          relación jurídica:
        </p>

        <ul>
          <li>
            Para prestarle todos los servicios y realizar todas las actividades
            académicas y administrativas que resulten necesarias durante su
            estancia en el CAAV como alumno.
          </li>
          <li>
            Para contactar a sus familiares o terceros en caso de una
            emergencia.
          </li>
        </ul>

        <p>
          Personal docente y prestadores de servicios profesionales: El CAAV
          para cumplir con las finalidades señaladas en el presente Aviso
          tratará datos personales de identificación, de contacto, laborales,
          académicos, biométricos, patrimoniales y financieros.
        </p>

        <p>
          Asimismo, le informamos que para cumplir con las finalidades descritas
          en este aviso se tratarán los siguientes datos personales sensibles:
          datos de salud. Le informamos que el tratamiento de dichos datos es
          necesario para cumplir las obligaciones derivadas de la relación
          jurídica entre usted y el CAAV, razón por la cual su consentimiento se
          encuentra exceptuado.
        </p>

        <p>
          Sus datos personales serán utilizados para los siguientes fines
          necesarios para cumplir con nuestras obligaciones derivadas de nuestra
          relación jurídica:
        </p>

        <ul>
          <li>
            Para dar cumplimiento legal a nuestra relación laboral, académica y
            administrativa.
          </li>
        </ul>

        <p>
          Egresados y Ex Alumnos. El CAAV como parte de su registro como
          Egresado o Ex Alumno, recabará y tratará datos personales de
          identificación, de contacto. Asimismo, le informamos que el CAAV no
          recabará ni tratará datos personales sensibles.
        </p>

        <p>
          El CAAV recabó y recabará tus datos personales con la finalidad
          primaria de identificarte, actualizar tus datos, corroborar tu nivel
          de estudios, ubicar tu centro de trabajo o actividad profesional.
        </p>

        <p>
          De manera adicional, el CAAV utilizará su información personal para
          las siguientes finalidades que no son necesarias, pero que nos
          permiten y facilitan brindarle una mejor atención:
        </p>

        <ul>
          <li>
            Para promover la vida académica, de investigación y de extensión,
            así como nuestras propuestas de educación continua, y de ofertas
            laborales.
          </li>
        </ul>

        <p>Derechos ARCO</p>

        <p>
          El CAAV informa a ustedes que tienen los derechos de acceso,
          rectificación, cancelación y oposición respecto de sus datos
          personales y personales sensibles que resguarda el CAAV y en el caso
          que desees ejercerlos, serás atendido en la oficina del Administrador
          General por AGUSTIN LOPEZ GONZALEZ en horas hábiles y conforme a las
          prevenciones y requisitos establecidos en el Capítulo IV de la Ley
          Federal de Protección de Datos Personales en Posesión de los
          Particulares en vigor. (Sede Griffith, Lerdo de Tejada 2071, Colonia
          Americana, teléfono 33 3587 7825, correo electrónico
          administracion@caav.mx).
        </p>

        <p>
          El presente aviso de privacidad puede sufrir modificaciones, cambios o
          actualizaciones derivadas de nuevos requerimientos legales; de
          nuestras propias necesidades por los productos o servicios que
          ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro
          modelo de negocio, o por otras causas. Nos comprometemos a mantenerlo
          informado sobre los cambios que pueda sufrir el presente aviso de
          privacidad, a través de nuestra página web{" "}
          <a href="www.caav.mx">www.caav.mx</a>.
        </p>

        <p>
          He leído y sí estoy de acuerdo con la parte general del aviso de
          privacidad y con las finalidades específicas del tratamiento de mis
          datos personales.
        </p>
      </div>
    </Fragment>
  );
};

export default AvisoPrivacidad;
