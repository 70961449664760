//carusel
import img1 from '../../../Images/escritorio/Entorno/carrusel/1.png';
import img2 from '../../../Images/escritorio/Entorno/carrusel/2.png';
import img3 from '../../../Images/escritorio/Entorno/carrusel/3.png';
import img4 from '../../../Images/escritorio/Entorno/carrusel/4.png';
import img5 from '../../../Images/escritorio/Entorno/carrusel/5.png';
import img6 from '../../../Images/escritorio/Entorno/carrusel/6.png';
import img7 from '../../../Images/escritorio/Entorno/carrusel/7.png';
import img8 from '../../../Images/escritorio/Entorno/carrusel/8.png';
import img9 from '../../../Images/escritorio/Entorno/carrusel/9.png';
import img10 from '../../../Images/escritorio/Entorno/carrusel/10.png';
import img11 from '../../../Images/escritorio/Entorno/carrusel/11.png';
import img12 from '../../../Images/escritorio/Entorno/carrusel/12.png';
import img13 from '../../../Images/escritorio/Entorno/carrusel/13.png';
import img14 from '../../../Images/escritorio/Entorno/carrusel/14.png';
import img15 from '../../../Images/escritorio/Entorno/carrusel/15.png';
import img16 from '../../../Images/escritorio/Entorno/carrusel/16.png';
import img17 from '../../../Images/escritorio/Entorno/carrusel/17.png';
import img18 from '../../../Images/escritorio/Entorno/carrusel/18.png';
import img19 from '../../../Images/escritorio/Entorno/carrusel/19.png';
import img20 from '../../../Images/escritorio/Entorno/carrusel/20.png';
import img21 from '../../../Images/escritorio/Entorno/carrusel/21.png';
import img22 from '../../../Images/escritorio/Entorno/carrusel/22.png';

const imagenes = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22
];

export default imagenes;